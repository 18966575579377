import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Grid
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DateAndTime from "../../organisms/confirmation/DateAndTime";
import { rescheduleOrder } from "../../services/orders";
import { updateLoaderFields } from "../../store/slices/LoaderSlice";
import { updateOrderFields } from "../../store/slices/OrderSlice";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
const RescheduleOrder = () => {
  const {isMobile} = useScreenResolution();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const open =
    useSelector(({ orders }) => orders.openOrderResceduleDialog) || false;

  const handleClose = () => {
    dispatch(
      updateOrderFields({
        openOrderResceduleDialog: false,
      })
    );
  };

  const confirmHandler = async () => {
    const response = await rescheduleOrder();
    const { success, message } = response;
    dispatch(
      updateLoaderFields({
        alertText: message,
        severity: success ? "success" : "error",
      })
    );
    if (success) {
      handleClose();
      navigate(0);
    }
  };
  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      fullWidth
      onClose={handleClose}
      maxWidth="md"
      scroll="paper"
      sx={{
        marginTop: { xs:'20%', md: "0%", lg: "0%" },
      }}
      PaperProps={{
        style: { borderTopLeftRadius: isMobile?"1.5rem":"",borderTopRightRadius: isMobile?"1.5rem":"",paddingTop:"1.2rem" },   }}
    >
      <DialogTitle>
        <Typography variant="h6" align="center" fontWeight={700}>
          Reschedule Order
        </Typography>
      </DialogTitle>
      <DialogContent>
      <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ textAlign: "center", width: "100%", height: "auto" }}>
        <DateAndTime confirmHandler={confirmHandler} />
            </Box>
      </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default RescheduleOrder;
