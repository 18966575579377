import { configureStore } from "@reduxjs/toolkit";
import ConfirmationDateTime from "./slices/ConfirmationDateTime";
import ConfirmationDialog from "./slices/ConfirmationDialog";
import GoogleMaps from "./slices/GoogleMaps";
import Payment from "./slices/Payment";
import ProfileSlice from "./slices/ProfileSlice";
import Rating from "./slices/Rating";
import OrderSlice from "./slices/OrderSlice";
import AuthSlice from "./slices/AuthSlice";
import LoaderSlice from "./slices/LoaderSlice";
import MenuSlice from "./slices/MenuSlice";
import GadgetsSlice from "./slices/GadgetsSlice";
import CategorySlice from "./slices/CategorySlice";
import CartSlice from "./slices/CartSlice";
import LocationSlice from "./slices/LocationSlice";

const store = configureStore({
  reducer: {
    confirmationDialog: ConfirmationDialog,
    googleMaps: GoogleMaps,
    confirmationDateTime: ConfirmationDateTime,
    profile: ProfileSlice,
    orders: OrderSlice,
    rating: Rating,
    payment: Payment,
    auth: AuthSlice,
    loader: LoaderSlice,
    menus: MenuSlice,
    gadgets: GadgetsSlice,
    category: CategorySlice,
    cart: CartSlice,
    location:LocationSlice
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
