import React from 'react'
import './css/LandingMain.css'
import TopBar from './components/TopBar'
import Hero from './components/Hero'
import InfoHero from './components/InfoHero'
import HowItWorks from './components/HowItWorks'
import Highlights from './components/Highlights'
import Comp from './components/Comp'
import Comparision from './components/Comparision'
import Testimonials from './components/Testimonials'
import FootBanners from './components/FootBanners'
import HowWeCompare from '../landingpage/component/HowWeCompare'
import Partner from './components/Partner'
// import Footer from '../landingpage/component/Footer'
import Footer from './components/Footer'
import FAQ from './components/FAQ'
import DeviceSection from './components/DeviceSection'
const LandingMain = () => {
  return (
    <div className="mainContainer">
      <div className="wrapper">
        <TopBar/>
        <Hero/>
        <DeviceSection/>
        <HowItWorks/>
        <InfoHero/>
        <Highlights/>
        <Comp/>
        {/* <Comparision/> */}
        <Testimonials/>
        <Partner/>
        <FAQ/>
        <FootBanners/>
      </div>
        <Footer/>
    </div>
  )
}

export default LandingMain