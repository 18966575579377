import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { noop } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import {
  PAYMENT_TYPE,
  REACT_APP_RAZ_KEY,
  REACT_APP_RAZ_SECRET,
  TABS,
} from "../../constants";
import { updateLoaderFields } from "../../store/slices/LoaderSlice";
import { updatePaymentFields } from "../../store/slices/Payment";
import CardPayment from "./CardPayment";
import NetbankingPayment from "./NetbankingPayment";
import PayLaterPayment from "./PayLaterPayment";
import QRCodePayment from "./QRCodePayment";
import UpiPayment from "./UpiPayment";
import WalletPayment from "./WalletPayment";

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    value === index && (
      <Box
        sx={{
          height: "60vh",
          paddingLeft: 2,
          paddingTop: 1,
          hidden: value !== index,
          width: "100%",
        }}
      >
        {children}
      </Box>
    )
  );
};

const PaymentOptionTabs = () => {
  const [value, setValue] = React.useState(0);
  const [mobilePayment, setMobilePayment] = React.useState({
    showMobilePaymentView: false,
    mobilePaymentcomponent: <></>,
  });

  const dispatch = useDispatch();

  const razorpayRef = React.useRef();
  let razorpayInstance = React.useRef();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  React.useEffect(() => {
    const razorpay = new window.Razorpay({
      key: REACT_APP_RAZ_KEY,
      key_secret: REACT_APP_RAZ_SECRET,
    });
    razorpayInstance.current = razorpay;

    dispatch(updateLoaderFields({ load: true }));
    razorpay.once("ready", (response) => {
      razorpayRef.current = response;
      dispatch(updateLoaderFields({ load: false }));
    });
    return () => {
      razorpayRef.current = null;
      razorpayInstance.current = null;
    };
  }, [dispatch]);

  React.useEffect(() => {
    return () =>
      setMobilePayment({
        showMobilePaymentView: false,
        MobilePaymentComponent: <></>,
      });
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openMobilePaymentTab = (paymentTab = "", label = "") => {
    let paymentOption = {
      showMobilePaymentView: true,
    };
    switch (paymentTab) {
      case PAYMENT_TYPE.CARD:
        paymentOption["mobilePaymentcomponent"] = (
          <CardPayment
            razorpay={razorpayInstance.current}
            razorpayRef={razorpayRef}
          />
        );
        break;
      case PAYMENT_TYPE.UPI:
        paymentOption["mobilePaymentcomponent"] = (
          <UpiPayment
            razorpay={razorpayInstance.current}
            razorpayRef={razorpayRef}
          />
        );
        break;
      case PAYMENT_TYPE.NETBANKING:
        paymentOption["mobilePaymentcomponent"] = (
          <NetbankingPayment
            razorpay={razorpayInstance.current}
            razorpayRef={razorpayRef}
          />
        );
        break;
      case PAYMENT_TYPE.WALLET:
        paymentOption["mobilePaymentcomponent"] = (
          <WalletPayment
            razorpay={razorpayInstance.current}
            razorpayRef={razorpayRef}
          />
        );
        break;
      case PAYMENT_TYPE.PAYLATER:
        paymentOption["mobilePaymentcomponent"] = (
          <PayLaterPayment
            razorpay={razorpayInstance.current}
            razorpayRef={razorpayRef}
          />
        );
        break;
      default:
        paymentOption["mobilePaymentcomponent"] = <></>;
        paymentOption["showMobilePaymentView"] = false;
        break;
    }
    dispatch(updatePaymentFields({
      title:label
    }));
    setMobilePayment(paymentOption);
  };

  const MobilePaymentTabComponent = ({
    label = "",
    value = "",
    icon = <></>,
    openMobilePaymentTab = noop,
  }) => (
    <Box
    style={{padding: '1.5rem 1rem'}}
      sx={{
        borderBottom: "1px solid #676767",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: 1,
        cursor: "pointer",

      }}
      onClick={() => openMobilePaymentTab(value, label)}
    >
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <Typography style={{color: '#0E62CB'}}>
          {icon} &nbsp;
          {label}
        </Typography>
      </Box>
      <RadioButtonUncheckedIcon style={{color: '#0E62CB'}}/>
    </Box>
  );

  const generateTabs = () => {
    return TABS.map((tab, index) => (
      <Tab
        sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}
        icon={<img src={tab.icon} alt={tab.icon} style={{ height: "4vh",minWidth:'80px' }} />}
        iconPosition="start"
        label={tab.label}
        key={index}
      />
    ));
  };

  const generateMobilePaymentTabs = () =>
    TABS.filter((t) => t.value !== PAYMENT_TYPE.QRCODE).map((tab, index) => (
      <MobilePaymentTabComponent
        label={tab.label}
        key={index}
        value={tab.value}
        openMobilePaymentTab={openMobilePaymentTab}
        icon={<img src={tab.icon} alt={tab.icon} style={{ height: "2vh",minWidth:'50px' }} />}
      />
    ));
  const tabs = generateTabs();

  const DesktopPaymentComponent = () => (
    <Box
    xs={12}
      sx={{
        bgcolor: "background.paper",
        display: "flex",
        height: "60vh",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          textAlign: "left",
          borderRight: 1,
          borderColor: "divider",
          width: "50%",
        }}
      >
        {tabs}
      </Tabs>
      <TabPanel value={value} index={0}>
        <QRCodePayment
          razorpay={razorpayInstance.current}
          razorpayRef={razorpayRef}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CardPayment
          razorpay={razorpayInstance.current}
          razorpayRef={razorpayRef}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UpiPayment
          razorpay={razorpayInstance.current}
          razorpayRef={razorpayRef}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <NetbankingPayment
          razorpay={razorpayInstance.current}
          razorpayRef={razorpayRef}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <WalletPayment
          razorpay={razorpayInstance.current}
          razorpayRef={razorpayRef}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <PayLaterPayment
          razorpay={razorpayInstance.current}
          razorpayRef={razorpayRef}
        />
      </TabPanel>
    </Box>
  );

  const mobilePaymentTab = generateMobilePaymentTabs();

  console.log(mobilePayment);
  const MobilePaymentComponent = () => (
    <Box
      sx={{
        
        bgcolor: "background.paper",
        height: "60vh",
        width: { lg: "60vw" },
      }}
    >
      {mobilePayment.showMobilePaymentView
        ? mobilePayment.mobilePaymentcomponent
        : mobilePaymentTab}
    </Box>
  );

  if (isDesktopOrLaptop) return <DesktopPaymentComponent />;
  if (isTabletOrMobile) return <MobilePaymentComponent />;
  return <></>;
};

export default PaymentOptionTabs;
