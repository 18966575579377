import { get } from "lodash";
import { getRequestWithParams, postRequest ,putRequest} from "..";
import store from "../../store";
import { GET_COUPONS, GET_VERIFY_COUPONS, UPDATE_CART } from "../ApiConstants";

export const updateItemsToCart = async (issueId = "") => {
  try {
    const payload = new FormData();
    payload.append("issueId", issueId);
    console.log("Payload: ",payload);
    const { data } = await postRequest(UPDATE_CART, payload);
    return get(data, "success", false);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const clearCart = async (issueId = "") => {
  try {
    const payload = new FormData();
    payload.append("issueId", issueId);
    const { data } = await putRequest(UPDATE_CART, payload);
    return get(data, "success", false);
  } catch (e) {
    console.log(e);
    return null;
  }
};


export const fetchCoupons = async (category = 1, city = 1) => {
  try {
    const payload = new URLSearchParams();
    payload.append("category", category);
    payload.append("city", city);
    const { data } = await getRequestWithParams(GET_COUPONS, payload);
    if (get(data, "success", false)) return get(data, "data", []);
    return [];
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const verifyCoupon = async (coupon) => {
  try {
    const state = store.getState();
    const category = get(state, "category.selectedCategory.category_id", 0);
    const city = get(state, "location.city", 1);
    const amount = get(state, "cart.totalAmount", 0);
    const payload = new URLSearchParams();
    payload.append("category", category);
    payload.append("city", city);
    payload.append("amount", amount);
    payload.append("coupon", get(coupon, "coupon_id", 0));
    const { data } = await getRequestWithParams(GET_VERIFY_COUPONS, payload);
    const couponVerified = get(data, "success", false);
    if (couponVerified) {
      const currentAmount = amount - get(coupon, "coupon_amount", 0);
      return { couponVerified: true, message: "", currentAmount };
    }
    return { couponVerified: false, message: get(data, "message", "") };
  } catch (e) {
    console.log(e);
    return null;
  }
};
