import { styled } from "@mui/material";
import React from "react";

const StyledStep = styled("div")((props) => ({
  width: "200px",
  height: "40px",
  position: "relative",
  background: props.backgroundColor,
  "&:after": {
    content: `""`,
    position: "absolute",
    left: 0,
    bottom: 0,
    width: 0,
    height: 0,
    borderLeft: props.first ? 0 : `20px solid #F5F6FA`,
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",
  },

  "&:before": {
    content: `""`,
    position: "absolute",
    right: "-20px",
    bottom: "0",
    width: 0,
    height: 0,
    borderLeft: props.last ? 0 : `20px solid ${props.backgroundColor}`,
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",
  },
}));

const TimelineComponent = ({
  firstStep = false,
  label = "",
  textColor = "",
  backgroundColor = "",
  lastStep = false,
}) => {
  return (
    <StyledStep
      first={firstStep}
      last={lastStep}
      backgroundColor={backgroundColor}
    >
      <div id="text-content">
        <p
          style={{
            color: textColor,
            textAlign: "center",
            fontSize: "16px",
            marginTop: "8px",
          }}
        >
          {label}
        </p>
      </div>
    </StyledStep>
  );
};

export default TimelineComponent;