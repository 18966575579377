import { Grid, Stack, Typography,Box } from "@mui/material";
import { height } from "@mui/system";
import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import DisplayComponent from "../../atoms/cart/DisplayComponent";

const CartComponent = () => {
  // console.log(cart);
  const cart_new = useSelector(({ cart }) => cart);
  const items = useSelector(({ cart }) => get(cart, "items", []));
  const totalAmount = useSelector(({ cart }) => cart.totalAmount);
  const convenience_fee_inc_tax = useSelector(({ orders }) => orders.convenience_fee_inc_tax);
  const discountedAmount = useSelector(({ cart }) => cart.discountedAmount);
  const appliedCouponcode = useSelector(({ cart }) => cart.appliedCoupon.coupon_code);
  const fullcode = "Discount coupon "+ appliedCouponcode + "";
  // console.log(cart_new);
  // console.log(items);

  const categoryItems = useSelector(({ category }) => category);

  // console.log("ategoryItem_tetsyds");
  // console.log(categoryItems);

  let cata_name = get(categoryItems, "selectedCategory.category_title", '');
  let brand_name = get(categoryItems, "selectedGadgetBrand.brand_title", '');
  let model_name = get(categoryItems, "selectedModel.model_title", '');
  const [catogryname, setcatogryname] = React.useState(cata_name);
  const [brandname, setbrandname] = React.useState(brand_name);
  const [modelname, setmodelname] = React.useState(model_name);

  return (
    <React.Fragment>
      <Typography variant="h6" align="left" fontWeight={600}>
        Your Cart 
      </Typography>
      <hr style={{marginBottom:"12px" }} />
      <Typography fontSize={"16px"} align="left">
        {catogryname} - {brandname} - {modelname}
      </Typography>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Stack spacing={0} sx={{ overflowY: "auto",maxHeight:"15vh" }}>
            {items &&
              items.map((issue, index) => (
                <DisplayComponent
                  key={index}
                  issue={get(issue, "issue_title", "---")}
                  amount={get(issue, "discounted_price", 0)}
                  data={issue}
                />
              ))}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {/* <DisplayComponent
            addFontWeight={true}
            issue="Total"
            amount={totalAmount + discountedAmount }
            displayDeleteIcon={false}
          /> */}
           <DisplayComponent
            // addFontWeight={true}
            issue="Taxes and fees"
            amount={convenience_fee_inc_tax}
            displayDeleteIcon={false}
          />
          {(discountedAmount==0) ? 
          ''
           : 
           <DisplayComponent
           type={"discount"}
           issue= {fullcode}  
           amount={(discountedAmount)}
           displayDeleteIcon={false}
           color={"#EB870E"}
         />
         }
          <Box sx ={{margin:"0.5rem"}}></Box>
           <DisplayComponent
            addFontWeight={true}
            issue="Grand total"
            amount={(totalAmount * 1) + (convenience_fee_inc_tax * 1) }
            displayDeleteIcon={false}
          />
          </Grid>
        </Grid>

    </React.Fragment>
  );
};
export default CartComponent;
