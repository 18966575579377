import { InputLabel, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentFields } from "../../../store/slices/Payment";

const ExpiryDate = () => {
  const expiryDate = useSelector(({ payment }) => payment.expiryDate);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    let { value } = e.target;
    if (value !== null || value !== undefined) {
      if (value.length > 2) {
        value = value.replace("/", "");
        let months = value.slice(0, 2);
        let years = value.slice(2);
        if (months >= 12) months = 12;
        let currentYear = parseInt((new Date().getFullYear() + "").slice(2));
        if (years >= currentYear + 20) years = currentYear + 20;
        dispatch(updatePaymentFields({ expiryDate: months + "/" + years }));
      } else {
        dispatch(updatePaymentFields({ expiryDate: value }));
      }
    }
  };

  return (
    <React.Fragment>
      <InputLabel htmlFor="my-input">Expiry Date</InputLabel>
      <TextField
        value={expiryDate}
        onChange={handleChange}
        placeholder="MM / YYYY"
        type="text"
        variant='filled'
        inputProps={{
          maxLength: 5,
            style:{
              padding: '0.8rem'
            }
        }}
      />
    </React.Fragment>
  );
};

export default ExpiryDate;
