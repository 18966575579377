import { InputAdornment, TextField } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileFields } from "../../store/slices/ProfileSlice";

const MobileNumberTextField = () => {
  const dispatch = useDispatch();
  const mobileNumber = useSelector(({ profile }) =>
    get(profile, "mobileNumber", "")
  );
  
  const error = useSelector(({ profile }) => get(profile, "error", false));

  const handleChange = (e) => {
    const value = e.target.value;
    console.log(error);
    if (error) {
      dispatch(updateProfileFields({ error: false }));
    }
    if (/^[0-9]+$/.test(value) || value === "") {
      dispatch(
        updateProfileFields({
          mobileNumber: e.target.value,
        })
      );
    }
  };

  return (
    <TextField
      autoFocus
      autocomplete="off"
      fullWidth
      type="tel"
      id={"mobileNumber"}
      placeholder={"Enter your mobile number (We’ll send OTP on this number)"}
      label="Mobile Number"
      variant="standard"
      error={error}
      helperText={error ? "Invalid Mobile Number" : ""}
      InputProps={{
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
        autocomplete: "off",
      }}
      value={mobileNumber}
      onChange={handleChange}
    />
  );
};

export default MobileNumberTextField;
