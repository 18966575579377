import noop from "lodash/noop";
import Geocode from "react-geocode";
import { YOUR_GOOGLE_MAPS_API_KEY } from "../constants";
import { updateGoogleMapsFields } from "../store/slices/GoogleMaps";
export const getCurrentLocation = async (lat, lng) => {
  Geocode.setApiKey(YOUR_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  const response = await Geocode.fromLatLng(lat, lng).catch((e) =>
    console.error(e)
  );
  const { long_name: postalCode = "" } =
    response.results[0].address_components.find((c) =>
      c.types.includes("postal_code")
    ) || {};

    const { long_name: newCity = "" } =
    response.results[0].address_components.find((c) =>
      c.types.includes("locality")
    ) || {};
   
    
    
  return {
//  address: response.results[0].formatted_address.split(",").slice(0, -3).join(" "),
  //  address: response.results[0].formatted_address,
  address: response.results[0].formatted_address,
    postalCode: postalCode,
    newCity:newCity
  };
};

export const fetchCurrentLocation = async (
  dispatch = noop,
  dispatchMethods = noop
) => {
  navigator.geolocation.getCurrentPosition(
    async (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      const { address, postalCode } = await getCurrentLocation(lat, lng);
      dispatch(
        dispatchMethods({
          position: { lat, lng },
          position_new: { lat, lng },
          address,
          textFieldValue: address,
          enableButton: true,
          postalCode: postalCode,
        })
      );

      dispatch(
        updateGoogleMapsFields({
         
          pincode:postalCode,
        })
      );

    },
    () => null
  );
};

export const fetchCurrentPosition = async (dispatch, dispatchMethods) => {
  navigator.geolocation.getCurrentPosition(
    async (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      dispatch(
        dispatchMethods({
          position: { lat, lng },
        })
      );
    },
    () => null
  );
};
