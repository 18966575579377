import { useRef, useEffect, useState } from 'react'
import axios from 'axios';
import noop from "lodash/noop";
import Geocode from "react-geocode";
import {
    DEFAULT_MAP_POSITION,
    YOUR_GOOGLE_MAPS_API_KEY,
} from "../../../constants";
import { Typography, Box, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import { useScreenResolution } from '../../../hooks/ResponsiveHook';
import { fetchCurrentLocation } from '../../../utils/GoogleMapsUtils';
import { useDispatch, useSelector } from "react-redux";
import { updateGoogleMapsFields } from '../../../store/slices/GoogleMaps';
import { get, isEmpty } from "lodash"
import { fetchUserProfile } from '../../../services/user-profile';
import { updateProfileFields } from '../../../store/slices/ProfileSlice';
import { useNavigate } from 'react-router-dom';
import quick from '../assets/Quick.svg'
import warranty from '../assets/warrn.svg'
import certified from '../assets/tool.svg'
import expert from '../assets/expert.svg'
import { DataObjectOutlined } from '@mui/icons-material';
import MapsAutoComplete from '../../new-google-maps/MapsAutoComplete';
import { GoogleMapsSlice } from '../../../store/slices/GoogleMaps';
import { ENQUIRY_GT } from '../../../services/ApiConstants';
import Loader from './Loader';
const LocationHero = () => {
    const HOST = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [PinCodeArea, setPinCodeArea] = useState([]);
    const [Inputvalue, setInputvalue] = useState("");
    const { isMobile } = useScreenResolution();
    const [isLoading, setisLoading] = useState(false);
    const profile = useSelector(({ profile }) => profile.user);
    // const full_name = get(profile, "user_fullname", "");
    // const ModelName = localStorage.getItem("modelName");
    // const mobile = get(profile, "user_mobile", "");
    // const issue = localStorage.getItem("issueName");
    const [enq_id, setenq_id] = useState();
    const autocompleteInput = useRef(null);

    const [Cus_info, setCus_info] = useState({});

    const [location, setLocation] = useState(null);
    const [Address, setAddress] = useState(null);

    const getCurrentLocationad = async (lat, lng) => {
        Geocode.setApiKey(YOUR_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("es");
        Geocode.setLocationType("ROOFTOP");
        Geocode.enableDebug();
        const response = await Geocode.fromLatLng(lat, lng).catch((e) =>
            console.error(e)
        );
        const { long_name: postalCode = "" } =
            response.results[0].address_components.find((c) =>
                c.types.includes("postal_code")
            ) || {};

        const { long_name: newCity = "" } =
            response.results[0].address_components.find((c) =>
                c.types.includes("locality")
            ) || {};



        return {
            //  address: response.results[0].formatted_address.split(",").slice(0, -3).join(" "),
            //  address: response.results[0].formatted_address,
            address: response.results[0].formatted_address,
            postalCode: postalCode,
            newCity: newCity
        };
    };

    const getCurrentLocation = async () => {

        setisLoading(true);

        try {
            if (navigator.geolocation) {
              const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
              });
        
              const { latitude, longitude } = position.coords;
              const { address, postalCode } = await getCurrentLocationad(
                latitude,
                longitude
              );
        
              console.log("Address:", address, "Postal Code:", postalCode);
        
              const token = localStorage.getItem('token');
              const data = {
                enquiryId: enq_id,
                addressFull: address,
                addressArea: '0',
                pincode: postalCode,
                longitude: longitude,
                latitude: latitude,
                gtpInternal: false,
                pincodeId: 0,
                areaId: 0
              };
        
              const response = await fetch(ENQUIRY_GT, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
              });
        
              const responseJson = await response.json();
        
              if (responseJson.success === false) {
                console.log("Error:", responseJson.error);
              } else {
                console.log("Success:", responseJson);
                setTimeout(() => {
                  setisLoading(false);
                  navigate("/temp_conform");
                }, 500);
              }
            } else {
              console.error('Geolocation is not supported by this browser.');
            }
          } catch (error) {
            console.error("Error:", error);
          }
    }
    const fetch_enquery_details = async () => {
        let token = localStorage.getItem("token");
        let enquiry_id_encrypted = localStorage.getItem("enquiry_id_encrypted");
        console.log("ENQID: ", localStorage.getItem("enquiry_id"));
        fetch(HOST + "/api/v1/users/enquiries?order=" + enquiry_id_encrypted + "", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then((response) => response.json())
            .then((responsejson) => {
                if (responsejson.success) {
                    setenq_id(responsejson.data[0].order_id);
                    console.log("Fetch ID: ", enq_id);
                }
                else {
                    console.log("NO success")
                }
            }).catch((error) => {
                console.log(error);
            })
    }
    const fetchPincode = async () => {
        const reqUrl = "https://www.eripbee.in/api/v1/pincodes_area";
        await fetch(reqUrl, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responsejson) => {
                // console.log(responsejson)
                let Data = responsejson.data;
                const listData = [];
                const obj = { label: "" }
                Data.map((item) => {
                    const str = item.loc_pincode_num + " - " + item.loc_area_title;
                    item.loc_area_title = str;

                })
                setPinCodeArea(Data);

            })
            .catch((error) => {
                console.log(error);
            })
    }
    const handleChange = (event, value) => {
        console.log(value);

    }
    const fetchProfile = () => {
        const name = localStorage.getItem("fullName");
        const mobile = localStorage.getItem("cus_mobile");
        const model_name = localStorage.getItem("modelName");
        const issue_name = localStorage.getItem("issueName");
        const obj = {
            name: name,
            mobile: mobile,
            modelName: model_name,
            issueName: issue_name
        }
        setCus_info(obj);
    }
    useEffect(() => {
        setTimeout(() => {
            fetch_enquery_details();
            fetchProfile();
        }, 1000);
        const google = window.google; // Make sure the Google Maps API is loaded before using it
        let autocomplete = "";
        if (window.google) {
            autocomplete = new google.maps.places.Autocomplete(autocompleteInput.current);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                console.log('Selected place:', place);
            });
        }


        // fetchPincode();

        PinCodeArea.map((opt) => {
            console.log(opt.loc_area_title);
        })

        const getUserProfile = async () => {
            const response = await fetchUserProfile();
            dispatch(
                updateProfileFields({
                    user: response,
                })
            );
        };

        if (isEmpty(profile)) {
            getUserProfile();
        }
    }, []);
    return (
        <div style={{ height: "90vh" }}>
            <Box sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "100%" : "58% 39%",
                gap: "2rem",
                padding: isMobile?"1rem":"2rem",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    borderRight: isMobile ? "" : "1px solid rgba(226, 226, 226, 1)",
                    borderBottom: isMobile ? "1px solid rgba(226, 226, 226, 1)" : "",
                    padding: isMobile ? "" : "2rem",
                    paddingBottom:isMobile?"2rem":""
                }}>
                    <div>
                        <Typography sx={{ fontSize: isMobile ? "26px" : "40px", fontWeight: "bold" }}>Where are you located? </Typography>
                        <Typography sx={{ fontSize: isMobile ? "16px" : "18px", color: "rgba(103, 103, 103, 1)" }}>Nearest professional will come to your home, work, or anywhere else and fix your device at doorstep.</Typography>
                    </div>
                    <MapsAutoComplete fromlanding={true} enq_id={enq_id}  setisLoading={setisLoading} />
                    <Autocomplete
                        sx={{ display: "none" }}
                        id="free-solo-demo"
                        freeSolo
                        options={PinCodeArea.map((option) => option.loc_area_title)}
                        onChange={handleChange}
                        open={Inputvalue.length >= 3}
                        renderInput={(params) => <TextField {...params}
                            // InputProps={{
                            //     startAdornment: (
                            //         <InputAdornment position="start">
                            //             <LocationOnOutlinedIcon />
                            //         </InputAdornment>
                            //     )
                            // }}
                            inputProps={{
                                ...params.inputProps,
                                minLength: 3,
                            }}
                            fullWidth placeholder='Enter your PIN code or address' onChange={(event) => setInputvalue(event.target.value)} />}
                    />
                    {/* <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LocationOnOutlinedIcon />
                                </InputAdornment>
                            )
                        }}
                        fullWidth placeholder='Enter your PIN code or address' /> */}
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "2rem"
                    }}>
                        <hr />
                        <Typography>Or</Typography>
                        <hr />
                    </Box>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Typography onClick={getCurrentLocation} sx={{
                            cursor: "pointer",

                        }}><MyLocationOutlinedIcon /> Use My Current Location</Typography>
                    </Box>
                    {isLoading && <Loader />}
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: isMobile?"1rem":"2rem",
                    padding: isMobile ? "" : "2rem"
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Typography sx={{ fontSize: isMobile ? "26px" : "40px", fontWeight: "bold" }}>Summary</Typography>
                        <Typography sx={{ fontSize: isMobile ? "16px" : "18px", color: "rgba(14, 98, 203, 1)", textDecoration: "underline", cursor: "pointer" }} onClick={() => { navigate('/ap-enquiry-order') }} >Change</Typography>
                    </Box>
                    <Box sx={{
                        borderBottom:"1px solid rgba(226, 226, 226, 1)",
                        paddingBottom:"1rem",
                    }}>
                        <Typography sx={{ fontSize: "18px", color: "rgba(103, 103, 103, 1)", marginBottom: "6px" }}>Contact Details</Typography>
                        <Typography sx={{ fontSize: "20px", textTransform: "capitalize" }}>{Cus_info.name}</Typography>
                        {/* <Typography sx={{ fontSize: "20px" }}>Pranay</Typography> */}
                        <Typography sx={{ fontSize: "18px", color: "rgba(103, 103, 103, 1)" }}>{Cus_info.mobile}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: "18px", color: "rgba(103, 103, 103, 1)", marginBottom: "6px" }}>Model & Issue</Typography>
                        <Typography sx={{ fontSize: "20px", textTransform: "capitalize" }}>{Cus_info.modelName}</Typography>
                        {/* <Typography sx={{ fontSize: "20px" }}>Issue</Typography> */}
                        <Typography sx={{ fontSize: "18px", color: "rgba(103, 103, 103, 1)", textTransform: "capitalize" }}>{Cus_info.issueName}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr 1fr",
                gap: "1rem",
                placeItems: "center",
                padding: "2rem",
                margin: isMobile ? "20px 0" : "40px 0",
                marginTop:"0",
                backgroundColor:"rgba(245, 249, 255, 1)"
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "3px"
                }}>
                    <img src={quick} alt="" />
                    <Typography fontWeight={"bold"} sx={{ fontSize: "18px", textAlign: "center" }}>Quick & Convenient</Typography>
                    <Typography sx={{ marginTop: "3px", textAlign: "center", color: "rgba(103, 103, 103, 1)", fontSize: "16px" }}>
                        Our professional may take upto 45 minutes to fix your device
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "3px"
                }}>
                    <img src={warranty} alt="" />
                    <Typography fontWeight={"bold"} sx={{ fontSize: "18px", textAlign: "center" }}>12 months warranty</Typography>
                    <Typography sx={{ marginTop: "3px", textAlign: "center", color: "rgba(103, 103, 103, 1)", fontSize: "16px" }}>
                        Get up to 12 months of warranty on device repairs
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "3px"
                }}>
                    <img src={certified} alt="" />
                    <Typography fontWeight={"bold"} sx={{ fontSize: "18px", textAlign: "center" }}>Certified Parts</Typography>
                    <Typography sx={{ marginTop: "3px", textAlign: "center", color: "rgba(103, 103, 103, 1)", fontSize: "16px" }}>We use genuine parts to ensure
                        safety of your device
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "3px"
                }}>
                    <img src={expert} alt="" />
                    <Typography fontWeight={"bold"} sx={{ fontSize: "18px", textAlign: "center" }}>Expert Professionals</Typography>
                    <Typography sx={{ marginTop: "3px", textAlign: "center", color: "rgba(103, 103, 103, 1)", fontSize: "16px" }}>
                        Our technicians are verified, trained and experienced to fix your devices
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}
export default LocationHero