import { createSlice } from "@reduxjs/toolkit";

export const LocationSlice = createSlice({
  name: "location",
  initialState: {
    city: 1,
    Load: false,
  },
  reducers: {
    updateLocationFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateLocationFields } = LocationSlice.actions;

export default LocationSlice.reducer;
