import { get } from "lodash";
import { getRequestWithParams, postRequest } from "..";
import store from "../../store";
import { CHECKPINCODE } from "../ApiConstants";



export const checkOrderPincode = async (pincode = "") => {
    // alert(pincode);
  
    // try {
    //   const payload = new FormData();
    //   payload.append("issueId", issueId);
    //   const { data } = await postRequest(UPDATE_CART, payload);
    //   return get(data, "success", false);
    // } catch (e) {
    //   console.log(e);
    //   return null;
    // }
    try {
        // const pinCode = (pincode, "pincode",0);
    const params = new URLSearchParams();
    params.append("pincode", pincode);
        
        // const params = pincode;
        // params.append("category", categoryId);
        const { data } = await getRequestWithParams(CHECKPINCODE, params);
//   console.log(data.data);
    //   return get(data, "success",data.success);
    if (get(data, "success", false)) return get(data, "data", []); // True || false 
    return [];
      } catch (e) {
        console.error(e);
        return null;
      }
  };