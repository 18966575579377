import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Rating,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PartnerAvatar from "../../molecules/partner/PartnerAvatar";
import PartnerName from "../../molecules/partner/PartnerName";
import PartnerQuestionnaire from "../../molecules/partner/PartnerQuestionnaire";
import {
  fetchOrderReviewQuestions,
  submitReviewRating,
  submitStarRating,
} from "../../services/review-rating";
import { updateRatingFields } from "../../store/slices/Rating";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
const PartnerRating = () => {
  const {isMobile} = useScreenResolution();


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const openRatingModule = useSelector(({ rating }) => rating.open);
  const rate = useSelector(({ rating }) => rating.rate);

  const [reviewQns, setReviewQns] = React.useState([]);

  const [selectedAnswers, updateSelectedAnswers] = React.useState([]);
  const [RatingCheck,setRatingCheck] = React.useState(null);
  const [remarks, setRemarks] = React.useState("");

  const setSelectedAnswers = (value) => {
    if (selectedAnswers) {
      if (selectedAnswers.indexOf(value) === -1) {
        updateSelectedAnswers([...selectedAnswers, value]);
      } else
        updateSelectedAnswers(
          selectedAnswers.filter((answer) => answer !== value)
        );
    }
  };
  const handleClose = () => {
    dispatch(
      updateRatingFields({
        open: false,
      })
    );
  };

  const updateRatings = (newValue) => {
    // alert(newValue)
    setRatingCheck(newValue);
    dispatch(
      updateRatingFields({
        rate: newValue,
      })
    );
  };

  React.useEffect(() => {
    setRatingCheck(null);
    const getReviewQns = async () => {
      const reviewQnas = await fetchOrderReviewQuestions();
      if (reviewQnas) {
        setReviewQns(reviewQnas);
      }
    };
    if (openRatingModule) getReviewQns();

    return () => {
      updateSelectedAnswers([]);
      setRemarks("");
      dispatch(
        updateRatingFields({
          rate: 0,
        })
      );
    };
  }, [openRatingModule, dispatch]);

  // const raten = useSelector(({ rating }) => rating);
  //  const ratem = get(raten, "rate", '');

  const submitRating = async () => {
      // alert(rate);
      
    const payload = {
      // question: get(reviewQns, "[0].question_id", ""),
      // answers: selectedAnswers,
      remarks: remarks,
    };
   const responsestar = await submitStarRating();
    const response = await submitReviewRating(payload);
    
    if (response) {
      dispatch(
        updateRatingFields({
          open: false,
          openFeedbackModule: true,
        })
      );
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={openRatingModule}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        marginTop: { xs: "28vh", md: "30%", lg: "0%" },
      }}
      PaperProps={{
        sx:{
          width:"100%",
          borderRadius:{lg: "8px"},
          borderTopLeftRadius: isMobile?"8px":"",
          borderTopRightRadius: isMobile?"8px":""
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "left" }}>
          <Box sx={{ width: "95%" }}>
            <Typography fontWeight={700} variant="h6" align="center">
              Rating and Review
            </Typography>
          </Box>
          <Box sx={{ width: "5%" }}>
            <CloseOutlinedIcon onClick={handleClose} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} marginTop="20px" justifyContent="space-between" alignItems="center">
          <Stack spacing={1} alignItems='center' gap={2}>
            <PartnerAvatar />
            <PartnerName />
            <Typography align="center" variant="body2">
              Please rate our partner and the service according to your
              experience
              <br/>
              <Rating
                name="simple-controlled"
                value={rate}
                onChange={(e, rating) => updateRatings(rating)}
                size="large"
                sx={{marginTop:"15px"}}
              />
            </Typography>
            {/* <PartnerQuestionnaire
              setSelectedAnswers={setSelectedAnswers}
              reviewQns={reviewQns}
            /> */}
            <TextField
              id="outlined-multiline-static"
              label="Tell us more..."
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              fullWidth
              rows={3}
              multiline
            />
          </Stack>
          <Button fullWidth variant="contained" onClick={submitRating} disabled = {RatingCheck === null?true:false} >
            Submit
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PartnerRating;
