import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_STATE = {
  gadgetBrands: [],
  gadgetModels: [],
  segmentModels: [],
  selectedCategory: {},
  selectedGadgetBrand: {},
  selectedModel: {},
  openGadgetsIssuePage: false,
  openSegmentsIssuePage: false,
  openModelsDialog: false,
};

export const CategorySlice = createSlice({
  name: "category",

  initialState: { categories: [], ...DEFAULT_STATE },
  reducers: {
    updateCategoryFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetCategoryData: (state, action) => (state = {...state,...DEFAULT_STATE}),
  },
});

export const { updateCategoryFields, resetCategoryData } =
  CategorySlice.actions;

export default CategorySlice.reducer;
