import { InputLabel, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentFields } from "../../../store/slices/Payment";

const CardNumber = () => {
  const cardNumber = useSelector(({ payment }) => payment.cardNumber) || '';

  const dispatch = useDispatch();

  const formatAndSetCcNumber = (e) => {
    const inputVal = e.target.value.replace(/ /g, ""); //remove all the empty spaces in the input
    let inputNumbersOnly = inputVal.replace(/\D/g, ""); // Get only digits

    if (inputNumbersOnly.length > 16) {
      //If entered value has a length greater than 16 then take only the first 16 digits
      inputNumbersOnly = inputNumbersOnly.substr(0, 16);
    }

    // Get nd array of 4 digits per an element EX: ["4242", "4242", ...]
    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" "); // Join all the splits with an empty space
    }

    dispatch(updatePaymentFields({ cardNumber: spacedNumber })); // Set the new CC number
  };
  return (
    <React.Fragment>
      <InputLabel htmlFor="my-input">Card Number</InputLabel>
      <TextField
        fullWidth
        variant='filled'
        placeholder="0000 0000 0000 0000"
        value={cardNumber}
        onChange={formatAndSetCcNumber}
        inputProps={{
          style:{
            padding: '0.8rem'
          }
        }}
      />
    </React.Fragment>
  );
};

export default CardNumber;
