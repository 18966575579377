import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import StepperComponent from "../../molecules/StepperComponent";
import {
  updateConfirmationDialogFields,
  updateToPreviousStep,
} from "../../store/slices/ConfirmationDialog";
import PaymentComponent from "../payment/PaymentComponent";
import Address from "./Address";
import ConfirmLocation from "./ConfirmLocation";
import DateAndTime from "./DateAndTime";
import Location from "./Location";
import SelectAddress from "./SelectAddress";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import { updateCartFields } from "../../store/slices/CartSlice";
import { useLocation } from "react-router-dom";
const ScheduleAppointment = () => {
  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {isMobile} = useScreenResolution();
  const open = useSelector(({ confirmationDialog }) => confirmationDialog.open);
  const allUserAddress = useSelector(
    ({ confirmationDialog }) => confirmationDialog.allUserAddress
  );
  const openCartsDialog = useSelector(({ cart }) => cart.openCartsDialog);
  const dispatch = useDispatch();

  const dialogTitle = useSelector(
    ({ confirmationDialog }) => confirmationDialog.dialogTitle
  );
  const step = useSelector(({ confirmationDialog }) => confirmationDialog.step);
  const showStepper = useSelector(
    ({ confirmationDialog }) => confirmationDialog.showStepper
  );

  const handleClose = () => {
    dispatch(
      updateConfirmationDialogFields({
        open: false,
      })
    );
  };

  const goToPreviousStep = () => {
    console.log(location.pathname);
    if( step === 0 && (location.pathname === "/temp_conform" || location.pathname === "/conform2")){
      return;
    }
    dispatch(updateToPreviousStep());
    if(step == 0){
      handleClose();
      dispatch(
        updateCartFields({
          openCartsDialog: true,
        })
      );
    }
  };

  React.useEffect(() => {
    return () => {
      dispatch(updateConfirmationDialogFields({}));
    };
  }, [dispatch]);
  const getCurrentStepComponent = () => {
    switch (step) {
      case 0:
        return (
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ textAlign: "center" }}>
              <DateAndTime />
            </Box>
          </Grid>
        );
      case 1:
        return (
          <Grid  item xs={12} md={12} lg={12} >
            {get(allUserAddress, "length", 0) > 0 ? (
              <SelectAddress />
            ) : (
              <Location />
            )}
          </Grid>
        );
      case 2:
        return (
          <Grid item xs={12} md={12} lg={12}>
            <Box
              sx={{
                // padding: 4,
                textAlign: "center",
              }}
            >
              <ConfirmLocation />
            </Box>
          </Grid>
        );
      case 3:
        return (
          <Grid item xs={12} md={12} lg={12}>
            <Box
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
                paddingTop: 3,
                paddingBottom: 0,
              }}
            >
              <Address />
            </Box>
          </Grid>
        );
      case 4:
        return (
          <Grid item xs={12} md={12} lg={12}>
            <Box
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
                paddingTop: 3,
                paddingBottom: 3,
              }}
            >
              <PaymentComponent />
            </Box>
          </Grid>
        );
      default:
        <div></div>;
    }
  };

  const componentToRender = getCurrentStepComponent();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="md"
      sx={{
        marginTop: { xs: step != 4?'20%':'20%', md: "0%", lg: "0%" },
      }}
      PaperProps={{
        style: { borderTopLeftRadius: isMobile?"1.5rem":"",borderTopRightRadius: isMobile?"1.5rem":"",paddingTop:"1.2rem" },   }}
      scroll="paper"
    >
      <DialogContent>
        <Grid container direction="row">
          <Grid item xs={1} lg={1} md={1}>
            <KeyboardBackspaceIcon
              sx={{ cursor: "pointer" }}
              onClick={goToPreviousStep}
            />
          </Grid>
          <Grid item xs={11} lg={11} md={11}>
            <Typography
              align="center"
              fontWeight={700}
              variant="h6"
              sx={{ paddingRight: 5 }}
            >
              {dialogTitle}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          {showStepper && (
            <Grid item xs={12} md={12} lg={12}>
              <StepperComponent />
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ textAlign: "center", width: "100%", height: "auto" }}>
              {componentToRender}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleAppointment;
