import store from "../store";
import { updateLoaderFields } from "../store/slices/LoaderSlice";

export const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);

export const convertToAddressFormat = (address = "") => {
  if (!address) return { addressOne: "", addressTwo: "" };
  // const addressParts = address.split(",");
  const addressParts = address.split(",").slice(0, -3);
  const mid = Math.round(addressParts.length / 2);
  return {
    addressOne: addressParts.slice(0, mid).join(","),
    addressTwo: addressParts.slice(mid).join(","),
  };
};

export const validateMobileNumber = (mobileNumber) => {
  return /^(\+\d{1,3}[- ]?)?\d{10}$/.test(mobileNumber);
};
export const validateMobileNumberNew = (mobileNumber) => {
  return /^(\+\d{1,3}[- ]?)?\d{11}$/.test(mobileNumber);
};

export const validateEmail = (email = "") => {
  // let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  //  let re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]$/;
  //  let re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]$/i;
   let re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

  return re.test(email);
};

export const openInNewTab = (url) => {
  window.open(url, "_blank").focus();
};

export const displayAlert = (alertText = "", success = false) => {
  store.dispatch(
    updateLoaderFields({
      alertText: alertText,
      severity: success ? "success" : "error",
    })
  );
};
