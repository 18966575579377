import React from 'react'
import {Box,
    Container,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
    Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import logo from '../../assets/logo.svg'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { fetchBrandsByCategory } from "../../services/categories";
import { updateCategoryFields } from "../../store/slices/CategorySlice";
import { updateLoaderFields } from "../../store/slices/LoaderSlice";
import { updateCartFields } from "../../store/slices/CartSlice";

const DevicesDialog = () => {
    const items = useSelector(({ category }) => get(category, "categories", []));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const openDeviceDialog = useSelector(({ cart }) => cart.openDeviceDialog);
  const openCategory = async (category = {}) => {
    // const groupp = get(category, "group_id", "");
    // alert(groupp);
    const data = await fetchBrandsByCategory(category);
    if (isEmpty(data)) {
      dispatch(
        updateLoaderFields({
          alertText: "No data Found",
          severity: "error",
        })
      );
      return;
    }
    // dispatch(
    //   updateCategoryFields({
    //     gadgetBrands: data,
    //     selectedCategory: category,
    //   })
    // );
    dispatch(
      updateCategoryFields({
        gadgetBrands: data,
        selectedCategory: category,
        segmentModels:[],
        gadgetModels:[],
        selectedGadgetBrand:{},
        openGadgetsIssuePage:false,
        selectedModel: {},
        openGadgetsmodel:false
      })
    );
    navigate(get(category, "category_slug_01", "/"));
    window.scrollTo(0, 0);
  };

  const handleClose = () => {
    
    dispatch(
      updateCartFields({
        openDeviceDialog: false,
      })
    );
  };

  const OpenDeviceDialog = () => {
    dispatch(
      updateCartFields({
        openDeviceDialog: true,
      })
    );
  };
  return (
    <Dialog open={openDeviceDialog}
            onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth="lg"
        sx={{
            marginTop: { xs: "30%" },
            height: { lg: "105vh" },
        }}
        PaperProps={{
            sx:{
            height:{lg:"78",xs:"105vh"},
            borderTopLeftRadius:"8px",
            borderTopRightRadius:"8px",
            }
        }}
    >   
        <DialogTitle>
        <CloseIcon cursor="pointer" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
            <Grid container flexDirection="column" marginBottom={"7rem"}>
            {items&&items.map((item,index)=>(
                <Grid container alignItems="center" justifyContent="space-between" sx={{padding:"0.8rem 0",borderBottom:"3px solid #f5f6fa"}}
                onClick={() => {openCategory(item);handleClose()}}
                >
                    {/* <img src={get(item, "category_icon_url", "")} style={{height:"6vh",maxWidth:"25%"}}></img> */}
                    <Box 
                  sx={{
                    height: {xs: "6vh" },
                    width: {xs: "25%" },
                    backgroundImage:`url(${get(item, "category_icon_url", "")})`,
                    backgroundPosition:"center",
                    backgroundSize:"contain",
                    backgroundRepeat:"no-repeat",
                  }}
                >
                </Box>
                    <Box display="flex" justifyContent="space-between" width="70%" >
                        <Typography>{get(item, "category_title", "")[0]}{get(item,'category_title',"").slice(1).toLowerCase()} </Typography>
                        <ArrowForwardIosOutlinedIcon/> 
                    </Box>
                </Grid>
                ))}
            </Grid>
        </DialogContent>
    </Dialog>
  )
}

export default DevicesDialog