import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import thankyouImage from "../../assets/thankyou-image.svg";
import { updateRatingFields } from "../../store/slices/Rating";

const Thankyou = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const openThankyouModule = useSelector(({ rating }) =>
    get(rating, "openThankyouModule", false)
  );

  const handleClose = () => {
    dispatch(
      updateRatingFields({
        openThankyouModule: false,
      })
    );
  };

  const backToBookings = () => {
    dispatch(
      updateRatingFields({
        openThankyouModule: false,
      })
    );
    navigate("/orders");
  };
  React.useEffect(() => {
    if (openThankyouModule) {
      setTimeout(() => {
        handleClose();
      }, [5000]);
    }
  }, [openThankyouModule]);

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={openThankyouModule}
      onClose={handleClose}
      aria-labelledby="responsive-Thankyou-dialog-title"
      sx={{
        marginTop: { xs: "60%", md: "50%", lg: "0%" },
      }}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={11} md={11} lg={11}></Grid>
          <Grid item xs={1} md={1} lg={1}>
            <CloseOutlinedIcon />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
          height="100%"
        >
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <Box
              textAlign={"center"}
              sx={{ height: { xs: "60%" }, width: "50%" }}
            >
              <img
                src={thankyouImage}
                alt={thankyouImage}
                height="100%"
                width="100%"
              />
            </Box>
            <Box>
              <Typography align="center" variant="h5" fontWeight={700}>
                Thank you
              </Typography>
              <Typography
                align="center"
                variant="h6"
                sx={{ color: "#676767" }}
                fontWeight={600}
              >
                You valuable feedback has been received
              </Typography>
            </Box>
          </Stack>
          <Button
            variant="contained"
            fullWidth
            onClick={backToBookings}
            sx={{ textTransform: "none" }}
          >
            Back to My Bookings
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Thankyou;
