import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React from "react";
import { useSelector } from "react-redux";
import { useScreenResolution } from "../hooks/ResponsiveHook";

const steps = ["Schedule Appointment", "Select Address", "Make Payment"];

const StepperComponent = () => {
  const activeStep = useSelector(
    ({ confirmationDialog }) => confirmationDialog.activeStep || 0
  );

  const { isDesktopOrLaptop } = useScreenResolution();

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  // const isStepSkipped = (step) => {
  //   return skipped.has(step);
  // };

  // const handleNext = () => {
  //   let newSkipped = skipped;
  //   if (isStepSkipped(activeStep)) {
  //     newSkipped = new Set(newSkipped.values());
  //     newSkipped.delete(activeStep);
  //   }
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped(newSkipped);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  return (
    <Box sx={{ paddingTop: 2, paddingBottom: 0 }}>
      <Stepper activeStep={activeStep} sx={{marginBottom:2}} >
        {steps.slice(0,window.location.pathname==='/thanks_ln_enq' ||  window.location.pathname === "/Thanku" || window.location.pathname === "/temp_conform" || window.location.pathname === "/conform2"?2:3) .map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                {isDesktopOrLaptop && label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {!isDesktopOrLaptop && (
        <Typography variant="h6" fontWeight='bold' align="center">
          {steps[activeStep]}
        </Typography>
      )}
    </Box>
  );
};

export default StepperComponent;
