import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, Typography,
  useTheme
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FeedbackDotStepper from "../../atoms/reviews/FeedbackDotStepper";
import FeedbackQuestionnaire from "../../molecules/feedback/FeedbackQuestionnaire";
import {
  fetchFeedbackQuestions,
  updateFeedback
} from "../../services/review-rating";
import { updateRatingFields } from "../../store/slices/Rating";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import { useState } from "react";
const Feedback = () => {
  const {isMobile} = useScreenResolution();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const [feedbackQns, setFeedbackQns] = React.useState([
    {
      question_id:1,
      question_content:"Q1",
      question_answers:[{answer_id:1,answer_content:"A"},{answer_id:2,answer_content:"B"},{answer_id:3,answer_content:"B"},{answer_id:4,answer_content:"D"}]
    },
    {
      question_id:2,
      question_content:"Q2",
      question_answers:[{answer_id:1,answer_content:"A"},{answer_id:2,answer_content:"B"},{answer_id:3,answer_content:"B"},{answer_id:4,answer_content:"D"}]
    }
  ]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedFeedbacks, setSelectedFeedbacks] = React.useState([]);
  const openFeedbackModule = useSelector(({ rating }) =>
    get(rating, "openFeedbackModule", false)
  );

  const handleClose = () => {
    dispatch(
      updateRatingFields({
        openFeedbackModule: false,
      })
    );
  };

  const submitFeedback = async () => {
    if (get(feedbackQns, "length") === get(selectedFeedbacks, "length")) {
      selectedFeedbacks.forEach(async (answer) => {
        await updateFeedback(answer);
      });

      dispatch(
        updateRatingFields({
          openFeedbackModule: false,
          openThankyouModule: true,
        })
      );
    }
  };

  // React.useEffect(() => {
  //   const getFeedbackQns = async () => {
  //     const feedback = await fetchFeedbackQuestions();
  //     if (feedback) {
  //       setFeedbackQns(feedback);
  //     }
  //   };
  //   if (openFeedbackModule) getFeedbackQns();

  //   return () => {
  //     setActiveStep(0);
  //     setFeedbackQns([]);
  //     setSelectedFeedbacks([]);
  //   };
  // }, [openFeedbackModule, dispatch]);

  const handleSelectedAnswer = async (answer = "") => {
    if( selectedFeedbacks.length > activeStep && selectedFeedbacks[activeStep].answer){
      let newSelection = [...selectedFeedbacks];
      newSelection[activeStep].answer = answer;
      setSelectedFeedbacks(newSelection);
    }
    else{
      setSelectedFeedbacks([
        ...selectedFeedbacks,
        {
          question: feedbackQns[activeStep]["question_id"],
          answer: answer,
        },
      ]);
    }
    (activeStep !== feedbackQns.length-1)?setActiveStep(activeStep + 1):setActiveStep(activeStep);
  };

  const getFeedbackQuestion = () => {
    const currentFeedback = { ...feedbackQns[activeStep] };
    return (
      <FeedbackQuestionnaire
        feedbackQnsLength = {feedbackQns.length}
        activeStep = {activeStep}
        setSelectedAnswers={handleSelectedAnswer}
        selected_answer = {get(currentFeedback,`selected_answer`)}
        question={get(currentFeedback, `question_content`)}
        options={get(currentFeedback, `question_answers`)}
      />
    );
  };
  // console.log(get(feedbackQns, "length"), get(selectedFeedbacks, "length"));
  // console.log(feedbackQns.length);
  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={openFeedbackModule}
      onClose={handleClose}
      aria-labelledby="responsive-Feedback-dialog-title"
      sx={{
        marginTop: { xs: "50vh", md: "50%", lg: "0%" },
      }}
      PaperProps={{
        sx:{
          borderRadius: {lg: "8px"},
          borderTopLeftRadius: isMobile?"8px":"",
          borderTopRightRadius: isMobile?"8px":""
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "left" }}>
          <Box sx={{ width: "95%" }}>
            <Typography fontWeight={700} variant="h6" align="center">
              Feedback Questions
            </Typography>
          </Box>
          <Box sx={{ width: "5%" }}>
            <CloseOutlinedIcon onClick={handleClose} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            // height: {
            //   lg: activeStep !== get(feedbackQns, "length") ? "60vh" : "25vh",
            // },
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          {activeStep !== get(feedbackQns, "length") && (
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap:"1.2rem"
              }}
            >
              {getFeedbackQuestion()}
              <FeedbackDotStepper
                steps={get(feedbackQns, "length")}
                activeStep={activeStep}
              />
              <Box width="100%" display={"flex"} justifyContent="space-between">
                <Button variant="outlined" sx={{width:"40%"}}  onClick={()=>{activeStep===0?setActiveStep(activeStep):setActiveStep(activeStep-1)}} >Prev</Button>
                <Button variant="outlined" sx={{width:"40%"}} onClick={()=>{activeStep===feedbackQns.length-1?setActiveStep(activeStep):setActiveStep(activeStep+1)}}>Next</Button>
              </Box>
            </Box>
          )}
          <Button
            fullWidth
            variant="contained"
            onClick={submitFeedback}
            disabled={
              get(feedbackQns, "length") === get(selectedFeedbacks, "length")?false:true
            }
            sx={{marginTop:"10px"}}
          >
            Submit Feedback
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Feedback;
