import React from 'react'
import '../css/Compare.css'
import { Typography, Button } from '@mui/material';
import lowprice from '../assets/lowprice.svg';
import doorstep from '../assets/doorstep.svg'
import time from '../assets/time.svg'
import payafter from '../assets/payafter.svg'
import nohidden from '../assets/nohidden.svg'
import instant from '../assets/instant.svg'
import yes from '../assets/correct.svg'
import no from '../assets/wrong.svg'
import logo from '../assets/ERIP Logo SVG.svg'
import other from '../../landingpage/vectorImage/other.svg'
import auth from '../../landingpage/vectorImage/auth.svg'
import rp2 from '../assets/2rp.svg'
import rp3 from '../assets/3rp.svg'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'
const Comp = ({ handler }) => {
    
    const { isMobile } = useScreenResolution();
    const go_up = () => {
        window.scrollTo(0, 0)
       
    }
    return (
        <section className='compareN' >
            <div className="compare_header">
                <Typography variant='h3' align='center' style={{ fontSize: isMobile ? "30px" : "40px", fontWeight: "bold" }} >We Always Outperform</Typography>
            </div>
            <div className="boxesN">
                <div className="boxN1 mainbox">
                    <div className="box_header">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="box_content">
                        <div className="compN">
                            <img src={lowprice} alt="" srcset="" />
                            <p>
                                Lowest Price Guaranted
                            </p>
                        </div>
                        <div className="compN">
                            <img src={doorstep} alt="" srcset="" />
                            <p>
                                Doorstep Service
                            </p>
                        </div>
                        <div className="compN">
                            <img src={time} alt="" srcset="" />
                            <p>
                                Track your service Real-Time
                            </p>
                        </div>
                        <div className="compN">
                            <img src={payafter} alt="" srcset="" />
                            <p>
                                Pay After Service
                            </p>
                        </div>
                        <div className="compN">
                            <img src={nohidden} alt="" srcset="" />
                            <p>
                                No Hidden Fees
                            </p>
                        </div>
                        <div className="compN">
                            <img src={instant} alt="" srcset="" />
                            <p>
                                Instant Quotation
                            </p>
                        </div>
                    </div>
                </div>
                <div className="box1 shadw" style={{ width: isMobile ? "80px" : "", fontSize: isMobile ? "1rem" : "1.2rem" }}>
                    <div className="box_header secondary_headerN">
                        <img src={other} alt="" srcset="" style={{ display: isMobile ? 'none' : '' }} />
                        Other Online Service
                    </div>
                    <div className="box_content">
                        <div className="tick">
                            <img src={rp2} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={no} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={no} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={no} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={yes} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={no} alt="" srcset="" />
                        </div>
                    </div>
                </div>
                <div className="box1 shadw" style={{ width: isMobile ? "80px" : "", fontSize: isMobile ? "0.9rem" : "1.2rem" }}>
                    <div className="box_header secondary_headerN">
                        <img src={auth} alt="" srcset="" style={{ display: isMobile ? 'none' : '' }} />
                        Authorised Service Center
                    </div>
                    <div className="box_content">
                        <div className="">
                            <img src={rp3} alt="" srcset="" width={"40px"} />
                        </div>
                        <div className="tick">
                            <img src={no} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={no} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={yes} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={yes} alt="" srcset="" />
                        </div>
                        <div className="tick">
                            <img src={no} alt="" srcset="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-grid gap-2 col-8 my-4 col-md-4" style={{ width: isMobile ? "100%" : "" }}>
                {/* <span id="shine-disaa" class="shine" style={shine}></span> */}
                <Button variant='contained' sx={{padding:"1rem 3rem", textTransform:"none", fontSize:"18px",background:"#0E62CB" }} fullWidth = {isMobile} onClick={() => { go_up() }}>
                    Get quotes
                </Button>
            </div>
        </section>
    )
}

export default Comp