import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DisplayComponent from "../../atoms/cart/DisplayComponent";

const Discount = () => {
  // const totalAmount = useSelector(({ cart }) => cart.totalAmount);
  const totalAmount = useSelector(({ cart }) => cart.total_new_amount);
  const discountedAmount = useSelector(({ cart }) => cart.discountedAmount);
  // alert(totalAmount);
  return (
    <Box
      sx={{
        borderTop: "2px solid #DAE3FE",
        padding: "2px",
      }}
    >
      <DisplayComponent
        issue="Discount"
        amount={discountedAmount}
        color="#EB870E"
        displayDeleteIcon={false}
        type={"discount"}
      />
      <DisplayComponent
        addFontWeight={true}
        issue="Total"
        amount={(totalAmount) - (discountedAmount)}
        displayDeleteIcon={false}
      />
    </Box>
  );
};

export default Discount;
