import deleteIcon from "../../assets/payment/delete-icon.svg";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { removeItem, updateCartItems } from "../../store/slices/CartSlice";

const DisplayComponent = ({
  displayDeleteIcon = true,
  amount = "---",
  issue = "",
  color = "#000",
  data = {},
  addFontWeight = false,
  type = "",
}) => {
  const dispatch = useDispatch();
  const removeItemFromCart = () => {
    dispatch(
      updateCartItems({
        issue: data,
        add: false,
      })
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography fontWeight={addFontWeight ? 700 : 400} sx={{ color: color }}>
        {issue}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography textAlign={"right"} fontWeight={addFontWeight ? 700 : 400} sx={{ color: color }}>
          {type==="discount"?"-":""}&#8377;{amount}
        </Typography>
        {/* {displayDeleteIcon && (
          <React.Fragment>
            &nbsp;&nbsp;
            <img
              src={deleteIcon}
              alt={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={removeItemFromCart}
            />
          </React.Fragment>
        )} */}
      </Box>
    </Box>
  );
};

export default DisplayComponent;
