import { createSlice } from "@reduxjs/toolkit";

export const RatingSlice = createSlice({
  name: "rating",
  initialState: {
    open: false,
    rate: 0
  },
  reducers: {
    updateRatingFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateRatingFields } = RatingSlice.actions;

export default RatingSlice.reducer;
