import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateDateTimeFields } from "../store/slices/ConfirmationDateTime";
import { getDay, getMonth, isTomorrow, nthDay } from "../utils/DateUtils";

const ConfirmationDateComponent = ({
  value = new Date(),
  valueKey = new Date(),
  index = 0,
}) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    // alert("sdsd");
    dispatch(
      updateDateTimeFields({
        selectedDate: value,
      })
    );
    localStorage.setItem("selecteddate", value);
  };

  const checkDisplayMonth = () => {
    if (isTomorrow(value, false)) return "Today";
    else if (isTomorrow(value, true)) return "Tomorrow";
    else return getMonth(value);
  };

  return (
    <Button
      sx={{
        height: "auto",
        padding: "2vh 0",
        border: "none",
        boxShadow: "rgb(0 0 0 / 10%) 0px 2px 8px",
        width: {lg: '132px'},
        fontSize: { xs:'0.7rem',lg: '1rem'},
        fontWeight:'bold',
        padding: {xs: '1rem 2.4rem'},
        color:
          value && valueKey && value.toDateString() === valueKey.toDateString()
            ? "#fff"
            : "#000",
        backgroundColor:
          value && valueKey && value.toDateString() === valueKey.toDateString()
            ? "#1976d2"
            : "#fff",
        "&:hover":{
              backgroundColor: value && valueKey && value.toDateString() === valueKey.toDateString()?"#44a0fc":"#fff",
              color: value && valueKey && value.toDateString() === valueKey.toDateString()?'#fff':"#000"
            }
      }}
      key={`${index}Date`}
      onClick={handleClick}
      name={value}
      size="small"
      fullWidth
    >
      {getDay(value)}
      <br />
      {value.getDate()}
      {nthDay(value)}
      <br />
      {checkDisplayMonth()}
      
    </Button>
  );
};
export default ConfirmationDateComponent;
