import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateDateTimeFields } from "../store/slices/ConfirmationDateTime";

const ConfirmationTimeComponent = ({
  label = "",
  valueKey = "",
  value = "",
}) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    dispatch(
      updateDateTimeFields({
        selectedTime: parseInt(value),
        selectedTime_label: label,
      })
    );

    localStorage.setItem("selectedTime_new", value);
  localStorage.setItem("selectedTime_label_new", label);
  };

  return (
    <Button
      onClick={handleClick}
      fullWidth
      sx={{
        height: {lg: "auto"},
        padding: {lg: "2vh 4vh",xs:"8px 0"},
        width: '100%',
        border: "none",
        boxShadow: "rgb(0 0 0 / 10%) 0px 2px 8px",
        color: value === valueKey ? "#fff" : "#000",
        backgroundColor: value === valueKey ? "#1976d2" : "#fff",
        fontWeight:'bold',
        "&:hover":{
          backgroundColor: value === valueKey?"#44a0fc":"#fff",
          color: value === valueKey ?'#fff':"#000"
        }
      }}
    >
      {label} 
    </Button>
  );
};
export default ConfirmationTimeComponent;
