import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { get, isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import loginImage from "../../assets/home/login.svg";
import AgreeTermsConditions from "../../atoms/login/AgreeTermsConditions";
import MobileNumberTextField from "../../atoms/login/MobileNumberTextField";
import { sendOtp, verifyOtp,sendOtp_new,updateUserProfile,fetchUserProfile } from "../../services/user-profile";
import { updateProfileFields } from "../../store/slices/ProfileSlice";
import { displayAlert, validateMobileNumber } from "../../utils";
import OtpTimer from "./OtpTimer";
import {getCart } from "../../services/user-profile";
import {
  updateCartFields,
  updateCartItems,
  updateCartItems_new
} from "../../store/slices/CartSlice";

const Login = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const mobileNumber = useSelector(({ profile }) =>
    get(profile, "mobileNumber", "")
  );

  const [loader, setLoader] = React.useState(false);
  const [user_has_name , setuser_has_name] =  React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [name, setname] = React.useState("");
  const [stage, setStage] = React.useState({
    title: "Please Verify your Mobile Number",
    phase: "LOGIN",
    button: "Continue",
  });
  // const [user_has_name , setuser_has_name] =  React.useState(false); 
  const openLogin = useSelector(({ profile }) => profile.openLogin);
  const userLoggedIn = useSelector(({ profile }) => profile.loggedIn);
  //  const user_has_name = useSelector(({ profile }) => profile.user_has_name);
  const redirectToHomePage = useSelector(
    ({ profile }) => profile.redirectToHomePage
  );
  const termsConditionCheck = useSelector(
    ({ profile }) => profile.termsConditionCheck
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(
        updateProfileFields({
          mobileNumber: "",
          error: false,
        })
      );
      setOtp("");
      setStage({
        title: "Please Verify your Mobile Number",
        phase: "LOGIN",
        button: "Continue",
      });
    };
  }, [openLogin, dispatch]);

  const handleClose = (event, reason = "") => {
    if (reason && reason === "backdropClick") {
      if (!userLoggedIn && redirectToHomePage) {
        dispatch(
          updateProfileFields({
            redirectToHomePage: false,
          })
        );
        navigate("/", { replace: true });
      }
    }
    dispatch(
      updateProfileFields({
        openLogin: false,
      })
    );
    event.preventDefault();
  };

  const loginUser = async () => {
    
    if (validateMobileNumber(mobileNumber)) {
      setLoader(true);
      // const response = await sendOtp(mobileNumber);
      const response = await sendOtp_new(mobileNumber);
      // console.log('response');
      // console.log(response);
      // alert(response.user_has_name);
      // if (response) {
      //   setStage({
      //     title: "Enter OTP",
      //     phase: "OTP",
      //     button: "Login",
      //   });
      // }
      // setLoader(false);
       if (response.success) {
        setStage({
          title: "Enter OTP",
          phase: "OTP",
          button: "Login",
        });
      }
      // dispatch(updateProfileFields({ user_has_name: true }));
      setuser_has_name(response.user_has_name);
     
      // alert(user_has_name);
      // alert("loder"+loader);
 
       setLoader(false);
    } else {
      dispatch(updateProfileFields({ error: "Invalid Mobile Number" }));
    }
  };

  const verifyOtpHandler = async () => {
    if (isEmpty(otp)) {
      alert("Invalid otp");
    } else {
      setLoader(true);
      const response = await verifyOtp(mobileNumber, otp);
      if (response) {
        displayAlert("Login sucessful", true);
        const token = get(response, "authorisation.token", "");
        const userData = get(response, "user_data", "");
        dispatch(
          updateProfileFields({
            openLogin: false,
            loggedIn: true,
            token: token,
            user: userData,
            redirectToHomePage: false,
          })
        );
        get_cart();
        if (redirectToHomePage) {
          navigate(0);
        }
      }
      setLoader(false);

      if(!user_has_name){
//  alert(name);
if (isEmpty(name)) {
alert('enter name');
}else{
const payload = new FormData();
      payload.append("userName", name);
      // dispatch(updateLoaderFields({ load: true }));
      const response = await updateUserProfile(payload);

      getUserProfile();
}



      }


    }
  };

  const getUserProfile =  async () =>{

    const response = await fetchUserProfile();
      dispatch(
        updateProfileFields({
          user: response,
        })
      );

  }

  const get_cart = async() =>{
    const response = await getCart();

    if(response==''){

      dispatch(
        updateCartFields({
          dbitems:[],
          cart_model:{},
          cart_category_title:{},
          cart_brand_title:{},
          cart_model_segment_title:{},
          // displayBookNow:true
        })
      );
      dispatch(
        updateCartItems_new({
          issue:response,
          add:true
          
        })
      );
     
    }else{

    dispatch(
      updateCartFields({
        dbitems:response,
        cart_model:response[0].model_segment_id,
        cart_category_title:response[0].category_title,
        cart_brand_title:response[0].brand_title,
        cart_model_segment_title:response[0].model_segment_title,
        displayBookNow:true
      })
    );
    dispatch(
      updateCartItems_new({
        issue:response,
        add:true
        
      })
    );
    }

  }

  const handleClick = () => {
    if (stage.phase === "LOGIN") loginUser();
    else if (stage.phase === "OTP") verifyOtpHandler();
  };
  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={openLogin}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        marginTop: { xs: "18vh", md: "0%", lg: "0%" },
        height: { lg: "104vh" },
      }}
      PaperProps={{
        sx:{
          borderRadius:{lg: "8px"}
        }
      }}
    >
      <Backdrop open={loader} />
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "left" }}>
          <Box sx={{ width: "96%" }}>
            <Typography fontWeight={700} variant="h6" align="center">
              {stage.title}
            </Typography>
          </Box>
          <Box sx={{ width: "4%" }}>
            <CloseOutlinedIcon
              onClick={handleClose}
              sx={{ paddingTop: "1px", cursor: "pointer" }}
            />
          </Box>
        </Box>
        {stage.phase === "OTP" && (
          <Typography align="center">
            We’ve sent an OTP to +91 {mobileNumber}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box sx={{ height: { xs: "30vh", lg: "25vh" } }}>
            <img
              src={loginImage}
              alt={loginImage}
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
          {stage.phase === "LOGIN" && (
            <React.Fragment>
              <MobileNumberTextField />
              <AgreeTermsConditions />
            </React.Fragment>
          )}
          {stage.phase === "OTP" && (
            <React.Fragment>
              {user_has_name ? 
              <></>
              
              :
              <TextField
              autoFocus
               value={name}
              onChange={(e) => setname(e.target.value)}
              type="text"
              variant="standard"
              placeholder="Enter the your name"
              label="Name"
            />
            }
              <TextField
                autoFocus
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                type="number"
                variant="standard"
                placeholder="Enter the OTP here"
                label="OTP"
              />
              <OtpTimer />
            </React.Fragment>
          )}
           {user_has_name ?
          <Button
            variant="contained"
            fullWidth
            onClick={handleClick}
            disabled={
              (stage.phase === "LOGIN" &&
                (!mobileNumber || !termsConditionCheck)) ||
              (stage.phase === "OTP" && !otp)
            }
          >
            {stage.button}
          </Button>
          :
          <Button
            variant="contained"
            fullWidth
            onClick={handleClick}
            disabled={
              (stage.phase === "LOGIN" &&
                (!mobileNumber || !termsConditionCheck)) ||
              (stage.phase === "OTP" && (!otp || !name))
            }
          >
            {stage.button}
          </Button>
}

        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default Login;
