import React, { useState, useEffect } from 'react'
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../../services/user-profile";
import {
    updateCartFields,
    updateCartItems,
    updateCartItems_new
} from "../../../store/slices/CartSlice";
import { updateItemsToCart, clearCart } from "../../../services/cart";
import { updateConfirmationDialogFields } from '../../../store/slices/ConfirmationDialog';
import { GET_ISSUES_GADGETS_DETAIL } from '../../../services/ApiConstants';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Typography,
    DialogActions,
    Grid,
    useMediaQuery,
    useTheme,
    Button
} from "@mui/material";
import Coupon from '../../landingpage/component/Coupon';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import { useScreenResolution } from '../../../hooks/ResponsiveHook'
import Accordian from './Accordian';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ADDICON from '../assets/Add.svg'
import coupon from '../assets/coupon.svg'
import { getRequest } from '../../../services';
import { GET_TIMESLOTS } from '../../../services/ApiConstants';
import { getFilteredTimeIntervals } from '../../../utils/DateUtils';

const ConformationHero = () => {
    const HOST = process.env.REACT_APP_API_URL;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { isMobile, isMiniMobile } = useScreenResolution();
    const [Modal, setModal] = useState(false);
    const [model_id, setmodel_id] = useState();
    const [issuelist, setissuelist] = useState([]);
    const [showModal_coupon, setshowModal_coupon] = React.useState(false);
    const dispatch = useDispatch();

    const [apply_coupon, setapply_coupon] = useState({
        code: '',
        c_amount: 0,
        c_description: ''
    });
    const [enqdetails, setwnqdetails] = useState({
        model: "",
        brand: "",
        device: "",
        issue_name: "",
        issue_display_price: "",
        issue_discounted_price: "",
        issue_repair_duration: "",
        issue_warranty: "",
        issue_repair_type: "",
    });
    const itemsInCart = useSelector(({ cart }) => get(cart, "dbitems", [])) || [];
    var total_display_price = itemsInCart.reduce((sum, item) => (sum * 1) + (item.issue_display_price * 1), 0);
    const totalamt = useSelector(({ cart }) =>
        get(cart, "total_new_amount", 0)
    );
    const totalamtdiscont = useSelector(({ cart }) =>
        get(cart, "discountedAmount", 0)
    );
    const checkItemInCart = (id) => {

        if (get(itemsInCart, "length", 0)) {
            let item = itemsInCart.find((i) => i.issue_id === id);
            if (item)
                return true;
        }
        return false;
    };
    const cart_model = useSelector(({ cart }) => cart.cart_model);

    const fetch_enquery_details = () => {
        // alert("wewe");
        let token = localStorage.getItem("token");
        let enquiry_id_encrypted = localStorage.getItem("enquiry_id_encrypted");

        let bearer = "Bearer " + token;

        // alert(enquiry_id_encrypted);

        fetch(
            HOST + "/api/v1/users/enquiries?order=" + enquiry_id_encrypted + "",
            {
                method: "GET",

                headers: {
                    Authorization: bearer,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.success == false) {
                    // setissuelist([]);
                } else {
                    console.log("Enquiry Details", responseJson.data[0]);
                    // let model = responseJson.data[0].order_model_segment;
                    // alert(model);

                    const nextFormState = {
                        ...enqdetails,
                        model: responseJson.data[0].order_model_segment,
                        issue_name: responseJson.data[0].order_issues[0].issue_name,
                        issue_display_price:
                            responseJson.data[0].order_issues[0].issue_display_price,
                        issue_discounted_price:
                            responseJson.data[0].order_issues[0].issue_discounted_price,
                        issue_repair_duration:
                            responseJson.data[0].order_issues[0].issue_repair_duration,
                        issue_warranty: responseJson.data[0].order_issues[0].issue_warranty,
                        issue_repair_type:
                            responseJson.data[0].order_issues[0].issue_repair_type,

                        // mobile_error:'',
                    };
                    setwnqdetails(nextFormState);

                    localStorage.setItem(
                        "model",
                        responseJson.data[0].order_model_segment
                    );
                    localStorage.setItem(
                        "issue_name",
                        responseJson.data[0].order_issues[0].issue_name
                    );
                    localStorage.setItem(
                        "issue_display_price",
                        responseJson.data[0].order_issues[0].issue_display_price
                    );
                    localStorage.setItem(
                        "issue_discounted_price",
                        responseJson.data[0].order_issues[0].issue_discounted_price
                    );
                }
                get_all_issue(responseJson.data[0].order_model_segment_id);
                setmodel_id(responseJson.data[0].order_model_segment_id);
                // setLoading(false);
                //  console.log(responseJson.data)
            })
            .catch((error) => {
                //Error
                console.error(error);
            });
    };
    const get_added_cart = async () => {
        const response = await getCart();
        if (response == '') {
            dispatch(
                updateCartFields({
                    dbitems: [],
                    cart_model: '',
                    cart_category_title: {},
                    cart_brand_title: {},
                    cart_model_segment_title: {},
                })
            );
            dispatch(
                updateCartItems_new({
                    issue: response,
                    add: true

                })
            );

        } else {
            dispatch(
                updateCartFields({
                    dbitems: response,
                    cart_model: response[0].model_segment_id,
                    cart_category_title: response[0].category_title,
                    cart_brand_title: response[0].brand_title,
                    cart_model_segment_title: response[0].model_segment_title,
                })
            );
            dispatch(
                updateCartItems_new({
                    issue: response,
                    add: true

                })
            );
        }

    }
    const [alert, setAlert] = useState(false);
    const updateCart = async (issue, add = !checkItemInCart(), asadd) => {
        removecouponcode();
        var total_cart_item = get(itemsInCart, "length", 0);

        //  alert(asadd);
        //  console.log(itemsInCart);

        if (!add) {
            //  alert('remove_coupon');
            //  alert(appliedCoupon);
            // console.log(appliedCoupon);
            // console.log('appliedCoupon');
            // if (isEmpty(appliedCoupon)) {

            // }else{

            dispatch(
                updateCartFields({
                    appliedCoupon: {},
                    // totalAmount: totalAmount + parseInt(discountedAmount),
                    discountedAmount: 0,
                })
            );
            // alert('remove_coupon');
            // }
        }


        if (asadd == 0) {
            // alert(total_cart_item);
            if (total_cart_item < 2) {
                // alert("Please choose one or more issues from the list");
                setAlert(true);
            } else {

                if (cart_model == model_id) {
                    add_tocart(issue, add);
                } else if (cart_model == '') {
                    add_tocart(issue, add);
                } else {

                    //  setOpen(true);

                }

            }

        } else {

            if (cart_model == model_id) {
                add_tocart(issue, add);
            } else if (cart_model == '') {
                add_tocart(issue, add);
            } else {

                //  setOpen(true);

            }

        }

        // alert(total_cart_item);







    };

    const add_tocart = async (issue, add) => {
        // alert("sdsd");
        if (!issue) return;
        const response = await updateItemsToCart(get(issue, "issue_id"));
        if (response) {

            get_added_cart();

            dispatch(
                updateCartItems({
                    issue,
                    add,
                })
            );
        }

    }


    const get_all_issue = (model_seg) => {

        let token = localStorage.getItem("token");


        let bearer = "Bearer " + token;

        fetch(HOST + '/api/v1/issues_by_models_detail?city=1&model=' + model_seg + '', {

            method: 'GET',

            headers: {
                Authorization: bearer,
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        }).then((response) => response.json())
            .then((responseJson) => {
                ;
                // console.log(responseJson)
                if (responseJson.success == false) {
                    setissuelist([]);

                } else {
                    setissuelist(responseJson.data);
                }

                // setLoading(false);
                //  console.log(responseJson.data)


            }).catch((error) => {
                //Error 
                console.error(error);
            });

    }



    const create_order = () => {
        // alert("working");

        localStorage.setItem("from_landing", 1);
        dispatch(
            updateConfirmationDialogFields({
                open: true,
                // activeStep: 0,
                // step: 2,
            })
        );

        // add_address();
        //  alert(enquiryId);
        // setTimeout(() => {
        //   alert(addressid);
        // }, 5000);
    };
    const select_coupon = () => {
        // alert("working");
        setshowModal_coupon(true);
    }

    const removecouponcode = () => {
        const nextFormState = {

            code: '',
            c_amount: 0,
            c_description: ''


            // mobile_error:'',
        };
        setapply_coupon(nextFormState);
    }
    const handleClose = () => {
        setAlert(false);
    };

    const [nextSlot, setNextSlot] = useState('No available slots today');
    const getNextTimeSlot = async () => {
        const { data } = await getRequest(GET_TIMESLOTS);
        if (data.data) {
            const filterdSLots = getFilteredTimeIntervals(data.data);
            setNextSlot(filterdSLots[0].title);
        }
    }
    useEffect(() => {
        fetch_enquery_details();
        get_added_cart();
        getNextTimeSlot();

        // fetchWarranty();
    }, []);
    return (
        <div>
            <Coupon showModal_coupon={showModal_coupon} handlernew={setshowModal_coupon} total={enqdetails.issue_discounted_price} coupon_set={setapply_coupon} />
            <Dialog
                open={alert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography> Cart Cannot be Empty</Typography>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={Modal}
                // open
                fullWidth
                fullScreen={fullScreen}
                sx={{
                    marginTop: isMobile ? "30%" : "0",
                    Padding: "0"
                }}
                PaperProps={{
                    sx: {
                        borderRadius: { lg: "8px" },
                        borderTopLeftRadius: isMobile ? "1.5rem" : "",
                        borderTopRightRadius: isMobile ? "1.5rem" : "",
                        
                    }
                }}
            >
                <DialogTitle display={"flex"} justifyContent="space-between">
                    <Typography sx={{fontWeight:"bold",fontSize:"24px"}}>Add More Issue</Typography>
                    <Typography sx={{ cursor: "pointer" }} onClick={() => setModal(Modal ^ 1)} ><CloseIcon /></Typography>
                </DialogTitle>
                <DialogContent>
                    {issuelist.length === 0 && <Typography>No More Issue for this device</Typography>}
                    {issuelist.map((item, index) => (
                        <Grid>
                            <Grid sx={{
                                display:"grid",
                                gridTemplateColumns:"50% 25% 25%",
                            }}>
                                <Box>
                                    <Typography variant="h6" fontSize={isMobile ? '14px' : '22px'} sx={{fontWeight:"600"}} >{item.issue_title}</Typography>
                                    <Box>
                                        <Typography sx={{color:"#7B7B7B",fontSize:isMobile?"13px":"21px"}}>{item.warranty_period} Warranty</Typography>
                                        <Typography sx={{color:"#7B7B7B",fontSize:isMobile?"13px":"21px"}}> Free {item.repair_type} </Typography>
                                    </Box>
                                </Box>
                                <Box >
                                        <Typography variant='h6' sx={{fontWeight:"600",fontSize:isMobile?"14px":"22px"}}>₹{" "}{item.discounted_price}</Typography>
                                </Box>

                                {!checkItemInCart(item.issue_id) ?
                                    <Button variant="outlined" fullWidth  sx={{height:"60px",fontWeight:"bold", border:"2px solid #0E62CB"}} onClick={() => updateCart(item, '', 1)}>
                                        Add
                                    </Button>
                                    :
                                    <Button color="error" fullWidth variant="outlined" sx={{height:"60px",fontWeight:"bold", border:"2px solid red"}}  onClick={() => updateCart(item, '', 0)}>

                                        Remove

                                    </Button>
                                }

                            </Grid>
                            <hr />
                        </Grid>
                    ))}
                </DialogContent>
            </Dialog>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: "100%",
                gap: "2rem",
                padding: isMobile ? "0.5rem" : "2rem",
            }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.2rem"
                    }}
                >
                    <Typography sx={{ fontSize: isMobile ? "22px" : "32px", fontWeight: "bold" }} >Pricing Details</Typography>
                    <Box sx={{
                        maxHeight: "150px",
                        overflowY: "scroll"
                    }}>
                        <Box>
                            {itemsInCart && itemsInCart[0] && <Typography sx={{ fontSize: isMobile ? "18px" : "24px", color: "#676767" }}>Model - {itemsInCart[0].model_segment_title}</Typography>}
                        </Box>
                        {itemsInCart.map((item, index) => (
                            <Box key={index} sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                                gap: "0.8rem"
                            }}>
                                <Box sx={{display:"flex",gap:isMobile?"0px":"10px",alignItems:isMobile?"start":"center",flexDirection:isMobile?"column":"row"}}>
                                    <Typography sx={{ fontSize: isMobile ? "16px" : "24px", fontWeight: isMobile ? "bold" : "" }} >
                                        {item.issue_title}
                                    </Typography>
                                    <Typography sx={{ fontSize: isMobile ? "12px" : "22px", fontWeight: isMobile ? "bold" : "",textTransform:"capitalize" }}>
                                        {`(Warranty ${item.issue_warranty} .Free ${item.issue_repair_type})`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: isMobile ? "0.8rem" : "2rem" }} >
                                    <Typography sx={{ color: "#858585", textDecoration: "line-through", fontSize: isMobile ? "17px" : "22px" }} > ₹ {item.issue_display_price}</Typography>
                                    <Typography sx={{ fontSize: isMobile ? "17px" : "22px" }}>₹ {item.issue_discounted_price}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Typography sx={{ fontSize: isMobile ? "16px" : "18px", color: "#0E62CB", textDecoration: "none", cursor: "pointer", fontWeight: "bold" }} onClick={() => setModal(true)}>
                        <img src={ADDICON} alt="add issue Icon" style={{ margin: "5px" }} />
                        Add more/Change issues</Typography>
                    {apply_coupon.code == '' && <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }} >
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <img src={coupon} className="coupon icon" alt="" style={{ width: isMobile ? "25px" : "40px" }} />
                            <Typography fontSize={isMobile ? "18px" : "22px"} fontWeight={'bold'}>
                                3 Offer Available {!isMobile && '-'} {isMobile && <br />} <span style={{ color: "#048A81" }}>Save up to ₹2000</span></Typography>
                        </Box>
                        <Typography fontSize={"20px"} sx={{ color: "#0E62CB", textDecoration: "none", cursor: "pointer", border: "1px solid #0E62CB", padding: isMobile ? "0.5rem 0.8rem" : "0.5rem 1rem", borderRadius: "3px" }} onClick={() => select_coupon()} >View all</Typography>
                    </Box>}
                    {
                        apply_coupon.code == '' ? null : <Box>
                            <Box sx={{
                                border: "1px solid #80808042",
                                width: "95%",
                                margin: "10px auto",
                                borderRadius: "4px",
                                padding: "5px 8px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                                <Box>
                                    <Typography fontSize={"18px"}><span style={{ color: "#048A81" }} >{apply_coupon.code.toUpperCase()}</span> applied</Typography>
                                    <Typography fontSize={"14px"}>{apply_coupon.c_description}</Typography>
                                </Box>
                                <Box onClick={() => removecouponcode()}>
                                    <CancelOutlinedIcon sx={{ fontSize: "2rem", color: "#0E62CB", cursor: "pointer" }} />
                                </Box>
                            </Box>
                        </Box>
                    }
                    < Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }} >
                        <Box>
                            <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "18px" : "20px") : "24px", fontWeight: "bold" }}>Total {!isMobile && 'Payable'} Amount*</Typography>
                            <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "14px" : "16px") : "18px", color: "#7B7B7B" }}>Tax & Fees Not Included</Typography>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "end"
                        }}>
                            <Typography sx={{ fontSize: isMobile ? "20px" : "24px", fontWeight: "bold", padding: isMobile ? "2px 5px" : "5px 8px" }} >
                                ₹ {totalamt - apply_coupon.c_amount}
                            </Typography>
                            <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "14px" : "16px") : "18px", color: "#7B7B7B", textAlign: "right" }}>
                                Pay After Service
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: isMobile ? "start" : "end",
                    }}>
                        <Typography sx={{ color: "#7B7B7B", fontSize: "16px", textAlign: "left",width: isMobile ? "100%" : "288px", textTransform:"capitalize" }}>Fastest available time slot is <span style={{color:"black"}}> {nextSlot}</span></Typography>
                        <Button variant='contained' sx={{ padding: "10px 16px", fontSize: "20px", textTransform: "none", background: "#0E62CB", width: isMobile ? "100%" : "288px" }} onClick={() => create_order()}>Place Order</Button>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default ConformationHero

{/* <Box sx={{
    display: 'flex',
    gap: "10px",
}}>
    <a href="https://api.whatsapp.com/send?phone=+9403890334&amp;text=Hi ERIP, I would like to book a service for my Device" style={{ width: "50%", textDecoration: "none" }}><Button variant='outlined' fullWidth  sx={{display: "flex", alignItems: "center", border: "2px solid #25D366", padding: "8px", fontSize: "18px", textTransform: "none", color: "#25D366", '&:hover': { border: "2px solid #25D366" } }} >  <div style={{display:"flex", flexDirection:"column"}}> <small>WhatsApp Us</small> <small style={{fontSize:"14px"}}> (Instant Reply 10AM - 7PM) </small> </div> <WhatsAppIcon sx={{ fontSize:"35px", margin: isMobile ? "5px" : "5px 10px", display:isMobile?"none":"" }} /> </Button></a>
    <a href="tel:9403890334" style={{ width: "50%", textDecoration: "none" }}><Button variant='outlined' fullWidth sx={{ display: "flex", alignItems: "center", border: "2px solid ", padding: "8px", fontSize: "18px", textTransform: "none", '&:hover': { border: "2px solid" } }} >  <div style={{display:"flex", flexDirection:"column"}}> <small>Call Us</small> </div> <PhoneIcon sx={{ fontSize:"35px", margin: isMobile ? "5px" : "5px 10px",display:isMobile?"none":"" }} /> </Button></a>
</Box> */}



// Button
{/* <Box sx={{
    background: "#CCFFF0",
    border: "1px solid #00996B",
    borderRadius: "5px",
    padding: isMobile ? (!isMobile && isMiniMobile ? "2px 6px" : "2px 12px") : "5px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#00996B",
    fontSize: isMiniMobile || isMobile ? "14px" : "20px"
    }}>
        SAVED ₹{" "}
        {total_display_price -
        ((totalamt * 1) - (apply_coupon.c_amount * 1))}
</Box> */}