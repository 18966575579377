import React, { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useScreenResolution } from '../../../hooks/ResponsiveHook';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Button, Typography, Box} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Brand_Form = ({ devicedetails, setdevicedetails, setStep, Step }) => {
   
  const HOST = process.env.REACT_APP_API_URL;
  // const [modeldetails,setmodeldetails] = useState({
  //     model_name:'',
  //     model_id:'',
  // });
  const { isMobile } = useScreenResolution();
  const [BrandList, setBrandList] = useState([]);
  const fetchbrandList = async () => {
    fetch(HOST + '/api/v1/brands_by_category?category=' + devicedetails.device_id + '', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json())
      .then((respJson) => {
        if (respJson.success === false){setBrandList([]);} 
        else{

       
        const newList = respJson.data.filter((item) => item.brand_id !== 2); 
        setBrandList(newList);
    }
      }).catch((error) => {
        console.log(error);
      });
  }
  console.log(BrandList);
  const setBrand = async (BrandName, BrandId) => {
    const BrandInfo = {
        device_name:BrandName,
        device_id:devicedetails.device_id,
        brand_id:BrandId
    }
     setdevicedetails(BrandInfo);
   setStep(2);
  }
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    fetchbrandList();
  },[])
  return (
    <>
      {!isMobile && <FormControl fullWidth  >
        <InputLabel id="demo-simple-select-label">Select Brand</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          open
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300
              }
            }
          }}
        >
          <MenuItem onClick={() => setStep(Step - 1)}><ArrowBackIcon /></MenuItem>
          {BrandList.map((item, index) => (
            <MenuItem key={index} value={10} onClick={() => setBrand(item.brand_title, item.brand_id)} > {item.brand_title}</MenuItem>
          ))}
        </Select>
      </FormControl>}
      {isMobile && <Button variant='outlined' sx={{ border: "1px solid #ababab", padding: "15px 14px", color: "#5d5d5d", textTransform: "capitalize", display: "flex", justifyContent: "space-between", fontSize: "16px", display: Step === 2 ? "" : "none" }} endIcon={<KeyboardArrowDownIcon />} onClick={handleClickOpen} >Select Brand</Button>}

      { isMobile &&<Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={fullScreen}
        maxWidth="md"
        onClose={handleClose}
        sx={{
          marginTop: "40%",
          Padding: "0"
        }}

        PaperProps={{
          sx: {
            borderRadius: { lg: "8px" },
            borderTopLeftRadius: isMobile ? "1.5rem" : "",
            borderTopRightRadius: isMobile ? "1.5rem" : ""
          }
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle >
        <Box onClick={()=>setStep(Step-1)} ><ArrowBackIcon/></Box>
        <Typography align='center' style={{ fontSize: "1.6rem",width:"100%", fontWeight:"bold" }}>Select Brand</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "0.8rem" }}>
              {BrandList.map((item, index) => (
                <Box key={index} sx={{ cursor: "pointer", borderBottom: "3px solid #F5F6Fa", paddingY: 0.8 }} onClick={() =>setBrand(item.brand_title, item.brand_id)} >
                  <Typography style={{ fontSize: "1.2rem" }} >{item.brand_title}</Typography>
                </Box>
              ))}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>}
    </>
  )
}

export default Brand_Form