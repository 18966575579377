import { get } from "lodash";
import { postRequest, postRequestWithAuth } from "..";
import { PAYMENT_TYPE } from "../../constants";
import store from "../../store";
import { handlePaymentSuccess } from "../../utils/PaymentUtils";
import { PAYMENT_CREATE_ORDER, VALIDATE_UPI_ID } from "../ApiConstants";

export const makePayment = async ({
  razorpay = {},
  type = "card",
  metaData = {},
}) => {
  const state = store.getState();
  const { cart, payment, orders } = state;
  let totalAmount = get(cart, "totalAmount", 0);
  if (get(payment, "cartItemsFromProfile.total")) {
    totalAmount = parseFloat(get(payment, "cartItemsFromProfile.total", 0));
  }
  if (!totalAmount) {
    alert("Invalid Amount");
    return;
  }
  const payload = {
    amount: totalAmount * 100,
    currency: "INR",
  };
  const userPayload = createUserPayload();
  const paymentRequestPayload = createRequestPayloadForPayment({
    type,
    metaData,
  });
  if (get(payment, "cartItemsFromProfile.total")) {
    let orderId = get(orders, "orderDetails.order_id", null);
    if (orderId) {
      pay({
        razorpay,
        paymentRequestPayload,
        payload,
        userPayload,
        orderId,
      });
    } else {
      alert("Invalid Order ID");
    }
    return;
  }
  const orderId = await createOrder(payload);
  if (orderId) {
    pay({
      razorpay,
      paymentRequestPayload,
      payload,
      userPayload,
      orderId,
    });
  }
};

const pay = ({
  razorpay,
  paymentRequestPayload,
  payload,
  userPayload,
  orderId,
}) => {
  razorpay.createPayment({
    ...paymentRequestPayload,
    ...payload,
    ...userPayload,
    order_id: orderId,
  });

  razorpay.on("payment.success", (resp) => {
    handlePaymentSuccess();
  }); // will pass payment ID, order ID, and Razorpay signature to success handler.

  razorpay.on("payment.error", (resp) => {
    console.log("Error", resp);
    alert("Payment Failed. Please try again")
  }); // will pass error object to error handler
};

export const createOrder = async (payload = {}) => {
  try {
    const { data } = await postRequest(PAYMENT_CREATE_ORDER, payload);
    return get(data, "order_id", "");
  } catch (e) {
    console.log(e);
    return false;
  }
};

const createRequestPayloadForPayment = ({ type = "card", metaData = {} }) => {
  if (!type) return null;
  const requestPayload = {};
  requestPayload["method"] = type;
  switch (type) {
    case PAYMENT_TYPE.CARD:
      requestPayload["card"] = get(metaData, "card", {});
      break;
    case PAYMENT_TYPE.QRCODE:
      break;
    case PAYMENT_TYPE.WALLET:
      requestPayload["wallet"] = get(metaData, "wallet", {});
      break;
    case PAYMENT_TYPE.UPI:
      requestPayload["vpa"] = get(metaData, "vpa", "");
      break;
    case PAYMENT_TYPE.NETBANKING:
      requestPayload["bank"] = get(metaData, "bank", {});
      break;
    case PAYMENT_TYPE.PAYLATER:
      requestPayload["provider"] = get(metaData, "provider", {});
      break;
    default:
      break;
  }
  return requestPayload;
};

const createUserPayload = () => {
  const state = store.getState();
  const email = get(state, "profile.user.user_email", "");
  const contact = get(state, "profile.user.user_mobile", "");
  return {
    email,
    contact,
  };
};

export const verifyUpiID = async (vpa = "") => {
  if (!vpa) return null;

  const payload = {
    vpa,
  };
  try {
    const { data } = await postRequestWithAuth(VALIDATE_UPI_ID, payload);
    console.log(data);
    return data?.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};
