import { Stack } from "@mui/material";
import React from "react";
import PaylaterComponent from "../../atoms/payments/PaylaterComponent";

import iciciLogo from "../../assets/payment/icici-logo.svg";
import lazypayLogo from "../../assets/payment/lazypay-logo.svg";
import simplLogo from "../../assets/payment/simpl-logo.svg";
import { get } from "lodash";

// const paylaterOptions = [
//   { logo: simplLogo, code: "getsimpl", label: "Simpl" },
//   { logo: iciciLogo, code: "icic", label: "ICICI Bank PayLater" },
//   { logo: lazypayLogo, code: "lazypay", label: "LazyPay" },
// ];
const PayLaterPayment = ({ razorpayRef = {}, razorpay = {} }) => {
  const paylaterOptions = get(razorpayRef, "current.methods.paylater", null);

  const generatePaylaterOptions = () => {
    if (paylaterOptions) {
      const paylaterKeys = paylaterOptions ? Object.keys(paylaterOptions) : [];
      return paylaterKeys.map((paylater, index) => (
        <PaylaterComponent
          razorpay={razorpay}
          key={index}
          label={paylater}
          code={paylater}
        />
      ));
    }
    return [];
  };

  const paylaterMenus = generatePaylaterOptions();

  return (
    <Stack spacing={2} >
      {paylaterMenus && paylaterMenus.length > 0 ? paylaterMenus : <></>}
    </Stack>
  );
};

export default PayLaterPayment;
