import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../services/user-profile";
import { updateLoaderFields } from "../../store/slices/LoaderSlice";
import { updateProfileFields } from "../../store/slices/ProfileSlice";
import { validateMobileNumberNew } from "../../utils";

const AddMobileNumber = () => {
  const open = useSelector(({ profile }) => get(profile,'mobileNumberDialog',false));

  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = React.useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]+$/.test(value) || value === "") {
      setMobileNumber(value);
    }
    // alert(value.length)
  };

  const handleClose = () => {
    dispatch(
      updateProfileFields({
        mobileNumberDialog: false,
      })
    );
  };

  const validate = () => {
    return mobileNumber ? validateMobileNumberNew(mobileNumber) : false;
  };

  React.useEffect(() => {
    return () => setMobileNumber("");
  }, [open]);

  const saveMobileNumber = async () => {
    if (!mobileNumber) return alert("Invalid Mobile Number");

    const payload = new FormData();
    payload.append("userAddAltData", true);
    payload.append("userAltMobile", mobileNumber);
    dispatch(updateLoaderFields({ load: true }));
    const response = await updateUserProfile(payload);
    dispatch(updateLoaderFields({ load: false }));
    if (response) {
      alert("Profile Updated Successfully");
      handleClose();
    } else {
      alert("Profile Update Error");
    }
  };

  return (
    <Dialog maxWidth={"xs"} fullWidth={true} open={open} onClose={handleClose}>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={11} md={11} lg={11}>
            <Typography align="center">Add Alternative Number</Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1}>
            <CloseIcon onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          id="standard-input"
          label="Mobile Number"
          type="tel"
          variant="standard"
          fullWidth
          value={mobileNumber}
          error={validate()}
          helperText={validate() ? "Invalid Mobile Number" : ""}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ cursor: "pointer" }} position="start">
                +91
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={saveMobileNumber}
          variant="contained"
          fullWidth
          disabled={validate()}
        >
          Add Number
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMobileNumber;
