import { createSlice } from "@reduxjs/toolkit";

export const MenuSlice = createSlice({
  name: "menus",
  initialState: {},
  reducers: {
    updateMenuFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateMenuFields } = MenuSlice.actions;

export default MenuSlice.reducer;
