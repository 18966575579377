import React from 'react'
import TopBar from './components/TopBar'
import ThankYouHeader from './components/ThankYouHeader'
import Testimonials from './components/Testimonials'
import Footer from './components/Footer'
const ThankYou = () => {
  return (
    <div className="mainContainer">
    <div className="wrapper">
      <TopBar/>
      <ThankYouHeader/>
      {/* <Testimonials/> */}
    </div>
      <Footer/>
</div>
  )
}

export default ThankYou