import React, { useState, useEffect } from 'react'
import Accordian from './Accordian'
import store from '../../../store';
import { get } from "lodash";
import Moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { useScreenResolution } from "../../../hooks/ResponsiveHook";
import { updateConfirmationDialogFields } from '../../../store/slices/ConfirmationDialog';
import { useNavigate } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import EditIcon from '@mui/icons-material/Edit';
import { getFilteredTimeIntervals } from '../../../utils/DateUtils';
import addressIcon from '../assets/addressIcon.svg'
import timeicon from '../assets/timeicon.svg'
import dateicon from '../assets/dateIcon.svg'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  DialogActions,
  Grid,
  useMediaQuery,
  useTheme,
  Button
} from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { GET_TIMESLOTS } from '../../../services/ApiConstants';
import { getRequest } from '../../../services';
const ConformationHero2 = () => {
  const [animate, setAnimate] = useState(false);

  const [nextSlot, setNextSlot] = useState('No available slots today');
  const getNextTimeSlot = async () => {
    const { data } = await getRequest(GET_TIMESLOTS);
    if (data.data) {
      const filterdSLots = getFilteredTimeIntervals(data.data);
      setNextSlot(filterdSLots[0].title);
    }
  }

  const animateClick = () => {
    if (!animate) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
        // create_order();
        update_enquery();
      }, 10000);
    }
  };
  const itemsInCart = useSelector(({ cart }) => get(cart, "dbitems", [])) || [];

  const new_issue = itemsInCart.map((item, index) => (item.issue_id));
  //  console.log(result);
  //  console.log('result');

  var total_display_price = itemsInCart.reduce((sum, item) => (sum * 1) + (item.issue_display_price * 1), 0);

  const totalamt = useSelector(({ cart }) =>
    get(cart, "total_new_amount", 0)
  );

  const { isMobile, isMiniMobile } = useScreenResolution();
  const dispatch = useDispatch();

  const [showModal_coupon, setshowModal_coupon] = React.useState(false);

  const [apply_coupon, setapply_coupon] = useState({
    code: '',
    c_amount: 0,
  });
  // const convenience_fee_inc_tax = useSelector(({ orders }) => orders.convenience_fee_inc_tax);
  const [enqdetails, setwnqdetails] = useState({
    model: "",
    brand: "",
    device: "",
    issue_name: "",
    issue_display_price: "",
    issue_discounted_price: "",
    issue_repair_duration: "",
    issue_warranty: "",
    issue_repair_type: "",
  });
  const [addressid, setaddressid] = useState();
  const [addressfull, setaddressfull] = useState();
  const [apointmnetdate, setapointmnetdate] = useState();
  const [apointmnetvalue, setapointmnetvalue] = useState();

  const [couponcoeddetails, setcouponcoeddetails] = useState({
    couponcode: localStorage.getItem("couponcode"),
    couponamount: localStorage.getItem("amount"),
    couponid: localStorage.getItem("couponid"),
    coupondesc: localStorage.getItem("coupondesc"),

  });
  const [checked, setChecked] = React.useState(true);

  const [coup_id, setcoup_id] = React.useState('');

  const [ext_data, setext_data] = React.useState({
    date_new: '',
    timeloat: '',
    Time_label: '',
    saddress: [],


  });
  const [selectedDate, uselectedDate] = React.useState(localStorage.getItem("selecteddate"));
  const [selectedTime, uselectedTime] = React.useState(localStorage.getItem("selectedTime_new"));
  const [selectedTime_label, usselectedTime_label] = React.useState(localStorage.getItem("selectedTime_label_new"));
  const [selectedAddress, uselectedAddress] = React.useState(JSON.parse(localStorage.getItem("addresss_new")));

  const selectedDate_neww = useSelector(
    ({ confirmationDateTime }) => confirmationDateTime.selectedDate
  );
  const selectedAddress_neww = useSelector(
    ({ confirmationDialog }) => confirmationDialog.selectedAddress
  );
  const HOST = process.env.REACT_APP_API_URL;

  const update_enquery = () => {
    let enquiryId = localStorage.getItem("enquiry_id");
    let token = localStorage.getItem("token");

    let bearer = "Bearer " + token;
    fetch(HOST + "/api/v1/users/enquiries_issues", {
      method: "POST",
      body: JSON.stringify({
        city: 1,
        enquiry: enquiryId,
        issues: new_issue,

      }), //post body
      headers: {
        Authorization: bearer,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //  console.log(responseJson);
        //  console.log('sdssdsd')
        if (responseJson.success == false) {
          // setissuelist([]);
          alert(responseJson.message);
        } else {
          create_order();

        }

        // setLoading(false);
        //  console.log(responseJson.data)
      })
      .catch((error) => {
        //Error
        console.error(error);
      });

  }

  const update_predata = () => {
    // window.location.reload();
    // alert("sdsd");
    uselectedDate(localStorage.getItem("selecteddate"));
    uselectedTime(localStorage.getItem("selectedTime_new"));
    usselectedTime_label(localStorage.getItem("selectedTime_label_new"));
    uselectedAddress(JSON.parse(localStorage.getItem("addresss_new")));
    // const selectedDate = localStorage.getItem("selecteddate");
    // const selectedTime = localStorage.getItem("selectedTime_new");
    // const selectedTime_label =  localStorage.getItem("selectedTime_label_new");
    // const selectedAddress =  JSON.parse(localStorage.getItem("addresss_new"));
    //  alert(localStorage.getItem("selecteddate"));
    //  alert(localStorage.getItem("selecteddate"));
  }
  // const selectedDate = useSelector(
  //   ({ confirmationDateTime }) => confirmationDateTime.selectedDate
  // );

  // const selectedTime = useSelector(
  //   ({ confirmationDateTime }) => confirmationDateTime.selectedTime
  // );
  // const selectedTime_label = useSelector(
  //   ({ confirmationDateTime }) => confirmationDateTime.selectedTime_label
  // );

  // const selectedAddress = useSelector(
  //   ({ confirmationDialog }) => confirmationDialog.selectedAddress
  // );
  // alert(selectedTime_label);
  // console.log("selectedAddresssdssd");
  // console.log(selectedAddress);



  //  alert(selectedDate);
  const handleChange = () => {
    setChecked(!checked);
  };

  const convenience_fee_inc_tax = localStorage.getItem("convenience_fee_inc_tax");
  // let couponcode = localStorage.getItem("couponcode");
  // let couponamount = localStorage.getItem("amount");
  // alert(convenience_fee_inc_tax);
  const fetch_enquery_details = () => {

    //  alert(selectedDate);
    // const todaynew = Date.now();
    // alert(Moment(todaynew).format('Do MMM'));
    // let apintdate = localStorage.getItem("apointmnetdate");
    let apintdate = selectedDate;
    //  alert(ext_data.date);
    // alert(Moment(apintdate ).format('Do MMM'));
    setapointmnetdate(Moment(apintdate).format('Do MMM'));
    setapointmnetvalue(selectedTime);
    let token = localStorage.getItem("token");
    let enquiry_id_encrypted = localStorage.getItem("enquiry_id_encrypted");

    //  setaddressfull(localStorage.getItem("fulladdress"));


    let bearer = "Bearer " + token;

    // alert(enquiry_id_encrypted);

    fetch(
      HOST + "/api/v1/users/enquiries?order=" +
      enquiry_id_encrypted +
      "",
      {
        method: "GET",

        headers: {
          Authorization: bearer,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success == false) {
          // setissuelist([]);
        } else {
          // let model = responseJson.data[0].order_model_segment;
          // alert(model);

          const nextFormState = {
            ...enqdetails,
            model: responseJson.data[0].order_model_segment,
            issue_name: responseJson.data[0].order_issues[0].issue_name,
            issue_display_price:
              responseJson.data[0].order_issues[0].issue_display_price,
            issue_discounted_price:
              responseJson.data[0].order_issues[0].issue_discounted_price,
            issue_repair_duration:
              responseJson.data[0].order_issues[0].issue_repair_duration,
            issue_warranty: responseJson.data[0].order_issues[0].issue_warranty,
            issue_repair_type:
              responseJson.data[0].order_issues[0].issue_repair_type,

            // mobile_error:'',
          };
          setwnqdetails(nextFormState);

          localStorage.setItem(
            "model",
            responseJson.data[0].order_model_segment
          );
          localStorage.setItem(
            "issue_name",
            responseJson.data[0].order_issues[0].issue_name
          );
          localStorage.setItem(
            "issue_display_price",
            responseJson.data[0].order_issues[0].issue_display_price
          );
          localStorage.setItem(
            "issue_discounted_price",
            responseJson.data[0].order_issues[0].issue_discounted_price
          );
        }

        // setLoading(false);
        //  console.log(responseJson.data)
      })
      .catch((error) => {
        //Error
        console.error(error);
      });
  };

  useEffect(() => {
    fetch_enquery_details();

  }, []);



  useEffect(() => {
    getNextTimeSlot();
    update_predata();
  }, [selectedDate_neww]);


  const add_address = () => {
    let token = localStorage.getItem("token");

    let bearer = "Bearer " + token;

    fetch(HOST + "/api/v1/users/my_addresses", {
      method: "POST",
      body: JSON.stringify({
        addressType: 1,
        addressNo: 1709,
        addressOne: "1st Main Rd, Austin Town",
        addressTwo: "Neelasandra, Bengaluru",
        landmark: "near Bascom",
        pincode: 47,
      }), //post body
      headers: {
        Authorization: bearer,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success == false) {
          // setissuelist([]);
        } else {
          // alert(responseJson.data);

          setaddressid(responseJson.data);
          let enquiryId = localStorage.getItem("enquiry_id");
          const current = new Date();

          const date = `${current.getFullYear()}-${current.getMonth() + 1
            }-${current.getDate()} `;
          //  alert(date);

          fetch(HOST + "/api/v1/users/orders", {
            method: "POST",
            body: JSON.stringify({
              enquiryId: enquiryId,
              addressId: responseJson.data,
              dateOrder: Moment(selectedDate).format('DD/MM/YYYY'),
              timeslot: 1,
              paymentType: 1,
            }), //post body
            headers: {
              Authorization: bearer,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
              if (responseJson.success == false) {
                // setissuelist([]);
              } else {
                window.location.href = "/thanks_ln_enq_2";
              }

              // setLoading(false);
              //  console.log(responseJson.data)
            })
            .catch((error) => {
              //Error
              console.error(error);
            });
        }

        // setLoading(false);
        //  console.log(responseJson.data)
      })
      .catch((error) => {
        //Error
        console.error(error);
      });
  };
  const navigate = useNavigate();
  const create_order = () => {
    // alert(couponcoeddetails.couponid);
    console.log("Address",selectedAddress.address_id);
    if (couponcoeddetails.couponid == null) {
      var coupon_id = 0;


    } else {
      var coupon_id = couponcoeddetails.couponid;

    }
    setcoup_id(coupon_id);
    //  alert(coupon_id);
    // alert(coup_id)
    let enquiryId = localStorage.getItem("enquiry_id");
    // let addressid = localStorage.getItem("addressid");
    //  alert("working");
    const state = store.getState();

    // const timeslot = get(state, "confirmationDateTime.selectedTime");
    const paymentType = get(state, "payment.paymentType", 1);
    // const enquiryId = get(state, "auth.enquiry_id");
    // alert(timeslot);

    // const timeslot = localStorage.getItem("apointmnettimeid");
    // let apintdate_new = localStorage.getItem("apointmnetdate");

    // const apointmnetdatenew = Moment(apintdate_new).format('DD-MM-YYYY');
    //   const today = new Date();

    // const dateValue =
    //   today.getDate() < 9 ? "0" + today.getDate() : today.getDate();
    // const monthValue =
    //   today.getMonth() + 1 < 9
    //     ? "0"+ (today.getMonth() +1) 
    //     : today.getMonth() + 1;
    // const date = `${today.getFullYear()}-${monthValue}-${dateValue}`;

    let token = localStorage.getItem("token");

    let bearer = "Bearer " + token;
    // let coup_id = 0;


    fetch(HOST + "/api/v1/users/orders", {
      method: "POST",
      body: JSON.stringify({
        enquiryId: enquiryId,
        addressId: selectedAddress.address_id,
        // dateOrder: Moment(selectedDate).format('DD/MM/YYYY'),
        dateOrder: Moment(selectedDate).format('YYYY-MM-DD'),
        timeslot: selectedTime,
        paymentType: paymentType,
        coupon: coupon_id,
      }), //post body
      headers: {
        Authorization: bearer,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        clear_Cart();
        // console.log(responseJson);
        if (responseJson.success == false) {
          // setissuelist([]);
        } else {
          localStorage.removeItem("couponcode");
          localStorage.removeItem("couponid");
          localStorage.removeItem("amount");
          localStorage.removeItem("coupondesc");
          localStorage.removeItem("apointmnettimevalu");
          localStorage.removeItem("fulladdress");
          localStorage.removeItem("apointmnetdate");
          localStorage.removeItem("apointmnettimeid");

          // window.location.href = "/Thanku_fin";
        }

        // setLoading(false);
        //  console.log(responseJson.data)
      })
      .catch((error) => {
        //Error
        console.error(error);
      });



    // alert(date);

    // dispatch(
    //   updateConfirmationDialogFields({
    //     open: true,
    //   })
    // );

    // add_address();
    //  alert(enquiryId);
    // setTimeout(() => {
    //   alert(addressid);
    // }, 5000);
    navigate("/thanks")
  };
  const select_coupon = () => {
    // alert("working");
    setshowModal_coupon(true);
  }

  const clear_Cart = () => {
    let token = localStorage.getItem("token");

    let bearer = "Bearer " + token;
    fetch(
      HOST + "/api/v1/users/my_cart",
      {
        method: "PUT",

        headers: {
          Authorization: bearer,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((response) => response.json())
      .then((responseJson) => {

      })
      .catch((error) => {
        //Error
        console.error(error);
      });

  }

  const removecouponcode = () => {
    const nextFormState = {

      code: '',
      c_amount: 0,


      // mobile_error:'',
    };
    setapply_coupon(nextFormState);
  }
  const edit_Date_Time = () => {
    // alert("working");

    localStorage.setItem("from_landing", 1);
    dispatch(
      updateConfirmationDialogFields({
        open: true,
        activeStep: 0,
        step: 0,

      })
    );

    // add_address();
    //  alert(enquiryId);
    // setTimeout(() => {
    //   alert(addressid);
    // }, 5000);
  };
  const edit_address = () => {
    localStorage.setItem("from_landing", 1);
    dispatch(
      updateConfirmationDialogFields({
        open: true,
        activeStep: 0,
        step: 1,

      })
    );

  }
  const [notcheckedmsg, setnotcheckedmsg] = useState('');
  const show_error_msg = () => {
    setnotcheckedmsg('To continue, please accept the terms and conditions.');
    setTimeout(() => {
      setnotcheckedmsg('');
    }, 5000);
  }
  return (
    <div>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: "100%",
        padding: isMobile ? "0.5rem" : "2rem"
      }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem"
          }}
        >
          <Typography sx={{ fontSize: isMobile ? "22px" : "28px", fontWeight: "bold" }} >Pricing Details</Typography>
          <Box>
            {itemsInCart && itemsInCart[0] && <Typography sx={{ fontSize: isMobile ? "18px" : "24px", color: "#676767" }}>Model - {itemsInCart[0].model_segment_title}</Typography>}
          </Box>
          {itemsInCart.map((item, index) => (
            <Box key={index} sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: "1rem"
            }}>
              <Typography sx={{ fontSize: isMobile ? "18px" : "24px", fontWeight: isMobile ? "bold" : "" }} >
                {item.issue_title}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: isMobile ? "0.8rem" : "2rem" }} >
                <Typography sx={{ color: "#858585", textDecoration: "line-through", fontSize: isMobile ? "17px" : "22px" }} > ₹ {item.issue_display_price}</Typography>
                <Typography sx={{ fontSize: isMobile ? "17px" : "22px" }}>₹ {item.issue_discounted_price}</Typography>
              </Box>
            </Box>
          ))}
          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1.5rem",
          }}>
            <Typography sx={{ fontSize: isMobile ? "20px" : "22px" }}>Tax & Fees</Typography>
            <Typography sx={{ fontSize: isMobile ? "20px" : "22px" }}>₹{" "}{convenience_fee_inc_tax}</Typography>
          </Box>
          {(couponcoeddetails.couponcode == null) ? <></> : <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1.5rem",
          }}>
            <Typography sx={{ fontSize: "22px", color: "#048A81" }}>Discount({couponcoeddetails.couponcode.toUpperCase()})</Typography>
            <Typography sx={{ fontSize: "22px", color: "#048A81" }}> -₹{" "}{couponcoeddetails.couponamount}</Typography>
          </Box>}
          < Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }} >
            <Box>
              <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "18px" : "20px") : "24px", fontWeight: "bold" }}>Total {!isMobile && 'Payable'} Amount</Typography>
              {/* <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "14px" : "16px") : "18px", color: "#7B7B7B" }}>Amount is not inclusive of taxes</Typography> */}
            </Box>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "end"
            }}>
              <Typography sx={{ fontSize: isMobile ? "20px" : "24px", fontWeight: "bold", padding: isMobile ? "2px 5px" : "5px 8px" }} >
                ₹ {totalamt - couponcoeddetails.couponamount + (convenience_fee_inc_tax * 1)}
              </Typography>
              {/* <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "14px" : "16px") : "18px", color: "#7B7B7B", textAlign: "right" }}>
                Pay After Service
              </Typography> */}
            </Box>
          </Box>
          {/* <Box sx={{
            display: "flex",00
            justifyContent: "space-between",
            alignItems: "center"
          }} >
            <Box>
              <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "18px" : "20px") : "24px" }}>Total Amount</Typography>
              <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "14px" : "16px") : "20px" }}>(Pay after service)</Typography>
            </Box>

            <Box sx={{
              display: "flex",
              justifyContent: "center",
              gap: isMobile ? "1rem" : "2rem"
            }}>
              <Box sx={{
                background: "#CCFFF0",
                border: "1px solid #00996B",
                borderRadius: "5px",
                padding: isMobile ? (!isMobile && isMiniMobile ? "2px 6px" : "2px 12px") : "5px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#00996B",
                fontSize: isMiniMobile || isMobile ? "14px" : "20px"
              }}>
                SAVED ₹{" "}
                {total_display_price -
                  ((totalamt * 1) - (couponcoeddetails.couponamount * 1))}
              </Box>
              <Typography sx={{ fontSize: isMobile ? "20px" : "24px", fontWeight: "bold", padding: isMobile ? "2px 5px" : "5px 8px" }} >
                ₹{totalamt - couponcoeddetails.couponamount + (convenience_fee_inc_tax * 1)}
              </Typography>
            </Box>
          </Box> */}
          <br />
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.2rem"
          }}>
            <Box>
              <Typography sx={{ fontSize: isMobile ? "22px" : "28px", fontWeight: "bold" }} >Booking Details</Typography>
            </Box>

            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1.5rem"
            }}>
              <Box sx={{
                display: "flex",
                gap: "2rem"
              }}>
                <Box sx={{
                  display: "flex",
                  gap: "0.5rem"
                }} >
                  <img src={dateicon} alt="" style={{ width: "25px" }} />
                  <Typography fontSize={"18px"}>{apointmnetdate}</Typography>
                </Box>
                <Box sx={{
                  display: "flex",
                  gap: "0.5rem"
                }} >
                  <img src={timeicon} alt="" style={{ width: "25px" }} />
                  <Typography fontSize={"18px"}>{selectedTime_label}</Typography>
                </Box>
              </Box>
              <Box sx={{
                cursor: "pointer"
              }} onClick={edit_Date_Time}>
                <EditIcon />
              </Box>
            </Box>
            {(selectedAddress == null) ? <></> : <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1.5rem"
            }}>
              <Box sx={{
                display: "flex",
                gap: "0.5rem"
              }}>
                <img src={addressIcon} alt="" style={{ width: "25px" }} />
                <Typography fontSize={"18px"}>{selectedAddress.address_line_1} {!isMobile && selectedAddress.address_line_2} .... </Typography>
              </Box>
              <Box sx={{
                cursor: "pointer"
              }} onClick={edit_address}>
                <EditIcon />
              </Box>
            </Box>}
            <Box>
              <label style={{ fontSize: "18px" }}>
                <input type="checkbox" checked={checked} onChange={handleChange} style={{ marginRight: '10px', width: "20px", height: "20px", cursor: "pointer" }} />
                I agree to the <a href="/termsandconditions" target='blank'> terms and conditions</a>
              </label>
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "end",
            gap: isMobile ? "10px" : "44px",
            width: "100%",
          }}>
            {notcheckedmsg}
            <Box sx={{
            }}>
            <Typography sx={{ fontSize: isMobile ? (!isMobile && isMiniMobile ? "14px" : "16px") : "18px", color: "#7B7B7B", textAlign: "left" }}>
              Pay After Service
            </Typography>
            {checked ? <Button variant='contained' sx={{ padding: "10px 16px", fontSize: "20px", textTransform: "none", background: "#0E62CB", width: isMobile ? "100%" : "288px" }} onClick={() => update_enquery()}>Confirm Booking</Button> :
              <Button variant='contained' sx={{ padding: "10px 16px", fontSize: "20px", textTransform: "none", width: isMobile ? "100%" : "288px" }} onClick={() => show_error_msg()} style={{ background: 'gray' }} >Confirm Booking</Button>
            }
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default ConformationHero2