import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Box, Stack, Typography } from "@mui/material";
import { get, isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyCoupon } from "../../services/cart";
import { updateCartFields } from "../../store/slices/CartSlice";

const CartCouponComponent = () => {
  const dispatch = useDispatch();

  const coupon = useSelector(({ cart }) => get(cart, "appliedCoupon", {}));
  const totalAmount = useSelector(({ cart }) => get(cart, "totalAmount", 0));
  const discountedAmount = useSelector(({ cart }) =>
    get(cart, "discountedAmount", 0)
  );
  console.log(totalAmount);
  const removeCoupon = async () => {
    dispatch(
      updateCartFields({
        appliedCoupon: {},
        totalAmount: totalAmount + discountedAmount,
        discountedAmount: 0,
      })
    );
  };

  let couponBoxStyles = {
    border: "1px solid #D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 5px",
    height: "10vh",
  };

  const openCoupons = () => {
    dispatch(
      updateCartFields({
        openCouponsDialog: true,
      })
    );
  };
  return (
    <Box  marginBottom={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom:"0.5rem"
        }}
      >
        <Typography fontWeight={550}>Available Coupons</Typography>
        <Typography color="primary" onClick={openCoupons} sx={{ cursor:'pointer',padding:"0.5rem"}}>
          See All
        </Typography>
      </Box>
      {!isEmpty(coupon) && (
        <Box sx={couponBoxStyles} borderRadius={2}>
          <Stack>
            <Typography sx={{ color: "#EB870E",width:"max-content" }}>
              {get(coupon, "coupon_code")}{" "}
              <span style={{ color: "#000" }}>&nbsp;Applied</span>
            </Typography>
            <Typography
              sx={{ color: "#676767" }}
              align="left"
              variant="subtitle2"
            >
              {get(coupon, "coupon_discription")[0].toUpperCase()}{get(coupon, "coupon_discription").slice(1)}
            </Typography>
          </Stack>
          <HighlightOffRoundedIcon
            onClick={removeCoupon}
            color="primary"
            sx={{ cursor: "pointer" }}
          />
        </Box>
      )}
    </Box>
  );
};
export default CartCouponComponent;
