import { get } from "lodash";
import { getRequest } from "..";
import { getFilteredTimeIntervals } from "../../utils/DateUtils";
import { GET_ADDRESS_TYPES, GET_TIMESLOTS } from "../ApiConstants";

// import { useDispatch, useSelector } from "react-redux";

// import { getDay, getMonth, isTomorrow, nthDay } from "../../utils/DateUtils";

export const fetchTimeSlots = async () => {
  let newDate = new Date();
  let selected_date = new Date(localStorage.getItem("selecteddate"));
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let date_sel = selected_date.getDate();
  let month_sel = selected_date.getMonth() + 1;
  let year_sel = selected_date.getFullYear();
  let new_today = date + '/' + month + '/' + year;
  let sel_date = date_sel + '/' + month_sel + '/' + year_sel;
  try {
    const { data } = await getRequest(GET_TIMESLOTS);
    const timeslots = get(data, "data", []);
    if (!timeslots) return [];
    if (new_today == sel_date) {
      const filteredIntervals = getFilteredTimeIntervals(timeslots);
      return filteredIntervals;
    } else {

      const filteredIntervals = timeslots;
      return filteredIntervals;
    }
  } catch (e) { }
};

export const fetchAddressTypes = async () => {
  try {
    const { data } = await getRequest(GET_ADDRESS_TYPES);
    return get(data, "data", []);
  } catch (e) { }
};
