import { get } from "lodash";
import { getRequest, postRequest } from "..";
import store from "../../store";
import { displayAlert } from "../../utils";
import {
  GET_USER_PROFILE,
  RESEND_OTP,
  SEND_OTP,
  USER_LOGOUT,
  VERIFY_OTP,
  UPDATE_CART
} from "../ApiConstants";

export const fetchUserProfile = async () => {
  try {
    const { data } = await getRequest(GET_USER_PROFILE);

    return get(data, "data[0]", {});
  } catch (e) {
    console.log(e);
    return {};
  }
};
export const getCart =async () => {
  try {
    const { data } = await getRequest(UPDATE_CART);

    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return {};
  }
}; 

export const sendOtp = async (mobile = "") => {
  if (!mobile) return {};
  try {
    const { data } = await postRequest(SEND_OTP, { mobile });
    const success = get(data, "success", "");
    displayAlert(get(data, "message"), success);
    return success;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const sendOtp_new = async (mobile = "") => {
  if (!mobile) return {};
  try {
    const { data } = await postRequest(SEND_OTP, { mobile });
    const success = get(data, "success", "");
    displayAlert(get(data, "message"), success);
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
};
export const resendOtp = async () => {
  const state = store.getState();
  const mobile = get(state, "profile.mobileNumber");
  if (!mobile) return null;
  try {
    const { data } = await postRequest(RESEND_OTP, { mobile });
    const success = get(data, "success", "");
    displayAlert(get(data, "message"), success);
    return success;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const verifyOtp = async (mobile = "", otp = "") => {
  if (!mobile || !otp) return null;
  try {
    const payload = {
      mobile: mobile,
      mobile_otp: otp,
      fullname: "", //Arbaz
      purpose: 2,
    };
    const { data } = await postRequest(VERIFY_OTP, payload);
    const success = get(data, "success", "");
    if (success) {
      localStorage.setItem("token", get(data, "authorisation.token"));
      return data;
    }
    displayAlert(get(data, "message"), success);
    return null;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const logoutUser = async () => {
  try {
    const { data } = await getRequest(USER_LOGOUT);
    return get(data, "success");
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const updateUserProfile = async (payload = {}) => {
  try {
    const { data } = await postRequest(GET_USER_PROFILE, payload);
    return get(data, "success");
  } catch (e) {
    console.log(e);
    return null;
  }
};
