import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";

 import store from "./store";
// import store from "./molecules/landingpage/store"

const root = ReactDOM.createRoot(document.getElementById("root"));

let theme = createTheme({
  palette: {
    primary: {
      light: "#0E62CB",
      main: "#0E62CB",
      dark: "#0E62CB",
      contrastText: "#fff",
    },
    secondary: {
      light: "#F4F6FA",
      main: "#ff753a",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

theme = responsiveFontSizes(theme);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
