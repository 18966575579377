import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { get, noop } from "lodash";
import React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import payAfterService from "../../assets/payment/pay-after-service.svg";
import payOnline from "../../assets/payment/pay-online-icon.svg";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import { createOrder } from "../../services/orders";
import { updateConfirmationDialogFields } from "../../store/slices/ConfirmationDialog";
import { updatePaymentFields } from "../../store/slices/Payment";
import { handlePaymentSuccess } from "../../utils/PaymentUtils";
import useRazorpay from "react-razorpay";
import { updateOrderPaymnet } from "../../services/orders";


const PaymentButton = ({
  value = "",
  label = "",
  icon = "",
  onClickHandler = noop,
  selectedButton = "",
}) => {
  return (
    <Button
      fullWidth
      variant="outlined"
      sx={{
        border: selectedButton === value ? "2.5px solid #0E62CB" : "0px",
        borderRadius: "5px",
        background: "#F6F8FE",
        color: "#0E62CB",
        textTransform: "none",
        height: "100%",
        // margin: "5px 20px",
        "&.MuiButtonBase-root:hover": {
          border: selectedButton === value ? "2.5px solid #0E62CB" : "0px",
          borderRadius: "5px",
          background: "#F6F8FE",
          color: "#0E62CB",
          textTransform: "none",
        },
      }}
      onClick={() => onClickHandler(value)}
    >
      <img src={icon} alt={icon} />
      &nbsp;&nbsp;
      <Typography color="#0E62CB" fontWeight='bold'>{label}</Typography>
    </Button>
  );
};

const PaymentButtons = () => {
  const dispatch = useDispatch();
  const Razorpay = useRazorpay();

  const totalAmount = useSelector(({ cart }) => cart.totalAmount);
  

  const profile = useSelector(({ profile }) => profile.user);
  const full_name = get(profile, "user_fullname", "");
  const user_mobile = get(profile, "user_mobile", "");
 

  const addressId = useSelector(({ googleMaps }) =>
    get(googleMaps, "addressId", null)
  );
  const enquiryId = useSelector(({ orders }) => get(orders, "enquiryId", null));
  const convenience_fee_inc_tax  = useSelector(({ orders }) => get(orders, "convenience_fee_inc_tax", null));
  // alert(convenience_fee_inc_tax);
  const enquiry_id_encrypted = useSelector(({ orders }) => get(orders, "enquiry_id_encrypted", null));
  // alert(enquiry_id_encrypted);
  const [paymentButton, setPaymentButton] = React.useState({
    text: "Proceed",
    disabled: true,
  });
  const [selectedButton, setSelectedButton] = React.useState("");

  const { isDesktopOrLaptop,isMobile } = useScreenResolution();

  const updateButton = (value) => {
    setSelectedButton(value);
    if (value === "payOnline") {
      setPaymentButton({
        text: "Proceed",
        disabled: false,
      });
    } else if (value === "payLater") {
      setPaymentButton({
        text: "Place Request",
        disabled: false,
      });
    }
  };

  const handlePayment = async () => {
    if (!addressId) {
      alert("Address ID is not Created");
      return;
    }
    dispatch(
      updateConfirmationDialogFields({
        open: false,
      })
    );

    const orderResponse = await createOrder(
      addressId,
      enquiryId,
      selectedButton === "payOnline" ? 2 : 1
    );
    console.log(orderResponse, "Order Res");
    if (orderResponse) {
      dispatch(updateConfirmationDialogFields({ open: false }));
    }
    if (selectedButton === "payOnline") {
      // dispatch(
      //   updatePaymentFields({
      //     open: true,
      //   })
      // );
     
     
       
      handlePaymentrzp();


    } else if (selectedButton === "payLater") {
      handlePaymentSuccess();
    }
  };


  const handlePaymentrzp = useCallback(() => {
    // alert(process.env.WDS_SOCKET_HOST)
    // console.log(process.env);
    
    var newtotal = ((totalAmount * 1) + (convenience_fee_inc_tax * 1) )

    let options  = {
      // key: "rzp_test_edc0iutgef4r18",
      // key: "rzp_live_SinYtkfK8OVOuE",
      key:process.env.WDS_SOCKET_HOST,
      amount: (newtotal  * 100),
      currency: "INR",
      name: "ERIP",
      description: "Order_id="+enquiryId,
      image: "https://erip.in/assets/images/logo_erip.png",
      // order_id: 12121212,
      handler: (res) => {
      //   console.log(res);
      //   alert(res.razorpay_payment_id);
      // alert(res.razorpay_order_id);
      // alert(res.razorpay_signature);
      const update_order = updateOrderPaymnet(res.razorpay_payment_id,newtotal,enquiry_id_encrypted);
        //  update_ordersucess(res.razorpay_payment_id);
        if (!update_order) {
          alert("Issue in paymnet");
        }else{
          handlePaymentSuccess();
        }
      },
      prefill: {
        name: full_name,
        // email: "youremail@example.com",
        contact: user_mobile,
      },
      notes: {
        // address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#0E62CB",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  // const update_ordersucess = (paymnet_id) =>{
    

  // }

  return (
    <Stack spacing={2} direction="column" alignItems="center">
      <Grid
        container
        display='flex'
        justifyContent='space-between'
        direction= {isMobile?"column":"row"}
        alignItems="center"
        spacing={{ xs: 2, md: 1, lg: 2 }}
      >
        <Grid item lg={6} md={6} xs={12} sx={{width:{xs:"100%"}}}>
          <PaymentButton
            value="payOnline"
            label="Pay Now (Online)"
            icon={payOnline}
            selectedButton={selectedButton}
            onClickHandler={updateButton}
          />  
        </Grid>
        <Grid item lg={6} md={6} xs={12} sx={{width:{xs:"100%"}}}>
          <PaymentButton
            value="payLater"
            label="Pay After Services"
            icon={payAfterService}
            selectedButton={selectedButton}
            onClickHandler={updateButton}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        fullWidth
        disabled={paymentButton.disabled}
        onClick={handlePayment}
      >
        {paymentButton.text}
      </Button>
    </Stack>
  );
};

export default PaymentButtons;
