import React from 'react'
import '../css/Loader.css'
const Loader = () => {
    return (
        <div className="main">
        <div className="cs-loader">
            <div className="cs-loader-inner">
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
            </div>
        </div>
        </div>
    )
}

export default Loader