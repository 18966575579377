import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

const DEFAULT_CART_DATA = {
  openCartBottomBar: false,
  items: [],
  dbitems:[],
  totalAmount: 0,
  allCoupons: [],
  appliedCoupon: {},
  openCouponsDialog: false,
  openDeviceDialog: false,
  openCartsDialog: false,
  openDisplayAddress: false,
  displayBookNow: false,
  discountedAmount: 0,
  cart_model:'',
  cart_category_title:{},
  cart_brand_title:{},
  cart_model_segment_title:{},
  total_new_amount:0
};

export const CartSlice = createSlice({
  name: "cart",
  initialState: DEFAULT_CART_DATA,
  reducers: {
    updateCartFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),

   
    

    updateCartItems: (state, action) => {
      const { issue, add } = action.payload;
      if (add) {
        state.items.push(issue);
        state.totalAmount =
          state.totalAmount + parseFloat(get(issue, "discounted_price", 0));
      } else {
        state.totalAmount =
          state.totalAmount - parseFloat(get(issue, "discounted_price", 0));
        state.items = state.items.filter(
          (currentIssue) => currentIssue.issue_id !== issue.issue_id
        );
      }
      state.displayBookNow = state.items.length > 0;
      return state;
    },
    updateCartItems_new: (state,action) => {
      const { issue,add } = action.payload;

      // const result = issue.reduce((total, currentValue) => total = total + (currentValue.issue_price *1),0);
      const result = issue.reduce((total, currentValue) => total = total + (currentValue.issue_discounted_price *1),0);
      state.total_new_amount =result;
          // console.log('issuenwewe');
          // console.log(result);
      // if (add) {
      // state.total_new_amount =
      //     state.total_new_amount + parseFloat(get(issue, "issue_price", 0));
      //     console.log('issuenwewe');
      //     console.log(state.total_new_amount);
      //     console.log(issue);
      // }
      // state.displayBookNow = state.items.length > 0;
      return state;
    },
    reserCartData: (state) => (state = DEFAULT_CART_DATA),
    removeItem: (state, action) => {
      const issue = action.payload;
     
      state.totalAmount =
        state.totalAmount - parseFloat(get(issue, "discounted_price", 0));
      let items = state.items.filter(
        (currentIssue) => currentIssue.issue_id !== issue.issue_id
      );
      if (items.length === 0) {
        state.displayBookNow = false;
        state.appliedCoupon = {};
      }
      state.items = [...items];
      return state;
    },
  },
});

export const { updateCartFields, updateCartItems, removeItem, reserCartData,updateCartItems_new } =
  CartSlice.actions;

export default CartSlice.reducer;
