import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import ConfirmationDateComponent from "../../molecules/ConfirmationDateComponent";
import ConfirmationTimeComponent from "../../molecules/ConfirmationTimeComponent";

import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import { fetchTimeSlots } from "../../services/confirmation/DateTimeService";
import {
  updateConfirmationDialogFields,
  updateToNextStep,
} from "../../store/slices/ConfirmationDialog";
import { generateDates } from "../../utils/DateUtils";
import { fetchUserAddressList } from "../../services/confirmation";
import { get } from "lodash";

const DateAndTime = ({ confirmHandler = null }) => {
  const dispatch = useDispatch();

  const [timeslotsData, setTimeslotsData] = React.useState([]);
  const { isDesktopOrLaptop,isMobile } = useScreenResolution();
  const [dateValues] = React.useState(generateDates());
  const [dateSliceValue, setDateSliceValue] = React.useState({
    start: 0,
    end: 4
  });
  
  const selectedDate = useSelector(
    ({ confirmationDateTime }) => confirmationDateTime.selectedDate
  );
  const selectedTime = useSelector(
    ({ confirmationDateTime }) => confirmationDateTime.selectedTime
  );

  const handleConfirm = async () => {
    if (confirmHandler) {
      confirmHandler();
    }
    const addresses = await fetchUserAddressList();
    dispatch(
      updateConfirmationDialogFields({
        allUserAddress: addresses,
        selectedAddress: null,
      })
    );
    dispatch(updateToNextStep());
  };

  React.useEffect(() => {
    dispatch(
      updateConfirmationDialogFields({
        dialogTitle: "Checkout",
        stepperTitle: "Schedule Appointment",
        showStepper: true,
        activeStep: 0,
      })
    );

    const getTimeslots = async () => {
      setTimeslotsData((await fetchTimeSlots()) || []);
    };

    getTimeslots();
  }, [dispatch]);

  const handleClick_date = ()=>{
   
    getTimeslots_new();
  }

  const getTimeslots_new = async () => {
    setTimeslotsData((await fetchTimeSlots()) || []);
    // console.log(timeslotsData);
  };
 
  const generateDateButtons = () => {
    return dateValues.map((date, index) => (
      <Grid item lg={2.2} md={2.2} xs={4} key={index}  onClick={handleClick_date} >
        <ConfirmationDateComponent
          valueKey={selectedDate}
          value={date}
          index={index}
          
          />
      </Grid>
    ));
  };

  const generateTimeslots = () => {
    return timeslotsData.map((time, index) => (
      <Grid item lg={3} md={3} xs={4} key={index}>
      <ConfirmationTimeComponent
        label={time.title}
        value={time.id}
        valueKey={selectedTime}
        />
      </Grid>
    ));
  };

  let dates = generateDateButtons();
  const timeslots = generateTimeslots();

  const previousDates = () => {
    setDateSliceValue({ start: 0, end: 4 });
  };

  const nextDates = () => {
    setDateSliceValue({ start: 4, end: dateValues.length });
  };

  const desktopView = () => (
    <Box
      sx={{
        paddingLeft: { lg: "2.5vw" },
        paddingRight: { lg: "2.5vw" },
        paddingTop: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        
      }}
    >
      <Grid container spacing={1} direction="column" margin="0 auto">
        <Typography
          variant="inherit"
          sx={{ paddingBottom: 1, paddingTop: 2,marginBottom: 2,textAlign:"left"}}
        >
          Select Date 
        </Typography>
        <div className="date-container" style={{display:"flex",justifyContent:"left",alignItems:"center"}}>
          {/* <ArrowCircleLeftIcon
            color="primary"
            onClick={previousDates}
            style={{ cursor: "pointer" }}
            fontSize="large"
          /> */}
          <Grid container height spacing={2}  direction="row">
            {dates.slice(0,3)}
          </Grid>
          {/* <ArrowCircleRightIcon
            color="primary"
            onClick={nextDates}
            style={{ cursor: "pointer" }}
            fontSize="large"
          /> */}
        </div>
        <Typography
          align="left"
          variant="inherit"
          sx={{ paddingBottom: 1, paddingTop: 5,marginBottom: 2 }}
        >
          Select Time 
        </Typography>
        <Grid container height spacing={1}  direction="row" textAlign={"center"}>
          {timeslots}
        </Grid>
        <br />
      </Grid>
      <Button
        align="center"
        variant="contained"
        sx={{ width: "25em", margin: '1rem 0rem', }}
        onClick={handleConfirm}
        disabled={!selectedDate || !selectedTime}
      >
        Confirm & Proceed 
      </Button>
    </Box>
  );

  return desktopView();
  // isDesktopOrLaptop ? (
  // ) : (
  //   <MobileDateAndTime dateValues={dateValues} selectedDate={selectedDate} />
  // );
};
export default DateAndTime;
