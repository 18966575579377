import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_INITIAL_STATE = {
  selectedTime: null,
  selectedDate: null,
};
export const ConfirmationDateTimeSlice = createSlice({
  name: "googleMaps",
  initialState: DEFAULT_INITIAL_STATE,
  reducers: {
    updateDateTimeFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetDateTimeState: (state) => (state = DEFAULT_INITIAL_STATE)
  },
});

export const { updateDateTimeFields, resetDateTimeState } = ConfirmationDateTimeSlice.actions;

export default ConfirmationDateTimeSlice.reducer;
