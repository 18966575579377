import { Box, Button, Grid } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WalletComponent from "../../atoms/payments/WalletComponent";
import { PAYMENT_TYPE } from "../../constants";
import { makePayment } from "../../services/payment";
import { updatePaymentFields } from "../../store/slices/Payment";

// const WALLETS = [
//   { label: "Phone Pe", value: "helo", icon: iciciLogo },
//   { label: "Phone Pe", value: "shelo", icon: iciciLogo },
//   { label: "Phone Pe", value: "hdelo", icon: iciciLogo },
//   { label: "Phone Pe", value: "heflo", icon: iciciLogo },
//   { label: "Phone Pe", value: "helgao", icon: iciciLogo },
// ];

const WalletPayment = ({ razorpay = {}, razorpayRef = {} }) => {
  const wallets = get(razorpayRef, "current.methods.wallet", {});

  const selectedValue = useSelector(
    ({ payment }) => payment.selectedWalletType
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => dispatch(updatePaymentFields({ selectedWalletType: "" }));
  }, [dispatch]);

  const makeWalletPayment = () => {
    makePayment({
      razorpay: razorpay,
      type: PAYMENT_TYPE.WALLET,
      metaData: { wallet: selectedValue },
    });
  };

  const generateWallets = () => {
    if (wallets) {
      const walletKeys = Object.keys(wallets);
      return walletKeys.map((wallet, index) => (
        <Grid item xs={12} md={12} lg={6} key={index}>
          {console.log(wallet)}
          <WalletComponent
            selectedValue={selectedValue}
            value={wallet}
            label={wallet}
          />
        </Grid>
      ));
    }
    return <></>;
  };

  const walletComponents = generateWallets();
  return (
    <Box
      sx={{
        // width: { lg: "35vw", xs: "100%" },
        padding:{xs:2},
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        {walletComponents}
      </Grid>
      <Button fullWidth variant="contained" onClick={makeWalletPayment}>
        Place Request
      </Button>
    </Box>
  );
};

export default WalletPayment;
