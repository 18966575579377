import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  updateConfirmationDialogFields,
  updateToNextStep,
} from "../../store/slices/ConfirmationDialog";

import currentLocationIcon from "../../assets/current-location.svg";
import MapsAutocomplete from "../../molecules/new-google-maps/MapsAutoComplete";
import MapsContainer from "../../molecules/new-google-maps/MapsContainer";
import { updateGoogleMapsFields } from "../../store/slices/GoogleMaps";
import { fetchCurrentLocation } from "../../utils/GoogleMapsUtils";
import { useMediaQuery } from "react-responsive";
import { checkOrderPincode } from "../../services/pincodecheck";
import Skeleton from '@mui/material/Skeleton';

const ConfirmLocation = () => {
  const dispatch = useDispatch();
  const [Response,setResponse] = React.useState('');
  // console.log("googleMaps")
  // console.log(googleMaps);
  const disableButton = useSelector(
    ({ googleMaps }) => googleMaps.enableButton
  );
  const newpincdode = useSelector(
    ({ googleMaps }) => googleMaps.pincode
  );

  React.useEffect(() => {
    setResponse('');
    dispatch(
      updateConfirmationDialogFields({
        dialogTitle: "Confirm Location",
        showStepper: false,
      })
    );
  }, [dispatch]);

  const handleConfirmLocation = async() => {
//  dispatch(updateToNextStep());
    //  alert(newpincdode);
    const response = await checkOrderPincode(newpincdode, "pincode");
    if (response) {
      console.log("Servicealble area ",response);
        if(response==''){
          setResponse(`Sorry! We don't serve in your area yet.`);
        }else{
          // console.log("response")
          // console.log(response[0].loc_pincode_id)
          dispatch(updateToNextStep());
          dispatch(updateGoogleMapsFields({postal_code:response[0].loc_pincode_id }));
          // dispatch(updateGoogleMapsFields({postal_code:parseInt(response[0].loc_pincode_number.substr(1,4)) }));
          // alert('data');
        }
    //  console.log(response);
// alert(response);
        //  if(response.success){
        //   alert(response.message);
        //  }else{
        //    alert(response.message);
        //  }
    }else{
      alert('no service area');
    }
   
  };

  const [Load, setLoad] = useState(false);
  const getCurrentLocation = () => {
    setLoad(true);
    fetchCurrentLocation(dispatch, updateGoogleMapsFields);
    setLoad(false);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <Grid container spacing={2} margin='1.5rem 0' width="100%">
      <Grid item xs={12} lg={6} marginTop='0.7rem'>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            <MapsAutocomplete setResponse={setResponse} />
            <Typography
              align="left"
              variant="body2"
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "2vh",
                cursor: "pointer",
                justifyContent: "flex-start",
              }}
              fontWeight={500}
              onClick={getCurrentLocation}
            >
              <img src={currentLocationIcon} alt="Current Location" /> &nbsp;
              Use my current location 
            </Typography>
          </div>
          {isDesktopOrLaptop && (
            <>
            <Typography fontSize="small" style={{color:"red"}}>
                      {Response}
            </Typography>
            <Button
              disabled={!disableButton}
              variant="contained"
              fullWidth
              onClick={handleConfirmLocation}
              >
              Confirm and Proceed
            </Button>
              </>
          )}
        </Box>
      </Grid>
      {Load ? <>
       <Grid item xs={12} lg={6}>
       <Skeleton variant="rectangular" width={"100%"} height={"50vh"} />
      </Grid>
      </> 
      : <>
      <Grid item xs={12} lg={6}>
        <Box
          sx={{
            textAlign: "center",
          }}
          >
          <MapsContainer />
        </Box>
      </Grid>
          </>}
      {isTabletOrMobile && (
        <Grid item xs={12} lg={6}>
          <Typography fontSize="small" style={{color:"red"}}>
            {Response}
          </Typography>
          <Button
            disabled={!disableButton}
            variant="contained"
            fullWidth
            onClick={handleConfirmLocation}
          >
            Confirm and Proceed
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfirmLocation;
