import { createSlice } from "@reduxjs/toolkit";
const DEFAULT_PROFILE_DATA = {
  loggedIn: localStorage.getItem("token") !== null,
  openLogin: false,
  token: localStorage.getItem("token") || "",
  user: {},
  error: false,
  emailDialog: false,
  mobileNumberDialog: false,
  termsConditionCheck: false,
  redirectToHomePage: false,
  user_has_name:false,
};
export const ProfileSlice = createSlice({
  name: "profile",
  initialState: DEFAULT_PROFILE_DATA,
  reducers: {
    updateProfileFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetProfileFields: (state) => {
      localStorage.removeItem("token");
      state = {
        ...DEFAULT_PROFILE_DATA,
        token: null,
        loggedIn: false,
        fromLogout: true,
      };
      return state;
    },
  },
});

export const { updateProfileFields, resetProfileFields } = ProfileSlice.actions;

export default ProfileSlice.reducer;
