import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { clone, get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import thankyouImg from "../../assets/home/order-placed-thank-you.svg";
import { reserCartData } from "../../store/slices/CartSlice";
import { updateOrderFields } from "../../store/slices/OrderSlice";
import { updateRatingFields } from "../../store/slices/Rating";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
const ThankYouComponent = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {isMobile} = useScreenResolution();
  const navigate = useNavigate();

  const open = useSelector(({ rating }) =>
    get(rating, "openOrderPlacedThankyou", false)
  );

  const orderIdEncrypted = useSelector(({ orders }) =>
    get(orders, "enquiry_id_encrypted", "")
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, [3000]);
    }
    return () => {
      dispatch(
        updateOrderFields({
          enquiryId: null,
          enquiry_id_encrypted: null,
        })
      );
    };
  }, [open]);

  const handleClose = () => {
    dispatch(
      updateRatingFields({
        openOrderPlacedThankyou: false,
      })
    );
    let orderId = clone(orderIdEncrypted);
    dispatch(reserCartData());
    if (orderId) navigate(`/orders/${orderId}`, { replace: true });
    else navigate("/orders");
  };

  const openOrder = () => {
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="md"
      sx={{
        marginTop: { xs: "50vh", md: "0%", lg: "0%" },
      }}
      PaperProps={{
        sx:{
          borderTopLeftRadius:isMobile?'12px':'',
          borderTopRightRadius:isMobile?'12px':'',
          borderRadius:{lg:"8px"},
        }
      }}
      scroll="paper"
    >
      <DialogContent>
        <Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Box>
              <img src={thankyouImg} alt="" />
            </Box>
            <Stack spacing={2} alignItems="center">
              <Typography align="center" variant="h4" color="primary">
                Thank you!
              </Typography>
              <Typography align="center" variant="h6">
                Your Booking has been placed Successfully
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ margin: "2vh 15%" }}>
            <Button variant="contained" fullWidth onClick={openOrder}>
              View Order Status 
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default ThankYouComponent;
