import { createSlice } from "@reduxjs/toolkit";

export const OrderSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    openOrderCancelDialog: false,
    openOrderResceduleDialog: false,
    openJobCardDetails: false,
    openReceiptDetails: false,
    data_count_old:''
  },
  reducers: {
    updateOrderFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateOrderFields } = OrderSlice.actions;

export default OrderSlice.reducer;
