import React, { useState } from 'react'
import { Box, Typography, Slide } from '@mui/material'
import mobile from '../assets/mobilebg.png'
import laptop from '../assets/laptop.png'
import shadow from '../assets/shadow.png'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'
import '../css/DeviceSection.css'
import bg from '../assets/bg.png'

const DeviceDesktopView = ({ Toggle, setToggle }) => {
    const { isMobile } = useScreenResolution();
    return (
        <Box sx={{
            display: "grid",
            gridTemplateColumns: "30% 40% 30%",
            gap: "1rem",
            placeItems: "center",
            minHeight:"595px",
        }}>
            {Toggle && <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                justifyContent:"space-between",
                height:"100%"
            }}>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "right" }}>Screen</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "right" }}>Cracked screen? Book a professional visit & get it fixed at your place.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "right" }}>Battery </Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "right" }}>Perfect battery replacement solutions for longer smartphone life.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "right" }}>Audio</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "right" }}>Speaker & mic solutions to restore clear and crisp sound</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "right" }}>Software Issues</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "right" }}>All software issues & glitches covered for smooth experience</Typography>
                </Box>

            </Box>}
            {!Toggle && <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                justifyContent:"space-between",
                height:"100%"
            }}>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "right" }}>Screen</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "right" }}>Fixing screens for a captivating visual experience with genuine & original parts.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "right" }}>Keyboard  </Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "right" }}>Effortlessly type all day long with our expert keyboard repairs. Our experts do a thorough check up & treat every issue accordingly.</Typography>
                </Box>
            </Box>}
            <Box sx={{
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
            }}>
                    <img src={mobile} alt="" className={Toggle ? "imgAnimate animate mobile" : "imgAnimate"}/>
                    <img src={laptop} alt="" className={!Toggle ? "imgAnimate animate laptop" : "imgAnimate"} />
                    <img src={shadow}/>
            </Box>
            {Toggle && <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent:"space-between",
                gap: "32px",
                height:"100%"
            }}>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Display</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Get your crystal-clear display back. Just as if you bought a new one.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Back Glass</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>We replace broken back glass like it's never broken</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Charging Port</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Deep clean & inspection for dirt & debris collected in charging port</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Camera </Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Front & rear camera repairs for capturing perfect moments again</Typography>
                </Box>
            </Box>}
            {!Toggle && <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent:"space-between",
                gap: "32px",
                height:"100%",
                paddingBottom:"22px"
            }}>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Battery</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Long-lasting battery solutions for hours of screen time without you having to look for your charger and socket point.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>MacOS/ Windows Installation
</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Seamless Mac OS/Windows installations for a smooth and optimised experience</Typography>
                </Box>
            </Box>}
        </Box>
    )
}

const DevicePhoneView = ({ Toggle, setToggle }) => {
    const { isMobile } = useScreenResolution();
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                height: "250px",
                width: "250px",
                overflow: "hidden",
                borderRadius: "50%",
                background: "white",
            }}>
                <img src={Toggle ? mobile : laptop} alt="" style={{ marginTop: "40px", maxWidth: "220px" }} />

            </Box>
            <br />
            {Toggle && <Box className="boo" sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "2rem",
            }}>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Screen</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Cracked screen? Book a professional/expert visit & get it fixed at your place.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Display</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Get your crystal-clear display back. Just as if you bought a new one.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Battery </Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Perfect battery replacement solutions for longer smartphone life.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Back Glass</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>We replace broken back glass like it's never broken
</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Audio</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Speaker & mic solutions to restore clear and crisp sound</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Charging Port</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Deep clean & inspection for dirt & debris collected in charging port</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Software Issues</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>All software issues & glitches covered for smooth experience</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Camera </Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Front & rear camera repairs for capturing perfect moments again</Typography>
                </Box>
            </Box>}
            {!Toggle && <Box className="coo" sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "2rem",
            }}>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Screen</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Fixing screens for a captivating visual experience with genuine & original parts.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Battery </Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Long-lasting battery solutions for hours of screen time without you having to look for your charger and socket point.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>Keyboard </Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Effortlessly type all day long with our expert keyboard repairs. Our experts do a thorough check up & treat every issue accordingly.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: "22px", textAlign: "left" }}>MacOS/ Windows Installation</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(103, 103, 103, 1)", textAlign: "left" }}>Seamless Mac OS/Windows installations for a smooth and optimised experience
</Typography>
                </Box>
            </Box>}
        </Box>
    )
}

const DeviceSection = () => {
    const url = window.location.pathname;
    const { isMobile } = useScreenResolution();
    const [Toggle, setToggle] = useState(url === "/devicelanding"?false:true);
    const ToggleAction = (val) => {
        setToggle(val);
    }
    return (
        <Box sx={{
            padding: isMobile ? "0.5rem" : "2rem"
        }}>
            <Box>
                <Typography sx={{ fontSize: isMobile?"30px":"40px", fontWeight: "bold", textAlign: "center" }}>Fixes for Every Problem</Typography>
                {Toggle && <Typography sx={{ fontSize: isMobile?"18px":"20px", color: "rgba(103, 103, 103, 1)", textAlign: "center" }}>Why roam everywhere when you can get all your phone issues resolved <span style={{fontWeight:"bold", color:"black"}}> anytime</span>, <span style={{fontWeight:"bold", color:"black"}}>anywhere</span>.</Typography>}
                {!Toggle && <Typography sx={{ fontSize: "20px", color: "rgba(103, 103, 103, 1)", textAlign: "center" }}>Laptop Problems? We've Got You!</Typography>}
                <br />
                {url === "/devicelanding"?<Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px"
                }}>
                    <a onClick={() => ToggleAction(false)}><Typography sx={{ fontSize: "24px", color: !Toggle ? "rgba(14, 98, 203, 1)" : "rgba(103, 103, 103, 1)", cursor: "pointer" }} >Laptop</Typography></a>
                    <div style={{ height: "24px", width: '0', border: "0.5px solid rgba(140, 140, 140, 1)" }} />
                    <a onClick={() => ToggleAction(true)}><Typography sx={{ fontSize: "24px", color: Toggle ? "rgba(14, 98, 203, 1)" : "rgba(103, 103, 103, 1)", cursor: "pointer" }}>Phone</Typography></a>
                </Box>:
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px"
                }}>
                    <a onClick={() => ToggleAction(true)}><Typography sx={{ fontSize: "24px", color: Toggle ? "rgba(14, 98, 203, 1)" : "rgba(103, 103, 103, 1)", cursor: "pointer" }}>Phone</Typography></a>
                    <div style={{ height: "24px", width: '0', border: "0.5px solid rgba(140, 140, 140, 1)" }} />
                    <a onClick={() => ToggleAction(false)}><Typography sx={{ fontSize: "24px", color: !Toggle ? "rgba(14, 98, 203, 1)" : "rgba(103, 103, 103, 1)", cursor: "pointer" }} >Laptop</Typography></a>
                </Box>}
            </Box>
            <br />
            <Box sx={{
                backgroundColor: "rgba(245, 249, 255, 1)",
                padding: isMobile ? "20px" : "60px",
                borderRadius: "20px",
                minHeight:"595px"
            }} className={isMobile?"":"mainBox"}>
                {isMobile ? <DevicePhoneView Toggle={Toggle} setToggle={setToggle} /> : <DeviceDesktopView Toggle={Toggle} setToggle={setToggle} />}
            </Box>
            <br />
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.2rem",
                padding: "10px 60px"
            }}>
                <a onclick={() => setToggle(true)} style={{ marginLeft: "30px" }}><div className={Toggle ? "step active" : "step"} /></a>
                <a onclick={() => setToggle(false)}><div className={!Toggle ? "step active" : "step"} /></a>
            </Box>
        </Box>
    )
}

export default DeviceSection