import { get } from "lodash";
import { getRequest, postRequestWithHeaders } from "..";
import { UPDATE_ADDRESS } from "../ApiConstants";

import store from "../../store";

export const updateOrderAddress = async (payload = {}) => {
  try {
    const state = store.getState();
    const { position, textFieldValue } = state.googleMaps;

    const { data } = await postRequestWithHeaders(UPDATE_ADDRESS, {
      ...payload,
      longitude: position["lng"],
      latitude: position["lat"],
      addressFull: textFieldValue,
    });
    // console.log('data');
    // console.log("addressData: ",data);
    return data;
    // console.log(get(data, "success", 0));
    // if(get(data, "success")==false){
    //   return get(data, "message", []);
    // }else{
    //   return get(data, "data", 0);
    // }
   
  } catch (e) {
    console.log(e);
    return null;
  }
};
export const fetchUserAddressList = async () => {
  try {
    const { data } = await getRequest(UPDATE_ADDRESS);
    const list = get(data, "data", []);
    return list.slice(0, 4);
  } catch (e) {
    console.log(e);
    return null;
  }
};
