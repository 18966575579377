import { get } from "lodash";
import {
  getRequest,
  getRequestWithParams,
  postRequest,
  postRequestWithHeaders,
} from "..";
import {
  CREATE_ENQUIRY,
  GET_CANCEL_REASONS_USER,
  GET_MY_ORDERS,
  RESCHEDULE_ORDER,
  SUBMIT_ORDER_CANCEL_REASON,
  UPDATE_QUOTATION,
  GET_MY_ORDERS_RECEPT,
  ORDER_ONLINE_PAYMNET
} from "../ApiConstants";

import store from "../../store";
import { getOrderDate } from "../../utils/DateUtils";

export const updateOrderPaymnet = async (pay_id,amount,order_id) =>{
  try {
    // const state = store.getState();
    const payload = new FormData();
    payload.append("orderId",order_id);
    payload.append("orderAmount",amount);
    payload.append("provider","Razorpay");
    payload.append("providerOrderId",0);
    payload.append("providerPaymentId",pay_id);
    payload.append("providerSign",0);
    
    
    const { data } = await postRequest(ORDER_ONLINE_PAYMNET, payload);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }

}

export const fetchMyOrders = async () => {
  try {
    // const { data } = await getRequest(GET_MY_ORDERS);
    const { data } = await getRequest(GET_MY_ORDERS);

     return get(data, "data", []);
    
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const fetchMyoldorder = async () => {
  try {
    // const { data } = await getRequest(GET_MY_ORDERS);
    const { data } = await getRequest(GET_MY_ORDERS);

     return get(data, "data_count_old", 0);
    
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const fetchOrderDetails = async (orderId = "") => {
  try {
    const params = new URLSearchParams();
    params.append("order", orderId);
    const { data } = await getRequestWithParams(GET_MY_ORDERS, params);

    return get(data, "data[0]", {});
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const fetchOrderReceptDetails = async (orderId = "") => {
  try {
    const params = new URLSearchParams();
    params.append("order", orderId);
    const { data } = await getRequestWithParams(GET_MY_ORDERS_RECEPT, params);

    return get(data, "data[0]", {});
  } catch (e) {
    console.log(e);
    return {};
  }
}

export const updateOrderQuotation = (status = 0, orderId = "") => {
  const formData = new FormData();
  formData.append("order", orderId);
  formData.append("quotationStatus", status);
  try {
    const { data } = postRequest(UPDATE_QUOTATION, formData);
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const createOrder = async (
  addressId = "",
  enquiryId = "",
  paymentType = 1
) => {
  const state = store.getState();
  // const enquiryId = get(state, "auth.enquiry_id");
  const timeslot = get(state, "confirmationDateTime.selectedTime");
  const dateValue = new Date(get(state, "confirmationDateTime.selectedDate"));
  const date = getOrderDate(new Date(dateValue));
  const coupon = get(state, "cart.appliedCoupon.coupon_id", 0);

  try {
    const payload = {
      enquiryId: enquiryId,
      addressId,
      dateOrder: date,
      timeslot,
      paymentType,
      coupon,
    };

    const { data } = await postRequestWithHeaders(GET_MY_ORDERS, payload);
    return get(data, "success");
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchCancelReasons = async () => {
  try {
    const { data } = await getRequest(GET_CANCEL_REASONS_USER);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const submitCancelReason = async ({
  cancelOrderReason = "",
  cancelOrderDescription = "",
}) => {
  try {
    const state = store.getState();
    const order = get(state, "orders.orderDetails.order_id_encrypted", "");

    if (!order) return null;
    const payload = new FormData();
    payload.append("order", order);
    payload.append("cancelOrderReason", cancelOrderReason);
    payload.append("cancelOrderDescription", cancelOrderDescription);

    const { data } = await postRequestWithHeaders(
      SUBMIT_ORDER_CANCEL_REASON,
      payload
    );
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const createEnquiry = async (props) => {
  try {
    const state = store.getState();
    const {utmSource,utmMedium,utmCampaign} = props;

    const payload = new FormData();
    let group_id =  get(state, "category.selectedCategory.group_id", 1);
    payload.append("utmSource",utmSource);
    payload.append("utmMedium",utmMedium);
    payload.append("utmCampaign",utmCampaign);
    payload.append(
      "flowGroup",
      get(state, "category.selectedCategory.group_id", 1)
    );
    payload.append("sourceType", 1);
    payload.append("subSourceType", 1);
    payload.append("city", get(state, "city", 1));

    payload.append(
      "category",
      get(state, "category.selectedCategory.category_id", 1)
    );
    payload.append(
      "brand",
      get(state, "category.selectedGadgetBrand.brand_id", 1)
    );
    if(group_id==1){
      payload.append(
        "model_segment",
        get(state, "category.selectedModel.model_id", 1)
      );
    }else{
      payload.append(
        "model_segment",
        get(state, "category.selectedModel.segment_id", 1)
      );
    }
    
    
    const items = get(state, "cart.items", []);
    items.forEach((item) =>
      payload.append("issues[]", get(item, "issue_id", 1))
    );
    payload.append(
      "coupon",
      get(state, "category.cart.appliedCoupon.coupon_id", 1)
    );

    const { data } = await postRequest(CREATE_ENQUIRY, payload);
    return get(data, "enquiry_data", null);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const rescheduleOrder = async () => {
  try {
    const state = store.getState();
    const payload = new FormData();
    payload.append(
      "order",
      get(state, "orders.orderDetails.order_id_encrypted", null)
    );
    payload.append(
      "timeslot",
      get(state, "confirmationDateTime.selectedTime", 0)
    );
    const dateValue = new Date(get(state, "confirmationDateTime.selectedDate"));
    const date = getOrderDate(new Date(dateValue));
    payload.append("dateOrder", date);
    const { data } = await postRequest(RESCHEDULE_ORDER, payload);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};
