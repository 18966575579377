import React,{useState,useEffect} from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Button, Typography, Box} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import { useScreenResolution } from '../../../hooks/ResponsiveHook';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Issue_Form = ({devicedetails ,modeldetails,setissuedetails,setStep,Step}) => {
    const HOST =process.env.REACT_APP_API_URL;
    const [IssueList,setIssueList] = useState([]);
    const [Open,setOpen] = useState(true);
    const [IssueSelect,setIssueSelect] = useState('');
    const {isMobile} = useScreenResolution();
    
    const fetchIssueList = async () => {
        fetch(HOST+'/api/v1/issues_by_models?model='+modeldetails.model_id+'',{
          method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((resp)=>resp.json())
        .then((respJson)=>{
          if(respJson.success === false) setIssueList([]);
          else setIssueList(respJson.data);
        }).catch((error)=>{
          console.log(error);
        });
      }

      const setIssue = (IssueName,IssueId) => {
        // setIssueSelect(IssueName);
        const IssueDetails = {
          issue_name:IssueName,
          issue_id: IssueId
        };
        setDigOpen(false);
        setOpen(false);
        setissuedetails(IssueDetails);
      }
      const handelChange = (event) => {
        setIssueSelect( event.target.value);
        // console.log(IssueSelect);
      }
      const [Digopen, setDigOpen] = React.useState(true);

      const handleClickOpen = () => {
        setDigOpen(true);
      };
    
      const handleClose = () => {
        setDigOpen(false);
      };
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
      useEffect(()=>{
        fetchIssueList();
      },[])
      const emptyIssue = {
        issue_name:'',
        issue_id:'',
      }
  return (
    <>
      {!isMobile && <Typography > {devicedetails.device_name} {" -> "} {modeldetails.model_name}{" -> "}{IssueSelect}</Typography>}
    { !isMobile && <FormControl fullWidth  >
        <InputLabel id="demo-simple-select-label" >Select Issue</InputLabel>
        <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="issue"
        value={IssueSelect}
        onChange={handelChange}
        open = {Open}
        onFocus={()=>setOpen(true)}
            MenuProps={{
              PaperProps:{
                style:{
                maxHeight:300
              }
            }
          }}
          >
          <MenuItem onClick={()=>{setStep(Step-1);setissuedetails(emptyIssue)}} ><ArrowBackIcon/></MenuItem>
        { IssueList && IssueList.map((item,index)=>(
          <MenuItem key={index} value={item.issue_title} onClick={()=>{setIssue(item.issue_title,item.issue_id)}} >{item.issue_title}</MenuItem>
          ))}
        </Select>
    </FormControl>}
    {isMobile && <Button variant='outlined' sx={{ border: "1px solid #ababab", padding: "15px 14px", color: "#5d5d5d", textTransform: "capitalize", display: "flex", justifyContent: "space-between", fontSize: "16px", display: Step === 3 ? "" : "none" }} endIcon={<KeyboardArrowDownIcon />} onClick={handleClickOpen} >{IssueSelect?`${modeldetails.model_name} - ${IssueSelect}`:"Select Issue"}</Button>}

    { isMobile &&<Dialog
      open={Digopen}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={handleClose}
      sx={{
        marginTop: "40%",
        Padding: "0"
      }}
      
      PaperProps={{
        sx: {
          borderRadius: { lg: "8px" },
          borderTopLeftRadius: isMobile ? "1.5rem" : "",
          borderTopRightRadius: isMobile ? "1.5rem" : ""
        }
      }}
      aria-describedby="alert-dialog-slide-description"
      >
      <DialogTitle  >
        <Box sx={{display:"flex", alignItems:"center", gap:"1rem"}} onClick={()=>{setStep(Step-1);setissuedetails(emptyIssue)}} >
          <ArrowBackIcon/>
        <Typography align='left' style={{ fontSize: "1.6rem",width:"100%",fontWeight:"bold" }}> Select Issue</Typography>
          </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "0.8rem" }}>
            {IssueList.map((item, index) => (
              <Box key={index} sx={{ cursor: "pointer", borderBottom: "3px solid #F5F6Fa", paddingY: 0.8 }} onClick={() => {setIssue(item.issue_title,item.issue_id);setIssueSelect(item.issue_title)}} >
                <Typography style={{ fontSize: "1.2rem" }} >{item.issue_title}</Typography>
              </Box>
            ))}
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>}
    </>
  )
}

export default Issue_Form