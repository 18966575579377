import { get } from "lodash";
import { UPDATE_ORDER_QUOTATION } from "./ApiConstants";
import axios from "./config";

export const getRequest = async (url = "") => {
  return await axios.get(url);
};

export const getRequestWithParams = async (
  url = "",
  params = null,
  headers = null
) => {
  return await axios.get(url, { params, headers });
};

export const getRequestWithheaders = async (url = "", headers = null) => {
  return await axios.get(url, { headers });
};

export const postRequest = async (url = "", payload = null) => {
  return await axios.post(url, payload);
};
export const putRequest = async (url = "", payload = null) => {
  return await axios.put(url, payload);
};

export const postRequestWithAuth = async (
  url = "",
  payload = null,
  auth = {}
) => {
  return await axios.post(url, payload, { auth });
};

export const postRequestWithHeaders = async (
  url = "",
  payload = null,
  headers = {}
) => {
  return await axios.post(url, payload, { headers });
};

export const updateQuotationStatus = async ({
  orderId = "",
  quotationStatus = 0,
}) => {
  const payload = new FormData();
  payload.append("order", orderId);
  payload.append("quotationStatus", quotationStatus);
  try {
    const { data } = await postRequest(UPDATE_ORDER_QUOTATION, payload);
    return get(data, "message", "");
  } catch (e) {
    console.log(e);
    return {};
  }
};
