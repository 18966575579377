import { createSlice } from "@reduxjs/toolkit";
import { get, set } from "lodash";

const DEFAULT_INITIAL_STATE = {
  dialogTitle: "Schedule Appointment",
  step: 0,
  showStepper: true,
  address: {},
  slots: {},
  activeStep: 0,
  open: false,
};

export const ConfirmationDialog = createSlice({
  name: "confirmationDialog",
  initialState: DEFAULT_INITIAL_STATE,
  reducers: {
    updateConfirmationDialogFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    updateToNextStep: (state, action) => {
      if (state.step < 5)
        set(state, "step", get(state, "step", 0) + get(action, "payload", 1));
    },
    updateToPreviousStep: (state, action) => {
      const currentStep = get(state, "step", 0);
      if (currentStep - get(action, "payload", 1) >= 0)
        if (state.step > 0)
          set(state, "step", get(state, "step", 0) - get(action, "payload", 1));
    },
    resetScheduleAppointmentState: (state) => (state = DEFAULT_INITIAL_STATE),
  },
});

export const {
  updateConfirmationDialogFields,
  updateToNextStep,
  updateToPreviousStep,
  resetScheduleAppointmentState,
} = ConfirmationDialog.actions;

export default ConfirmationDialog.reducer;
