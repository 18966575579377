import { InputLabel, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentFields } from "../../../store/slices/Payment";

const CardHolderName = () => {
  const cardHolderName = useSelector(({ payment }) => payment.cardHolderName);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(updatePaymentFields({ cardHolderName: e.target.value }));
  };
  return (
    <React.Fragment>
      <InputLabel htmlFor="my-input">Card Holder's Name</InputLabel>
      <TextField
        fullWidth
        placeholder="Name"
        value={cardHolderName}
        onChange={handleChange}
        variant='filled'
        inputProps={{
          style:{
            padding: '0.8rem'
          }
        }}
      />
    </React.Fragment>
  );
};

export default CardHolderName;
