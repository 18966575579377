import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCartFields } from "../../store/slices/CartSlice";
import AllCouponComponent from "./AllCouponComponent";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
const AllCouponsDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {isMobile} = useScreenResolution();
  const openCouponsDialog = useSelector(({ cart }) => cart.openCouponsDialog);
  const allCoupons = useSelector(({ cart }) => cart.allCoupons);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      updateCartFields({
        openCouponsDialog: false,
      })
    );
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      open={openCouponsDialog}
      onClose={handleClose}
      sx={{
        marginTop: isMobile?"50%":"0",
        Padding: "0"
      }}
      PaperProps={{
        sx:{
          borderRadius:{lg:"8px"},
          borderTopLeftRadius: isMobile?"1.5rem":"",
          borderTopRightRadius: isMobile?"1.5rem":""
        }
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Available Coupons</Typography>
          <HighlightOffRoundedIcon
            onClick={handleClose}
            sx={{ cursor: "pointer" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack width={isMobile?"100%":"40vw"}>
          {allCoupons &&
            allCoupons.map((coupon, index) => (
              <AllCouponComponent coupon={coupon} key={index} />
            ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AllCouponsDialog;
