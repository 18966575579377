import { createSlice } from "@reduxjs/toolkit";

export const GadgetsSlice = createSlice({
  name: "gadgets",
  initialState: {
    category: {},
    brand: {},
    model: {},
  },
  reducers: {
    updateGadgetsFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateGadgetsFields } = GadgetsSlice.actions;

export default GadgetsSlice.reducer;
