import { Box, Button, Grid,Typography } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import NetbankingAutocompleteField from "../../atoms/payments/NetbankingAutocompleteField";
import { PAYMENT_TYPE } from "../../constants";
import { makePayment } from "../../services/payment";
import iciciLogo from '../../assets/payment/icici-logo.svg'
import axis from '../../assets/payment/axis-logo.png'
import kotak from '../../assets/payment/kotak-logo.png'
import hdfc from '../../assets/payment/hdfc-logo.png'
import { useScreenResolution } from "../../hooks/ResponsiveHook";
const NETBANKING = [
  { label: "HDFC", value: "helo", icon: hdfc },
  { label: "ICICI", value: "shelo", icon: iciciLogo },
  { label: "Axis", value: "hdelo", icon: axis },
  { label: "Kotak", value: "heflo", icon: kotak },
];

const NetbankingPayment = ({ razorpayRef = {}, razorpay = {} }) => {
  const {isMiniMobile,isDesktopOrLaptop} = useScreenResolution();
  const selectedNetbankingValue = useSelector(({ payment }) =>
    get(payment, "selectedNetbankingValue.value", "")
  );
  const netbankingOptions = get(
    razorpayRef,
    "current.methods.netbanking",
    null
  );

  const makeNetbankingPayment = () => {
    if (selectedNetbankingValue) {
      makePayment({
        razorpay: razorpay,
        type: PAYMENT_TYPE.NETBANKING,
        metaData: { bank: selectedNetbankingValue },
      });
    }
  };
  const NetbankingComponentDesktop = ({
    lable = "",
    value = "",
    icon = <></>
  })=>(
    <Box height="6vh" width={'200px'} style={{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      border:'1px solid #0E62CB',
      cursor:'pointer',
      padding: isMiniMobile?'1.4rem':'2rem',
      borderRadius: "0.3rem"
       }}>
      <Typography display='flex' alignItems='center' flexDirection ={isMiniMobile?'column':'row'}  xs={6} fontSize= {isMiniMobile?'0.8rem':'1rem'}>
        {icon} &nbsp;
        {lable}
      </Typography>
    </Box>
  );
  const NetbankingComponent = ({
    lable = "",
    value = "",
    icon = <></>
  })=>(
    <Box height="6vh" width={isMiniMobile?'120px':'150px'} style={{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      border:'1px solid #0E62CB',
      cursor:'pointer',
      padding: isMiniMobile?'1.4rem':'2rem',
      borderRadius: "0.3rem"
       }}>
      <Typography display='flex' alignItems='center' flexDirection ={isMiniMobile?'column':'row'}  xs={6} fontSize= {isMiniMobile?'0.8rem':'1rem'}>
        {icon} &nbsp;
        {lable}
      </Typography>
    </Box>
  );
  const generateNetbankingOptions = () => {
    const netbankingKeys = netbankingOptions
      ? Object.keys(netbankingOptions)
      : [];
    return netbankingKeys.map((bank) => ({
      label: netbankingOptions[bank],
      value: bank,
    }));
  };

  const netbankingMenus = generateNetbankingOptions();
  return (
    <Box
      sx={{
        height: "80%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={1} marginTop={"2rem"} width="100%" style={{
        display: 'flex',
        gap:'1rem',
        justifyContent:'center',
        }}>
      {NETBANKING.map((netbanking, index) => (
          <Grid key={index} item 
          style={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
          }}>
            {isDesktopOrLaptop? 
            <NetbankingComponentDesktop lable={netbanking.label}
            value={netbanking.value}
            icon={<img src={netbanking.icon} style={{height:"5vh"}}></img>} >
             </NetbankingComponentDesktop>
             :
            <NetbankingComponent lable={netbanking.label}
            value={netbanking.value}
            icon={<img src={netbanking.icon} style={{height:"5vh"}}></img>} >
             </NetbankingComponent>
            }
          </Grid>
        ))}
        <Grid item xs={12} md={12} lg={12}>
          {/* <BankSelectionComponent
            options={netbankingMenus}
            label="Select a bank"
          /> */}
          <NetbankingAutocompleteField options={netbankingMenus} />
        </Grid>
      </Grid>

      <Grid container marginTop={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={makeNetbankingPayment}
            disabled={!selectedNetbankingValue}
          >
            Pay now
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NetbankingPayment;
