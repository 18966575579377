import React from 'react'
import TopBar from './components/TopBar'
import Footer from './components/Footer'
import LocationHero from './components/LocationHero'
import InfoHero from './components/InfoHero'
import HowItWorks from './components/HowItWorks'
const LocationPage = () => {
  return (
    <div className="mainContainer">
    <div className="wrapper">
        <TopBar/>
        <LocationHero/>
    </div>
        {/* <Footer/> */}
    </div>
  )
}

export default LocationPage