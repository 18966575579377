import { Checkbox, FormControlLabel, Link, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateProfileFields } from "../../store/slices/ProfileSlice";

const AgreeTermsConditions = () => {
  const dispatch = useDispatch();

  const [checked, setChecked] = React.useState(false);

  const handleChange = (e) => {
    setChecked(e.target.checked);
    dispatch(
      updateProfileFields({
        termsConditionCheck: e.target.checked,
      })
    );
  };
  React.useEffect(() => {
    return () => {
      setChecked(false);
    };
  }, []);
  return (
    <>
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={<Typography fontWeight={700}>
          I agree to the <Link href="/termsandconditions" > Terms & Conditions</Link>
        </Typography>}
      labelPlacement="end"
      />
      </>
  );
};

export default AgreeTermsConditions;
