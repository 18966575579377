import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import CardHolderName from "../../atoms/payments/card-payment/CardHolderName";
import CardNumber from "../../atoms/payments/card-payment/CardNumber";
import CVV from "../../atoms/payments/card-payment/CVV";
import ExpiryDate from "../../atoms/payments/card-payment/ExpiryDate";
import { PAYMENT_TYPE } from "../../constants";
import { makePayment } from "../../services/payment";

const CardPayment = ({ razorpay = {} }) => {
  const cardHolderName = useSelector(({ payment }) => payment.cardHolderName);
  const cardNumber = useSelector(({ payment }) => payment.cardNumber);
  const expiryDate = useSelector(({ payment }) => payment.expiryDate);
  const cvc = useSelector(({ payment }) => payment.cvc);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleSubmit = () => {
    makePayment({
      razorpay: razorpay,
      type: PAYMENT_TYPE.CARD,
      metaData: {
        card: {
          number: cardNumber,
          name: cardHolderName,
          expiry_month: expiryDate && parseInt(expiryDate.split("/")[0]),
          expiry_year: expiryDate && parseInt(expiryDate.split("/")[1]),
          cvv: cvc,
        },
      },
    });
  };

  return (
    <Box
      sx={{
        paddingTop: 2,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "80%",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9}>
          <CardNumber />
        </Grid>
        {isDesktopOrLaptop && (
          <Grid item lg={3}>
            <ExpiryDate />
          </Grid>
        )}
        <Grid item xs={12} lg={9}>
          <CardHolderName />
        </Grid>
        {isTabletOrMobile && (
          <Grid item xs={5}>
            <ExpiryDate />
          </Grid>
        )}
        <Grid item xs={5} lg={3}>
          <CVV />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <br/>
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            Pay now
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardPayment;
