import { Box, Grid, Typography } from "@mui/material";
import { get, noop } from "lodash";
import React from "react";
import QuestionnaireCheckbox from "../../atoms/reviews/FeedbackSelectionComponent";

const FeedbackQuestionnaire = ({
  setSelectedAnswers = noop,
  feedbackQnsLength = 0,
  selected_answer = -1,
  activeStep = 0,
  question = "",
  options = [],
}) => {
  const [Answer,setAnswer] = React.useState([]);
  const generateAnswers = () => {
    if (get(options, "length")) {
      return options.map((answer, index) => (
        <Grid item xs={6} md={6} lg={6} key={index}>
          <QuestionnaireCheckbox
          feedbackQnsLength = {feedbackQnsLength}
          activeStep = {activeStep}
            setSelectedAnswers={setSelectedAnswers}
            Answer={Answer}
            setAnswer = {setAnswer}
            idx={index}
            value={get(answer, "answer_id")}
            label={get(answer, "answer_content")}
          />
        </Grid>
      ));
    }
    return <></>;
  };

  const answers = generateAnswers();

  return (
    <Box textAlign={"center"}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="caption2">{question}</Typography>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        {answers}
      </Grid>
    </Box>
  );
};
export default FeedbackQuestionnaire;
