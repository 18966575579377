import { getRequestWithParams, postRequestWithHeaders } from "..";
import {
  GET_FEEDBACK_QNA,
  GET_ORDER_REVIEW_QNA,
  SUBMIT_FEEDBACK,
  SUBMIT_ORDER_REVIEW_QNA,
  ORDERS_RATE_PARTNAR,
} from "../ApiConstants";
import { useDispatch, useSelector } from "react-redux";

import { get } from "lodash";
import store from "../../store";
import { fetchCategoryIdByCategory } from "../../utils/CategoryUtils";
import { updateRatingFields } from "../../store/slices/Rating";

export const fetchOrderReviewQuestions = async () => {
  const state = store.getState();
  const orderId = get(state, "orders.orderDetails.order_id_encrypted", "");
  const params = new URLSearchParams();
  params.append("order", orderId);
  try {
    const { data } = await getRequestWithParams(GET_ORDER_REVIEW_QNA, params);
    return get(data, "data", null);
  } catch (e) {
    console.log(e);
    return null;
  }
};


export const submitReviewRating = async ({
  // question = "",
  // answers = [],
  remarks = "",
}) => {
  // if (!question) return null;
  const state = store.getState();
  const orderId = get(state, "orders.orderDetails.order_id_encrypted", "");
//  const rate = get(state, "rating.rate", "");
  
  const params = new FormData();
  params.append("order", orderId);
  // params.append("question", question);
  // if (answers && answers.length) {
  //   answers.forEach((answer) => params.append("answer[]", answer));
  // }
  params.append("remark", remarks);
  // params.append("rate", rate);
  
  try {
    const { data } = await postRequestWithHeaders(
      SUBMIT_ORDER_REVIEW_QNA,
      params
    );
    return get(data, "success", null);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const submitStarRating = async ()=>{
  const state = store.getState();
  const rate = get(state, "rating.rate", "");
  const orderId = get(state, "orders.orderDetails.order_id_encrypted", "");
  const partnelId = get(state, "orders.orderDetails.order_partner_details[0].partner_id", "");
  const params = new FormData();
  params.append("order", orderId);
  params.append("partner", partnelId);
  params.append("rating", rate);
  try {
    const { data } = await postRequestWithHeaders(
      ORDERS_RATE_PARTNAR,
      params
    );
    return get(data, "success", null);
  } catch (e) {
    console.log(e);
    return null;
  }

}
export const fetchFeedbackQuestions = async () => {
  const state = store.getState();
  const orderId = get(state, "orders.orderDetails.order_id_encrypted", "");
  const orderCategory = get(state, "orders.orderDetails.order_category", "");
  if (!orderCategory) return null;
  const category = fetchCategoryIdByCategory(orderCategory);
  const params = new URLSearchParams();
  params.append("order", orderId);
  params.append("category", category);
  try {
    const { data } = await getRequestWithParams(GET_FEEDBACK_QNA, params);
    return get(data, "data", null);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const updateFeedback = async ({ question = "", answer = "" }) => {
  if (!question) return null;
  const state = store.getState();
  const orderId = get(state, "orders.orderDetails.order_id_encrypted", "");
  const params = new FormData();
  params.append("order", orderId);
  params.append("question", question);
  params.append("answer", answer);
  try {
    const { data } = await postRequestWithHeaders(SUBMIT_FEEDBACK, params);
    return get(data, "success", null);
  } catch (e) {
    console.log(e);
    return null;
  }
};
