import MobileStepper from "@mui/material/MobileStepper";
import React from "react";

const FeedbackDotStepper = ({ steps=0, activeStep = 0 }) => {
  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 400, flexGrow: 1 }}
    />
  );
};

export default FeedbackDotStepper;
