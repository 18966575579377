import { Button, Grid, TextField, Typography } from "@mui/material";
import { get, noop } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { fetchAddressTypes } from "../services/confirmation/DateTimeService";
import { updateConfirmationDialogFields } from "../store/slices/ConfirmationDialog";
import { updateGoogleMapsFields } from "../store/slices/GoogleMaps";

const AddressInformation = ({ updateAddress = noop,errormsg="" ,handler}) => {
  const dispatch = useDispatch();

  const doorNumber = useSelector(({ googleMaps }) => googleMaps.doorNumber);
  const landmark = useSelector(({ googleMaps }) => googleMaps.landmark);
  const address = useSelector(({ googleMaps }) => googleMaps.address);
  const addressType = useSelector(({ googleMaps }) => googleMaps.addressType);
  // const errormsg = useSelector(({ googleMaps }) => googleMaps.errormsg);
  const userName = useSelector(({ profile }) =>
    get(profile, "user.user_fullname", "")
  );
  
  const [customerName, setCustomerName] = React.useState("");

  React.useEffect(() => {
    // alert(userName);
   
    setCustomerName(userName);
  }, []);

  const [addressTypesData, setAddressTypesData] = React.useState([]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  React.useEffect(() => {
    const getAddressTypes = async () => {
      setAddressTypesData(await fetchAddressTypes());
     
    };

    getAddressTypes();

    return () => {
      dispatch(
        updateGoogleMapsFields({
          doorNumber: "",
          customerName: "",
          addressType: "",
          landmark: "",
        })
      );
    };
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    dispatch(
      updateGoogleMapsFields({
        [e.target.name]: e.target.value,
      })
    );
  };

  const updateAddressType = (e) => {
    dispatch(updateGoogleMapsFields({ addressType: parseInt(e.target.value)}));
  };

  const resetPosition = () => {
    dispatch(
      updateConfirmationDialogFields({
        activeStep: 0,
        step: 2,
      })
    );
  };

  const generateAddressTypes = () => {
    return addressTypesData.map((selectedAddressType, index) => (
      <Button
        key={index}
        value={selectedAddressType.id}
        onClick={updateAddressType}
        variant={
          addressType === selectedAddressType.id ? "contained" : "outlined"
        }
        style={{
          padding: "0.3rem 1.5rem"
        }}
      >
        {selectedAddressType.title}
      </Button>
    ));
  };

  const addressTypes = generateAddressTypes();

  const getDisabled = () => !customerName || !doorNumber || !addressType;

  return (
    <div
    className="hide_scroll"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        overflow:"scroll",

      }}
    >
      <form onSubmit={handleSubmit} style={{
        height : "54vh",
        width:"100%",
      }}>
        <Grid container spacing={2} height={"100%"} >
          <Grid item lg={12} marginBottom={"0.2rem"} >
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item  lg={8} xs={8} >
                <Typography variant="caption" fontWeight={700}  >
                  {address}
                </Typography>
              </Grid>
              <Grid item lg={4} xs={4}>
                <Button variant="outlined" size="small" onClick={resetPosition}>
                  CHANGE
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
          <TextField 
            type="text"
            error = { doorNumber && doorNumber.length >= 25?true:false}
              id="name"
              name="doorNumber"
              label= {doorNumber && doorNumber.length >= 25?"Maximum 25 characters":"House/Flat number"}
              value={doorNumber}
              aria-describedby="doorNumber"
              sx={{width:"100%"}}
              onChange={handleChange}
          />
            {/* <TextField
              size="small"
              placeholder="House/Flat number"
              fullWidth
              value={doorNumber}
              onChange={handleChange}
              name="doorNumber"
            /> */}
          </Grid>
          <Grid item xs={12} lg={12}>
          <TextField 
          error = { landmark && landmark.length >= 50?true:false}
            type="text"
              id="name"
              name="landmark"
              label={landmark && landmark.length >= 50?"Maximum 50 characters":"Landmark (optional)"}
              value={landmark}
              aria-describedby="landmark"
              sx={{width:"100%"}}
              onChange={handleChange}
          />
            {/* <TextField
              size="small"
              placeholder="Landmark (optional)"
              fullWidth
              value={landmark}
              onChange={handleChange}
              name="landmark"
            /> */}
            
          </Grid>
          <Grid item xs={12} lg={12}>
            {/* <TextField
              size="small"
              placeholder="Name"
              fullWidth
              disabled={userName !== ""}
              value={customerName}
              name="customerName"
              /> */}
            <TextField 
            error={ customerName && customerName.length >= 25?true:false}
            type="text"
            id="customerName"
            name="customerName"
            label={customerName && customerName.length >= 25?"Maximum 25 characters":"Name"}
            value={customerName}
            disabled={userName !== ""}
            aria-describedby="customerName"
            sx={{width:"100%"}}
            onChange={(e) => {setCustomerName(e.target.value);handler(e.target.value)}}
          />
          </Grid>
          <Grid container lg={12}>
            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                padding: "0.5rem 0",
                display: "flex",
                gap: "2.8rem",
                paddingLeft: "1.5rem",
              }}
            >
              {addressTypes}
            </div>
          </Grid>
          {isDesktopOrLaptop && (
            <Grid item xs={12} lg={12} style={{marginTop: isDesktopOrLaptop?"0":"0"}}>
              {/* <Typography sx={{marginTop:{lg:0,xs:1}}}  fontWeight='bold' align="center" color="red" fontSize= "10px">
         {errormsg}
      </Typography> */}
              <Button
                fullWidth
                variant="contained"
                onClick={updateAddress}
                disabled={getDisabled()}
              >
                Save and Proceed
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default AddressInformation;
