import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_MAP_POSITION } from "../../constants";

export const GoogleMapsSlice = createSlice({
  name: "googleMaps",
  initialState: {
    textFieldValue: "",
    enableButton: false,
    position: DEFAULT_MAP_POSITION,
    position_new: DEFAULT_MAP_POSITION,
    address: "",
    errormsg:"",
  },
  reducers: {
    updateGoogleMapsFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateGoogleMapsFields } = GoogleMapsSlice.actions;

export default GoogleMapsSlice.reducer;
