import { get, isEmpty } from "lodash";
import { getRequestWithParams } from "..";
import store from "../../store";
import { fetchCategory,fetchBrand } from "../../utils/CategoryUtils";
import {
  GET_BRANDS_BY_CATEGORY,
  GET_ISSUES_GADGETS,
  GET_ISSUES_GADGETS_DETAIL,
  GET_ISSUES_SEGMENTS,
  GET_ISSUES_SEGMENTS_DETAIL,
  GET_MODELS_BY_CATEGORY,
  GET_SEGMENTS_BY_CATEGORY,
  GET_HERO_SECTION_BY_CATEGORY,
  GET_HERO_SECTION_CONTENT_BY_CATEGORY,
  GET_HERO_SECTION_CONTENT_BY_BRAND,
  GET_HERO_SECTION_CONTENT_BY_MODEL
} from "../ApiConstants";

export const fetchHeroSecticontentonByModel = async (model="",brand = "",category = "",)=>{
  try {
    const state = store.getState();
    let categoryId = "";
    let brandId = "";
    if (!category) {
      categoryId = get(state, "category.selectedCategory.category_id");
    } else {
      const currentCategory = fetchCategory(category);
      categoryId = get(currentCategory, "category_id", "");

      const currentbrand = fetchBrand(brand);
      brandId = get(currentbrand, "brand_id", "");
    }
    // const categoryId = get(category, "category_id",0);
    // const categoryId = get(category, "category_id",0);
    const params = new URLSearchParams();
    params.append("category", category);
    params.append("city", 1);
    params.append("brand", brand);
    params.append("model", model);

    const { data } = await getRequestWithParams(
      GET_HERO_SECTION_CONTENT_BY_MODEL,
      params
    );
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }

}

export const fetchHeroSecticontentonByBrand = async (brand = "",category = "",)=>{

  try {
    const state = store.getState();
    let categoryId = "";
    let brandId = "";
    if (!category) {
      categoryId = get(state, "category.selectedCategory.category_id");
    } else {
      const currentCategory = fetchCategory(category);
      categoryId = get(currentCategory, "category_id", "");

      const currentbrand = fetchBrand(brand);
      brandId = get(currentbrand, "brand_id", "");
    }
    // const categoryId = get(category, "category_id",0);
    // const categoryId = get(category, "category_id",0);
    const params = new URLSearchParams();
    params.append("category", category);
    params.append("city", 1);
    params.append("brand", brand);
    const { data } = await getRequestWithParams(
      GET_HERO_SECTION_CONTENT_BY_BRAND,
      params
    );
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }


}

export const fetchHeroSecticontentonByCategory = async (category = "")=>{

  try {
    const state = store.getState();
    let categoryId = "";
    if (!category) {
      categoryId = get(state, "category.selectedCategory.category_id");
    } else {
      const currentCategory = fetchCategory(category);
      categoryId = get(currentCategory, "category_id", "");
      
    }
    // const categoryId = get(category, "category_id",0);
    // const categoryId = get(category, "category_id",0);
    const params = new URLSearchParams();
    params.append("category", categoryId);
    params.append("city", 1);
    const { data } = await getRequestWithParams(
      GET_HERO_SECTION_CONTENT_BY_CATEGORY,
      params
    );
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }

}
export const fetchHeroSectionByCategory = async (category = "")=>{
  try {
    const state = store.getState();
    let categoryId = "";
    if (!category) {
      categoryId = get(state, "category.selectedCategory.category_id");
    } else {
      const currentCategory = fetchCategory(category);
      categoryId = get(currentCategory, "category_id", "");
    }
    // const categoryId = get(category, "category_id",0);
    // const categoryId = get(category, "category_id",0);
    const params = new URLSearchParams();
    params.append("category", categoryId);
    const { data } = await getRequestWithParams(
      GET_HERO_SECTION_BY_CATEGORY,
      params
    );
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }

}
export const fetchApplianceModels = async (category = "") => {
 

  try {
    const state = store.getState();
    let categoryId = "";
    if (!category) {
      categoryId = get(state, "category.selectedCategory.category_id");
    } else {
      const currentCategory = fetchCategory(category);
      categoryId = get(currentCategory, "category_id", "");
    }
    // const categoryId = get(category, "category_id",0);
    // const categoryId = get(category, "category_id",0);
    const params = new URLSearchParams();
    params.append("category", categoryId);
    const { data } = await getRequestWithParams(
      GET_SEGMENTS_BY_CATEGORY,
      params
    );
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchBrandsByCategory = async (category = {}) => {
  if( isEmpty(category)){
    return [];
    // alert("empty");
  }
  try {
    const categoryId = get(category, "category_id",0);
    const params = new URLSearchParams();
    params.append("category", categoryId);
    const { data } = await getRequestWithParams(GET_BRANDS_BY_CATEGORY, params);
    return get(data, "data", []);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchModelsByCategory = async () => {
  try {
    const state = store.getState();
    const category = get(state, "category.selectedCategory.category_id");
    const brand = get(state, "category.selectedGadgetBrand.brand_id");
    const params = new URLSearchParams();
    params.append("category", category);
    params.append("brand", brand);
    const { data } = await getRequestWithParams(GET_MODELS_BY_CATEGORY, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchIssuesByCategory = async (isGadgets = true) => {
  try {
    const state = store.getState();
    const city = get(state, "location.city", 1);
    const model = get(
      state,
      `category.selectedModel.${isGadgets ? "model_id" : "segment_id"}`
    );
    const userLoggedIn = get(state, "profile.loggedIn", false);

    const params = new URLSearchParams();
    if (userLoggedIn) params.append("city", city);
    params.append(isGadgets ? "model" : "segment", model);

    let url = "";
    if (isGadgets) {
      url = userLoggedIn ? GET_ISSUES_GADGETS_DETAIL : GET_ISSUES_GADGETS;
    } else {
      url = userLoggedIn ? GET_ISSUES_SEGMENTS_DETAIL : GET_ISSUES_SEGMENTS;
    }

    const { data } = await getRequestWithParams(url, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchInformationContentByCategory = async ({
  valueKey = "",
  value = "",
  api = "",
  cata_id="",
  brand_id=""
}) => {
  try {
    const params = new URLSearchParams();
    params.append(valueKey, value);
    params.append("category", cata_id);
    params.append("city", 1);
    params.append("brand", brand_id);
    const { data } = await getRequestWithParams(api, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};
