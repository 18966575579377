import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { GoogleApiWrapper, Map } from "google-maps-react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_MAP_POSITION,
  YOUR_GOOGLE_MAPS_API_KEY,
} from "../../constants";
import { updateGoogleMapsFields } from "../../store/slices/GoogleMaps";

import { getCurrentLocation } from "../../utils/GoogleMapsUtils";
import axios from "axios";
import { useState } from "react";
import { ENQUIRY_GT } from "../../services/ApiConstants";
const MapsAutocomplete = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setResponse, fromlanding, enq_id,setisLoading } = props;
  let autocompleteRef = React.useRef();

  const textFieldValue = useSelector(
    ({ googleMaps }) => googleMaps.textFieldValue
  );
  const [Address, setAddress] = React.useState({});

  React.useEffect(() => {
    const renderAutoComplete = () => {
      const { google, map } = props;
      if (!google || !map) return;

      const autocomplete = new google.maps.places.Autocomplete(
        autocompleteRef.current
      );
      autocomplete.bindTo("bounds", map);

      autocomplete.addListener("place_changed", async () => {
        // alert("working");
        const place = autocomplete.getPlace();

        if (!place.geometry) return;

        if (place.geometry.viewport) map.fitBounds(place.geometry.viewport);
        else {
          map.setCenter(place.geometry.location);
          map.setZoom(19);
        }

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        //  alert(lat);
        //  alert(lng);
        //   const { long_name: postalCode_new = "" } =
        //   place.address_components.find((c) =>
        //   c.types.includes("postal_code")
        // ) || {};
        //   alert(postalCode_new);

        // alert(newaddress);
        const { postalCode } = await getCurrentLocation(lat, lng);

        const address = place.formatted_address;
        //  alert(newaddress);
        setAddress(place);
        dispatch(
          updateGoogleMapsFields({
            textFieldValue: place.formatted_address,
            enableButton: true,
            position: {
              lng,
              lat,
            },
            position_new: {
              lng,
              lat,
            },
            postalCode,
            address,
            pincode: postalCode,
          })
        );
      });
    };

    renderAutoComplete();
  }, [dispatch, props]);

  const handleChange = (e) => {
    dispatch(
      updateGoogleMapsFields({
        textFieldValue: e.target.value,
        enableButton: false,
        address: "",
      })
      );
    setAddress(textFieldValue);
    console.log(Address);
  };

  const resetPosition = () => {
    setResponse('');
    dispatch(
      updateGoogleMapsFields({
        textFieldValue: "",
        enableButton: false,
        position: DEFAULT_MAP_POSITION,
        address: "",

      })
    );
  };
  // const [Obj, setObj] = useState({});
  const pushData = async () => {
    
    if(textFieldValue == ""){
      console.log("error");
      return;
    }
    try {
      let token = localStorage.getItem('token');
      const { postalCode } = await getCurrentLocation(Address.geometry.location.lat(), Address.geometry.location.lng());
      console.log(postalCode,Address.formatted_address);
      const data = {
        enquiryId: enq_id,
        addressFull: Address.formatted_address,
        addressArea: '0',
        pincode: postalCode,
        longitude: Address.geometry.location.lng(),
        latitude: Address.geometry.location.lat(),
        gtpInternal: false,
        pincodeId: 0,
        areaId: 0
      };

      console.log("Data:", data);
      const response = await fetch(ENQUIRY_GT, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseJson = await response.json();

      if (responseJson.success === false) {
        console.log("Error:", responseJson.error); // Assuming the error message is in the "error" field
      } else {
        console.log("Success:", responseJson);
        
        // setTimeout(() => {
        //   setisLoading(false);
        // }, 500);
          navigate("/temp_conform");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  // const pushData = async () => {
  //   try {
  //     let token = localStorage.getItem('token');
  //     const { postalCode } = await getCurrentLocation(Address.geometry.location.lat(), Address.geometry.location.lng());
  //     console.log(Obj)
  //     setObj({
  //       enquiryId: 0,
  //       addressFull1: Address.formatted_address,
  //       addressArea: 0,
  //       pincode: postalCode,
  //       longitude: Address.geometry.location.lng(),
  //       latitude: Address.geometry.location.lat(),
  //       gtpInternal: false,
  //       pincodeId: 0,
  //       areaId: 0
  //     });
  //     console.log("Key", Obj);
  //     fetch('https://bee.nutshell.repair/api/v1/users/enquiries_gt', {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(Obj)
  //     }).then((response) => response.json())
  //       .then((responsejson) => {
  //         if (responsejson.success === false) {
  //           console.log("error")
  //         }
  //         else {
  //           console.log(responsejson);
  //         }
  //       }).catch((error) => {
  //         console.log(error);
  //       })
  //   } catch (error) {
  //     console.log(error);
  //   }

  // }

  return (
    <>
      <TextField
        fullWidth
        color="primary"
        variant="outlined"
        id="country"
        name="country"
        placeholder={fromlanding?"Enter your Pincode or Address":props.placeholder}
        onChange={handleChange}
        value={textFieldValue}
        inputRef={(ref) => (autocompleteRef.current = ref)}
        InputProps={{
          endAdornment: !props.disableAdornment && (
            <InputAdornment
              sx={{ cursor: "pointer" }}
              position="end"
              onClick={fromlanding?pushData():resetPosition}
            >
              <Typography color="primary" fontWeight={700}>
                {fromlanding?"":"CHANGE"}
              </Typography>
            </InputAdornment>
          ),
        }}
      />
      {/* <Button onClick={() => { pushData() }}>Submit</Button> */}
    </>
  );
};

const MapWrapper = (props) => (
  <Map
    google={props.google}
    visible={false}
    containerStyle={{ position: "relative" }}
  >
    <MapsAutocomplete {...props} />
  </Map>
);

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_MAPS_API_KEY,
})(MapWrapper);
