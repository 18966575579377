import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import AddressInformation from "../../molecules/AddressInformation";
import MapsContainer from "../../molecules/new-google-maps/MapsContainer";
import { updateOrderAddress } from "../../services/confirmation";
import { updateUserProfile } from "../../services/user-profile";
import {
  updateConfirmationDialogFields,
  updateToNextStep,
} from "../../store/slices/ConfirmationDialog";
import { get, noop } from "lodash";
import { updateGoogleMapsFields } from "../../store/slices/GoogleMaps";
import { convertToAddressFormat } from "../../utils";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import { useLocation } from "react-router-dom";
const Address = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()
  const doorNumber = useSelector(({ googleMaps }) => googleMaps.doorNumber);
  const customerName_n = useSelector(({ googleMaps }) => googleMaps.customerName);
  const addressType = useSelector(({ googleMaps }) => googleMaps.addressType);
  const address = useSelector(({ googleMaps }) => googleMaps.address);
  // const postalCode = useSelector(({ googleMaps }) => googleMaps.postalCode);
  const landmark = useSelector(({ googleMaps }) => googleMaps.landmark);
  const pincode = useSelector(({ googleMaps }) => googleMaps.postal_code);
  // alert(address);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [customerName, setCustomerName] = React.useState("");
  const [errormsg, seterrormsg] = React.useState("");
  const [callprofileapi, setcallprofileapi] = React.useState(false);
  // const [getDisabled, setgetDisabled] = React.useState(false);

  const userName = useSelector(({ profile }) =>
    get(profile, "user.user_fullname", "")
  );

  React.useEffect(() => {
    if (userName == '') {
      setcallprofileapi(true);
      setCustomerName('..');
      // if(!doorNumber || !addressType){
      //   setgetDisabled(true);
      // }
    } else {
      setcallprofileapi(false);
      setCustomerName(userName);
      // if(!customerName || !doorNumber || !addressType){
      //   setgetDisabled(true);
      // }
    }

  }, []);

  React.useEffect(() => {
    dispatch(
      updateConfirmationDialogFields({
        dialogTitle: "Schedule Appointment",
        showStepper: true,
        activeStep: 1,
      })
    );

    // return () => {
    //   dispatch(updateToPreviousStep(3));
    // };
  }, [dispatch]);

  const updateAddress = async () => {
    //  alert(callprofileapi);
    // alert(customerName);
    const { addressOne, addressTwo } = convertToAddressFormat(address);
    if (callprofileapi) {
      const payload = new FormData();
      payload.append("userName", customerName);
      // dispatch(updateLoaderFields({ load: true }));
      const response = await updateUserProfile(payload);
    } else {
      // alert("not call api");
    }
    const addressnew = await updateOrderAddress({
      addressNo: doorNumber,
      addressType,
      addressOne,
      addressTwo,
      landmark,
      // Ask Mohammed about pincode length
      pincode: pincode,
    });
    // console.log('addressIddqw');
    // console.log(addressId.success);
    if (addressnew.success == false) {
      seterrormsg(JSON.stringify(addressnew.message).replace(/[{}]/g, ""));

      dispatch(
        updateGoogleMapsFields({
          errormsg: errormsg,
        })
      );

      dispatch(
        updateGoogleMapsFields({
          addressId: '',
        })
      );
      // const erormsg = JSON.stringify(addressId.message).replace(/[{}]/g, "");
      // alert(JSON.stringify(addressId.message).replace(/[{}]/g, ""));
      // console.log('addressIdd');
      //     console.log(JSON.stringify(addressId.message).replace(/[{}]/g, ""));
    } else {

      const addressId = addressnew.data;
      if (addressId) {
        dispatch(
          updateGoogleMapsFields({
            addressId: addressId,
          })
        );

        var newAddress = {
          address_city: "Bengaluru",
          address_city_id: "1",
          address_country: "India",
          address_country_id: "1",
          address_created_at: "",
          address_id: addressId,
          address_landmark: "0",
          address_line_1: addressOne,
          address_line_2: addressTwo,
          address_no: doorNumber,
          address_pincode: '',
          address_pincode_id: pincode,
          address_primary: false,
          address_state: "",
          address_state_id: "1",
          address_type_id: addressType,
          address_type_title: "",
        }
        dispatch(
          updateConfirmationDialogFields({
            selectedAddress: newAddress,
          })
        );
        localStorage.setItem("addresss_new", JSON.stringify(newAddress));
        // var isfromlanding = window.location.pathname === "/thanks_ln_enq" || window.location.pathname === "/thanks_ln_enq_2" || window.pathname.pathname === "/temp_conform"
        // console.log(isfromlanding);
        if (location.pathname === "/temp_conform" || location.pathname === "/conform2") {
          localStorage.removeItem("from_landing");
          console.log("landing");

          // dispatch(updateLoaderFields({ load: true }));
          dispatch(
            updateConfirmationDialogFields({
              open: false,
            })
          );
          navigate("/conform2")
          // navigate("/thanks_ln_enq_2")
        }
        //   dispatch(updateLoaderFields({ load: true }));
        // const enquiry = await createEnquiry();
        // if (!enquiry) {
        //   alert("Issue in creating enquiry");
        // }
        else {
          console.log("Landing");
          dispatch(updateToNextStep());
        }
      }

    }
  };

  const getDisabled = () => !customerName || !doorNumber || !addressType;
  console.log(getDisabled());
  const { isMobile } = useScreenResolution();
  console.log(customerName, addressType, doorNumber, userName)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}  >
        <AddressInformation updateAddress={updateAddress} errormsg={errormsg} handler={setCustomerName} />
      </Grid>
      <Grid item xs={12} lg={6} style={{ display: isMobile ? "none" : "" }}>
        <MapsContainer disableMap={true} />
      </Grid>
      {isTabletOrMobile && (
        <Grid item xs={12} lg={6} sx={{ marginTop: { xs: "0", lg: "3rem" } }}>
          <Typography sx={{ marginTop: { lg: 0, xs: 1 }, fontSize: { xs: "15px", lg: "15px" } }} fontWeight='bold' align="center" color="red" fontSize="10px">
            {errormsg}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            onClick={updateAddress}
            disabled={getDisabled()}
          >
            Save and Proceed
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Address;
