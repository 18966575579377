import { Autocomplete, TextField } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentFields } from "../../store/slices/Payment";

const NetbankingAutocompleteField = ({ options = [] }) => {
  const value = useSelector(({ payment }) =>
    get(payment, "selectedNetbankingValue", "")
  );
  const [inputValue, setInputValue] = React.useState("");

  const dispatch = useDispatch();

  return (
    <Autocomplete
      value={value}
      isOptionEqualToValue={(option, value) =>
        option.value === value.value || ""
      }
      onChange={(event, newValue) => {
        dispatch(
          updatePaymentFields({
            selectedNetbankingValue: newValue,
          })
        );
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="netbanking-autocomplete"
      options={options}
      renderInput={(params) => (
        <TextField variant="filled" fullWidth {...params} label="Bank" />
      )}
    />
  );
};

export default NetbankingAutocompleteField;
