import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import DevicesIcon from '@mui/icons-material/Devices';
import HelpIcon from '@mui/icons-material/Help';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch } from "react-redux";
import {
  resetProfileFields,
  updateProfileFields,
} from "../../store/slices/ProfileSlice";
import { updateCartFields } from "../../store/slices/CartSlice";
const BottomNavigationMenu = () =>{
    const [Value,setValue] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector(({ profile }) => profile.loggedIn);
    const loginUser = () => {
      dispatch(updateProfileFields({ openLogin: true }));
    };
    const openDevicesDialog = () => {
      dispatch(updateCartFields({ openDeviceDialog: true }));
    }
    return(
        <Paper sx={{ position: 'fixed', bottom: 0.5, left: 0, right: 0 ,zIndex:800}} elevation={2}>
            <BottomNavigation
            showLabels
            value={Value}
            onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
            <BottomNavigationAction label="Home" onClick={()=>navigate('/')} icon={<HomeIcon />} />
            <BottomNavigationAction label="Device" onClick={()=>openDevicesDialog()} icon={<DevicesIcon />} />
            <BottomNavigationAction label="Help" onClick={()=>navigate('/ContactUs')} icon={<HelpIcon />} />
            <BottomNavigationAction label="Account" onClick={()=> userLoggedIn?navigate('/orders'):loginUser()} icon={<AccountCircleIcon />} />
            </BottomNavigation>
      </Paper>
    );
};

export default BottomNavigationMenu;