import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { noop } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { getRequestCallback } from "../../services/home";
import { updateLoaderFields } from "../../store/slices/LoaderSlice";
import { validateEmail, validateMobileNumber } from "../../utils";

const RequestCallbackDialog = ({
  openRequestCallback = false,
  handleClose = noop,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [request, setRequest] = React.useState({
    name: "",
    mobile: "",
    email: "",
    description: "",
  });
  const handleRequestCallback = async () => {
    const response = await getRequestCallback(request);
    const { success, message } = response;
    dispatch(
      updateLoaderFields({
        alertText: message,
        severity: success ? "success" : "error",
      })
    );
    if (success) handleClose();
  };
  const handleChange = (e) => {
    setRequest({
      ...request,
      [e.target.name]: e.target.value,
    });
  };

  const getDisabledBtn = () => {
    const { name, mobile, email, description } = request;
    console.log(validateEmail(email));
    return (
      name &&
      validateMobileNumber(mobile) &&
      validateEmail(email) &&
      description
    );
  };
  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={openRequestCallback}
      onClose={handleClose}
      aria-labelledby="request-callback-dialog-title"
      sx={{
        marginTop: { xs: "50%", md: "50%", lg: "0%" },
        height: { lg: "104vh" },
      }}
      width="lg"
    >
      <DialogTitle>
        <Box sx={{ textAlign: "end" }}>
          <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Stack sx={{ width: "100%" }} spacing={3}>
            <TextField
              name="name"
              value={request.name}
              onChange={handleChange}
              placeholder="Your Name"
              fullWidth
            />
            <TextField
              name="mobile"
              value={request.mobile}
              onChange={handleChange}
              placeholder="Your Phone Number"
              fullWidth
            />
            <TextField
              name="email"
              value={request.email}
              onChange={handleChange}
              placeholder="Your Email"
              fullWidth
            />
            <TextField
              name="description"
              value={request.description}
              onChange={handleChange}
              multiline
              rows={4}
              placeholder="Your Query"
              fullWidth
            />
          </Stack>
          <br />
          <Button
            disabled={!getDisabledBtn()}
            fullWidth
            variant="contained"
            onClick={handleRequestCallback}
          >
            Request a Call Back
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RequestCallbackDialog;
