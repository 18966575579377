import { createSlice } from "@reduxjs/toolkit";
import { get, set } from "lodash";

export const ConfirmationDialog = createSlice({
  name: "confirmationDialog",
  initialState: {
    dialogTitle: "Schedule Appointment",
    step: 0,
    showStepper: true,
    address: {},
    slots: {},
    activeStep: 0,
    open: false,
  },
  reducers: {
    updateConfirmationDialogFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    updateToNextStep: (state, action) => {
      if (state.step < 4)
        set(state, "step", get(state, "step", 0) + get(action, "payload", 1));
    },
    updateToPreviousStep: (state, action) => {
      const currentStep = get(state, "step", 0);
      if (currentStep - get(action, "payload", 1) >= 0)
        if (state.step > 0)
          set(state, "step", get(state, "step", 0) - get(action, "payload", 1));
    },
  },
});

export const {
  updateConfirmationDialogFields,
  updateToNextStep,
  updateToPreviousStep,
} = ConfirmationDialog.actions;

export default ConfirmationDialog.reducer;
