import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../services/user-profile";
import { updateLoaderFields } from "../../store/slices/LoaderSlice";
import { updateProfileFields } from "../../store/slices/ProfileSlice";
import { validateEmail } from "../../utils";

const AddEmail = () => {
  const open = useSelector(({ profile }) => get(profile,'emailDialog',false));

  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleClose = () => {
    dispatch(
      updateProfileFields({
        emailDialog: false,
      })
    );
  };

  const validate = () => {
    return email ? validateEmail(email) : false;
  };

  React.useEffect(() => {
    return () => setEmail("");
  }, [open]);

  const saveEmail = async () => {
    if (!email) return alert("Invalid Email");

    const payload = new FormData();
    payload.append("userEmail", email);
    dispatch(updateLoaderFields({ load: true }));
    const response = await updateUserProfile(payload);
    dispatch(updateLoaderFields({ load: false }));
    if (response) {
      alert("Profile Updated Successfully");
      dispatch(updateProfileFields({
        [`user['user_email']`]: email
      }))
      handleClose();
    } else {
      alert("Profile Update Error");
    }
  };

  return (
    <Dialog maxWidth={"xs"} fullWidth={true} open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={11} md={11} lg={11}>
            <Typography align="center">Add Email</Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1}>
            <CloseIcon onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          id="standard-input"
          label="Email"
          type="email"
          variant="standard"
          fullWidth
          onChange={handleChange}
          value={email}
          error={validate()}
          helperText={validate() && "Invalid Email"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={saveEmail}
          variant="contained"
          fullWidth
          disabled={validate()}
        >
          Add Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEmail;
