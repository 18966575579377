import { Avatar } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import defaultPartnerImage from "../../assets/default-partner-image.svg";

const PartnerAvatar = () => {
  const partnerInformation = useSelector(({ orders }) =>
    get(orders, "orderDetails.order_partner_details[0]", {})
  );

  return partnerInformation ? (
    <Avatar
      sx={{
        bgcolor: "#DDDDDD",
        justifyContent: "center",
        width: 56,
        height: 56,
      }}
      src={partnerInformation["partner_photo"]}
      alt={partnerInformation["partner_name"]}
    >
      P
    </Avatar>
  ) : (
    <img style={{ height: "13vh" }} src={defaultPartnerImage} alt={"P"} />
  );
};

export default PartnerAvatar;
