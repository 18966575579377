import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid, Radio, Typography } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderAddress } from "../../services/confirmation";
import { useHistory, useNavigate } from "react-router-dom";
import {
  updateConfirmationDialogFields,
  updateToNextStep,
} from "../../store/slices/ConfirmationDialog";
import { updateGoogleMapsFields } from "../../store/slices/GoogleMaps";

const SelectAddressComponent = ({
  address = {},
  editAddress = () => {},
  deleteAddress = () => {},
}) => {
  const dispatch = useDispatch();
  
  const selectedAddress = useSelector(
    ({ confirmationDialog }) => confirmationDialog.selectedAddress
  );
  const {isMobile,isMiniMobile} = useScreenResolution();
  React.useEffect(() => {
    dispatch(
      updateConfirmationDialogFields({
        dialogTitle: "Checkout",
        showStepper: true,
        activeStep: 1,
      })
    );
  }, []);

  const handleChange = (newAddress = {}) => {
    // console.log("wwewwewe");
    // console.log(newAddress);
    dispatch(
      updateConfirmationDialogFields({
        selectedAddress: newAddress,
      })
    );
    dispatch(
      updateGoogleMapsFields({
        addressId: newAddress.address_id,
      })
    );

    localStorage.setItem("addresss_new", JSON.stringify(newAddress));
  };

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
        spacing={{ xs: 1, lg: 2 }}
        sx={{justifyContent:isMobile?"space-between":"flex-start"}}
        width={"60%"}
        margin="0 auto"
        onClick={() => handleChange(address)}
      >
        <Grid item lg={1} xs={1} md={1}>
          <Radio
            checked={
              get(selectedAddress, "address_id") === get(address, "address_id")
            }
            value={address}
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
          />
        </Grid>
        <Grid item lg={8} xs={10} md={9} display='flex' alignItems='left'>
        {/* <Typography align="left"
            variant="caption">Home</Typography> */}
          <Typography
            align="left"  
            variant="caption"
            onClick={() => handleChange(address)}
          >
            <span style={{fontWeight:'bolder',fontSize:'12px'}} >{get(address, "address_type_title", "").slice(0,1).toUpperCase()}{get(address, "address_type_title", "").slice(1)}</span>
            <br/>
            {get(address, "address_line_1", "")}
          </Typography>
        </Grid>
        {/* <div className="delete-edit-btn" style={{display:'flex',alignItems:'center',justifyContent:'center', gap:'2rem', paddingTop: isMobile?'':'2rem'}}> */}
        {/* <Grid item lg={1} xs={1} md={1}>
          <EditIcon style={{cursor:'pointer'}} color="primary" onClick={() => editAddress(address)} />
        </Grid> */}
        {/* <Grid item lg={1} xs={1} md={1}>
          <DeleteIcon style={{cursor:'pointer'}} color="error" onClick={() => deleteAddress(address)} />
        </Grid> */}
        {/* </div> */}
      </Grid>
      <hr style={{border:"1px solid #c7c7c7d8 "}} />
    </>
  );
};

const SelectAddress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allUserAddress = useSelector(
    ({ confirmationDialog }) => confirmationDialog.allUserAddress
  );
  const selectedAddress = useSelector(
    ({ confirmationDialog }) => confirmationDialog.selectedAddress
  );
  const confirmAddress = async () => {

    // var isfromlanding = localStorage.getItem("from_landing");
    // var isfromlanding = window.location.pathname === "/thanks_ln_enq" ||  window.location.pathname === "/thanks_ln_enq_2"
    var isfromlanding = window.location.pathname === "/temp_conform" || window.location.pathname === "/conform2" 

    console.log(window.location.pathname);
    if(isfromlanding){
      localStorage.removeItem("from_landing");
      
      // dispatch(updateLoaderFields({ load: true }));
      dispatch(
        updateConfirmationDialogFields({
          open: false,
        })
      );
      // navigate("/thanks_ln_enq_2")
      navigate("/conform2"); 

    }else{
      dispatch(updateToNextStep(3));
    }
    
  
  };

  const addNewAddress = () => {
    dispatch(updateToNextStep());
  };

  const deleteAddress = (address = {}) => {
    dispatch(
      updateConfirmationDialogFields({
        allUserAddress: allUserAddress.filter(
          (addr) => get(addr, "address_id", 0) !== get(address, "address_id", 0)
        ),
      })
    );
  };

  const editAddress = () => {
    dispatch(updateToNextStep());
  };

  return (
    <Box
      sx={{
        padding: { lg: 2, xs: 0 },
        margin: "0 auto",
        width: { lg: "80%", xs: "100%", md: "100%" },
      }}
    >
      <Box sx={{ height: "45vh", overflowY: "auto", paddingTop: 2,marginTop: 3 }}>
        {allUserAddress &&
          allUserAddress.map((address, index) => (
            <SelectAddressComponent
              key={index}
              address={address}
              deleteAddress={deleteAddress}
              editAddress={editAddress}
            />
          ))}
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            paddingTop: 2,
          }}
          onClick={addNewAddress}
          style={{cursor:'pointer'}}
        >
          <Typography fontWeight={700} paddingLeft={2} color="primary">
            +
          </Typography>
          <Typography fontWeight={700} paddingLeft={2}>
            Add New Address
          </Typography>
        </Grid>
        <Grid item lg={12} xs={12} md={12}>
          <Button
            variant="contained"
            disabled={!selectedAddress}
            fullWidth
            onClick={confirmAddress}
          >
            Confirm with this Address  
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
// 

export default SelectAddress;
