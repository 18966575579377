import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import searchIcon from "../../assets/search-icon.svg";
import {
  updateConfirmationDialogFields,
  updateToNextStep,
} from "../../store/slices/ConfirmationDialog";
import { updateGoogleMapsFields } from "../../store/slices/GoogleMaps";
import { fetchCurrentLocation } from "../../utils/GoogleMapsUtils";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
const Location = () => {
  const dispatch = useDispatch();
  const {isMobile} = useScreenResolution();
  React.useEffect(() => {
    dispatch(
      updateConfirmationDialogFields({
        dialogTitle: "Select Location",
        showStepper: false,
      })
    );
  }, [dispatch]);

  const handleSearchLocation = () => {
    dispatch(updateToNextStep());
  };

  const getCurrentLocation = async () => {
    await fetchCurrentLocation(dispatch, updateGoogleMapsFields);
    dispatch(updateToNextStep());
  };
  return (
    <Box sx={{ paddingTop: 2, paddingBottom: 3 }}  textAlign="center">
      <br />
      <br />
      <Typography color="textsecondary" fontWeight={600}>
        Select your Service location to get started
      </Typography>
      <br />
      <br />

      <Stack spacing={2} marginTop= {isMobile?"5rem":"1rem"}  >
        <Button
          fullWidth
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={getCurrentLocation}
        >
          Use my Current Location
        </Button>
        <Typography color="textsecondary">OR</Typography>
        <Button
          fullWidth
          variant="outlined"
          sx={{ textTransform: "none", color: "#000" }}
          onClick={handleSearchLocation}
        >
          <img src={searchIcon} alt="Search" height="20px" width="20px" />{" "}
          &nbsp; Search street, locality, etc
        </Button>
      </Stack>
    </Box>
  );
};

export default Location;
