import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../atoms/components/NotFound";
import Home from "../../organisms/home";
import MyBooking from "../../organisms/my-bookings";
import OrderDetails from "../../organisms/my-bookings/OrderDetails";
import Orders from "../../organisms/my-bookings/Orders";
import Offers from "../../organisms/offers";
import AboutUs from "../home/AboutUs";
import ContactUs from "../home/ContactUs";
import TermsOfUse from "../home/TermsofUse";
import SelectDeviceComponent from "../select-device";
import Landing from "../landingpage";
import LandingMain from "../LandingPageV2/LandingMain";
import Confirmation from "../landingpage/pages/Confirmation"
import Thanku from "../landingpage/pages/Thanku"
 import Thanku_fin from "../landingpage/pages/Thanku_fin"
import PrivacyPolicy from "../home/PrivacyPolicy";
import CancellationTOC from "../home/CancellationTOC";
import TermsAndConditions from "../home/TermsAndConditions";
import Warrantypolicy from "../home/Warrantypolicy";
import Conformation from "../LandingPageV2/Conformation";
import Conformation2 from "../LandingPageV2/Conformation2";
import ThankYou from "../LandingPageV2/ThankYou";
import LandingMobile from "../LandingPageV2/LandingMobile";
import DeviceLanding from "../LandingPageV2/DeviceLanding";
import LocationPage from "../LandingPageV2/LocationPage";
const Navbar = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingMain />} />
      <Route path="/location" element={<LocationPage/>}  />
      <Route path="/temp_conform" element={<Conformation />} />
      <Route path="/conform2" element={<Conformation2/>} />
      <Route path="/thanks" element={<ThankYou/>} />
      {/* <Route path="/" exact element={<Home />} /> */}
      {/* <Route path="/:category"  element={<SelectDeviceComponent />} /> */}
      {/* <Route
        path="/:category/:brand"
        exact
        element={<SelectDeviceComponent />}
      />
      <Route
        path="/:category/:brand/:model"
        exact
        element={<SelectDeviceComponent />}
      />
      <Route path="/orders" exact element={<MyBooking />}>
        <Route path="/orders/" element={<Orders />} />
        <Route path="/orders/:orderId" element={<OrderDetails />} />
      </Route>
      <Route path="/offers" element={<Offers />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/termsOfUse" element={<TermsOfUse />} />
      <Route path="/privcypolicy" element={<PrivacyPolicy />} />
      <Route path="/cancellation" element={<CancellationTOC />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/warranty-policy" element={<Warrantypolicy />} />
      <Route path="/ap-enquiry-order_old" element={<Landing />} />
      <Route path="/devicelanding" element={<DeviceLanding />} />

      <Route path="/thanks_ln_enq" element={<Confirmation />} />
      <Route path="/thanks_ln_enq_2" element={<Thanku />} />
      <Route path="/thanks_order/" element={<Thanku_fin />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/service-mobile-blr" element={<LandingMobile />} /> */}
      {/* <Route path="/landingpage" element={<Landing />} /> */}
      {/* <Route path="/Confirmation" element={<Confirmation />} /> */}
      {/* <Route path="/Thanku" element={<Thanku />} /> */}
      {/* <Route path="/ThankYou" element={<Thanku_fin />} /> */}
    </Routes>
  );
};

export default Navbar;
