import { Typography } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";

const PartnerName = () => {
  const partnerInformation = useSelector(({ orders }) =>
    get(orders, "orderDetails.order_partner_details[0]", {})
  );

  return partnerInformation ? (
    <Typography align='center'>{partnerInformation["partner_name"]}</Typography>
  ) : (
    <></>
  );
};

export default PartnerName;
