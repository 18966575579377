import React, { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useScreenResolution } from '../../../hooks/ResponsiveHook';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { Button, Typography, Box } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Model_Form = ({ devicedetails, setmodeldetails, setStep, Step, setdevicedetails, setDeviceName }) => {
  const HOST = process.env.REACT_APP_API_URL;
  // const [modeldetails,setmodeldetails] = useState({
  //     model_name:'',
  //     model_id:'',
  // });
  const { isMobile } = useScreenResolution();
  const [ModelList, setModelList] = useState([]);
  const [modellist_new, setmodellist_new] = useState([]);
  const fetchModelList = async () => {
    fetch(HOST + '/api/v1/models_by_category_and_brand?category=' + devicedetails.device_id + '&brand=' + devicedetails.brand_id + '', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json())
      .then((respJson) => {
        if (respJson.success === false) { setModelList([]); }
        else {
          setModelList(respJson.data);
          setmodellist_new(respJson.data)
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  const setModel = async (modelName, modelId) => {
    const modelInfo = {
      model_name: modelName,
      model_id: modelId
    }
    setmodeldetails(modelInfo);
    setStep(Step + 1);
  }
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const myfunction = e => {

    const updatedList = modellist_new.filter(item => {
      return (
        item.model_title.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });
    // setmodellist({ modellist: updatedList });
    setModelList(updatedList)

  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    fetchModelList();
  }, [])
  return (
    <>
      {!isMobile && <FormControl fullWidth  >
        <InputLabel id="demo-simple-select-label">Select Model</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          open
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300
              }
            }
          }}
        >
          <MenuItem onClick={() => {
            setStep(Step - 1); setdevicedetails({
              device_name: '',
              device_id: '',
              brand_id: ''
            })
          }}><ArrowBackIcon /></MenuItem>
          {/* <MenuItem onKeyDown={(e) => e.stopPropagation()}>  <TextField
            id="name"
            label="Search model"
            aria-describedby="passwordHelpInline"
            onChange={e => myfunction(e)}

            sx={{ width: "90%", marginTop: 2, marginLeft: 2 }}
          /></MenuItem> */}
          {ModelList.map((item, index) => (
            <MenuItem key={index} value={10} onClick={() => setModel(item.model_title, item.model_id)} >{item.model_title}</MenuItem>
          ))}
        </Select>
      </FormControl>}
      {isMobile && <Button variant='outlined' sx={{ border: "1px solid #ababab", padding: "15px 14px", color: "#5d5d5d", textTransform: "capitalize", display: "flex", justifyContent: "space-between", fontSize: "16px", display: Step === 2 ? "" : "none" }} endIcon={<KeyboardArrowDownIcon />} onClick={handleClickOpen} >Select Model</Button>}

      {isMobile && <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={fullScreen}
        maxWidth="md"
        onClose={handleClose}
        sx={{
          marginTop: "40%",
          Padding: "0"
        }}

        PaperProps={{
          sx: {
            borderRadius: { lg: "8px" },
            borderTopLeftRadius: isMobile ? "1.5rem" : "",
            borderTopRightRadius: isMobile ? "1.5rem" : ""
          }
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }} onClick={() => {
            setStep(Step - 1);
            setdevicedetails({
              device_name: '',
              device_id: '',
              brand_id: ''
            });
            setDeviceName('')
          }} >
            <ArrowBackIcon />
            <Typography align='left' style={{ fontSize: "1.6rem", width: "100%", fontWeight: "bold" }}> Select Model</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "0.8rem" }}>
              <TextField
                id="name"
                label="Search model"
                aria-describedby="passwordHelpInline"
                onChange={e => myfunction(e)}

                sx={{ width: "100%", marginTop: 2 }}
              />
              {ModelList.map((item, index) => (
                <Box key={index} sx={{ cursor: "pointer", borderBottom: "3px solid #F5F6Fa", paddingY: 0.8 }} onClick={() => setModel(item.model_title, item.model_id)} >
                  <Typography style={{ fontSize: "1.2rem" }} >{item.model_title}</Typography>
                </Box>
              ))}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>}
    </>
  )
}

export default Model_Form