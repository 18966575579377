import { Box, Stack, Typography } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Parser from 'html-react-parser';
import reactStringReplace from 'react-string-replace';
import { stripHtml } from "string-strip-html";

import {
  GET_INFO_BY_BRAND,
  GET_INFO_BY_CATEGORY,
  GET_INFO_BY_MODEL,
} from "../../services/ApiConstants";
import { fetchInformationContentByCategory } from "../../services/categories";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
const InformationSection = () => {

  const {isDesktopOrLaptop} = useScreenResolution();
  const [informations, setInformations] = React.useState([]);
  const [knowMore, setKnowMore] = React.useState(false);
  const [rests, setrests] = React.useState('working');
  const categoryItems = useSelector(({ category }) => category);
  const { category, brand, model } = useParams();
  // console.log(informations);
  React.useEffect(() => {
    // console.log(categoryItems);
    const getInformationByCategory = async () => {
      let valueKey = "";
      let value = "";
      let api = "";
      let cata_id = "";
      let brand_id = "";
      switch ("string") {
        case typeof model:
          valueKey = "model";
          value = get(categoryItems, "selectedModel.model_id", 1);
          api = GET_INFO_BY_MODEL;
          cata_id = get(categoryItems, "selectedCategory.category_id", 1);
          brand_id = get(categoryItems, "selectedGadgetBrand.brand_id", 1);
          break;
        case typeof brand:
          valueKey = "brand";
          value = get(categoryItems, "selectedGadgetBrand.brand_id", 1);
          api = GET_INFO_BY_BRAND;
          cata_id = get(categoryItems, "selectedCategory.category_id", 1);
          brand_id = get(categoryItems, "selectedGadgetBrand.brand_id", 1);
          break;
        case typeof category:
          valueKey = "category";
          value = get(categoryItems, "selectedCategory.category_id", 1);
          api = GET_INFO_BY_CATEGORY;
          cata_id = get(categoryItems, "selectedCategory.category_id", 1);
          brand_id = get(categoryItems, "selectedGadgetBrand.brand_id", 1);
          break;
        default:
          break;
      }
      const infos = await fetchInformationContentByCategory({
        valueKey,
        value,
        api,
        cata_id,
        brand_id
      });
    //   console.log("infos");
    // console.log(infos);
      setInformations(infos);
    };

    getInformationByCategory();
  }, [category, brand, model]);

  const formatTitle = (text = "") => {
    if (text) {
      return text
        .split("-")
        .map((i) => i[0].toLocaleUpperCase() + i.slice(1))
        .join(" ");
    }
    return text;
  };
  // const ree = "test";

  

  const Infomation = ({ info = {},cata={},location={},index}) => (
    
  // <Typography>{get(info, "title", "")}</Typography>
  <Stack spacing={1}>
  <Typography variant={get(info, "tag", "")} fontWeight="bold" sx={{fontSize:{lg:"1.5vw",xs:"4.3vw",md:"2vw"}}} >
       {index != 0 && get(info, "title", "")}
      </Typography>
      <Typography sx={{fontSize:{lg:"1.2vw",xs:"3.5vw"}}}>
       {get(info, "description", "")}
      </Typography>
      </Stack>
    // <></>
  // <Typography>{Parser(info)}</Typography> 
 
  
  // <Typography>

  //    {reactStringReplace(reactStringReplace(info, '{category}', (match, i) => (
  //         <>{cata}</>
  //       )),'{location}',(match, i) =>( <>{location}</>))}
        
  // </Typography>
  
//  <Typography dangerouslySetInnerHTML={{__html: info}}></Typography>
  
  );
  // console.log("informatio")
  //  console.log(informations)
  const renderAllInfo = () => (
    <Stack spacing={1}>
     
      {informations &&
        informations.map((info, index) => (
          <Infomation info={info} key={index} cata="test1" index={index} />
        ))}
    </Stack>
  );

  const displayInformations = () => {
    if (get(informations, "length", 0) > 1) {
      return (
        <Stack spacing={1}>
          {knowMore ? (
            renderAllInfo()
          ) : (
            <Infomation info={get(informations, "[0]")} cata={get(categoryItems, "selectedCategory.category_title", 1)} location='Bangalore' index={0}/>
          )}
          <Typography
            color="primary"
            onClick={() => setKnowMore(!knowMore)}
            align="center"
            variant="h6"
            sx={{ cursor: "pointer" }}
          >
            Know {knowMore ? "Less" : "More"}
          </Typography>
        </Stack>
      );
    } else if (get(informations, "length", 0)) {
      return <Infomation info={get(informations, "[0]")} cata={get(categoryItems, "selectedCategory.category_title", 1)} location='Bangalore12' index={0} />;
    }
    return <></>;
  };

  return (
    <Box
      sx={{
        padding: "3vh 2vw",
      }}
    >
      <Typography variant={isDesktopOrLaptop ? "h4" : "h5"} sx={{fontSize:{lg:"32px",md:"32px",xs:"24px"}}} fontWeight='bold' align="center">
        {informations.length > 0? informations[0].title : formatTitle(model || brand || category)}
      </Typography>
      <Box sx={{ padding:2}}>{displayInformations()}</Box>
    </Box>
  );
};

export default InformationSection;
