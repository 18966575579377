import { noop } from "lodash";
import { REACT_APP_RAZ_KEY, REACT_APP_RAZ_SECRET } from "../constants";
import store from "../store";
import { updateCartFields } from "../store/slices/CartSlice";
import { resetDateTimeState } from "../store/slices/ConfirmationDateTime";
import { resetScheduleAppointmentState } from "../store/slices/ConfirmationDialog";
import { updatePaymentFields } from "../store/slices/Payment";
import { updateRatingFields } from "../store/slices/Rating";

export const openDefaultPaymentGateway = ({
  amount = 100,
  callback = noop,
  dispatch = noop,
  user = {},
}) => {
  var options = {
    key: REACT_APP_RAZ_KEY,
    key_secret: REACT_APP_RAZ_SECRET,
    amount: amount ? amount * 100 : 100 * 100,
    currency: "INR",
    name: "ERIP",
    description: "for testing purpose",
    handler: function (response) {
      //   dispatch(callback());
      alert("payment succes");
    },
    prefill: {
      name: user.name,
      email: user.email,
      contact: user.contact,
    },
    notes: {
      address: "ERIP, Banglore",
    },
    theme: {
      color: "#3399cc",
    },
  };
  var pay = new window.Razorpay(options);
  pay.open();
};

export const handlePaymentSuccess = () => {
  store.dispatch(updatePaymentFields({ open: false }));
  store.dispatch(updateCartFields({ displayBookNow: false }));
  store.dispatch(resetScheduleAppointmentState());
  store.dispatch(resetDateTimeState());
  store.dispatch(updateRatingFields({ openOrderPlacedThankyou: true }));
};
