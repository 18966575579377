const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const generateDates = (numberOfDates = 7) => {
  let dates = [];
  for (let i = 0; i < numberOfDates; i++) {
    let date = new Date();
    date.setDate(date.getDate() + i);
    dates.push(date);
  }
  return dates;
};

export const isTomorrow = (date = new Date(), isTomorrowCheck = true) => {
  const tomorrow = new Date();
  if (isTomorrowCheck) tomorrow.setDate(tomorrow.getDate() + 1);
  if (tomorrow.toDateString() === date.toDateString()) {
    return true;
  }
  return false;
};

export const getMonth = (date = null) => {
  date = date || new Date();
  return MONTHS[date.getMonth()];
};

export const getDay = (date = null) => {
  date = date || new Date();
  return DAYS_OF_WEEK[date.getDay()];
};

export const nthDay = (value) => {
  let day = value.getDate();
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getFilteredTimeIntervals = (intervals) => {
  return filterIntervals(intervals);
};

const filterIntervals = (intervals) => {
  let currentHour = parseInt(getCurrentTime() + 2);

  const filteredIntervals = intervals.filter((timeslot) => {
    let interval = timeslot.title;
    let startInterval = convertTo24Hour(interval.split("-")[0].trim());
    let endInterval = convertTo24Hour(interval.split("-")[1].trim());
    let startHour = parseInt(startInterval);
    let endHour = parseInt(endInterval);

    // console.log(startHour);
    // console.log("startHour");
    // console.log(currentHour);

    if (currentHour < startHour) {
      return true;
    } else if (currentHour >= startHour && currentHour < endHour) {
      return true;
    } else {
      return false;
    }
  });
  return filteredIntervals;
};

const getCurrentTime = () => {
  return new Date().getHours();
};
const convertTo24Hour = (time) => {
  let [hours, ampm] = time.split(/([APM]+)/);
  hours = (ampm === "PM" && hours !== "12" ? +hours + 12 : +hours) % 24;
  return ("0" + hours).slice(-2);
};

export const getOrderDate = (today = new Date()) => {
  const dateValue =
    today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
  const monthValue =
    today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1;
  return `${today.getFullYear()}-${monthValue}-${dateValue}`;
};
