import { InputLabel, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentFields } from "../../../store/slices/Payment";

const CVV = () => {
  const cvc = useSelector(({ payment }) => payment.cvc);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(updatePaymentFields({ cvc: e.target.value }));
  };

  return (
    <React.Fragment>
      <InputLabel htmlFor="my-input">CVV</InputLabel>
      <TextField
        placeholder="3 digits"
        value={cvc}
        onChange={handleChange}
        variant='filled'
        type="password"
        inputProps={{
          maxLength: 3,
          style:{
            padding: '0.8rem'
          }
        }}
      />
    </React.Fragment>
  );
};

export default CVV;
