import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { useHistory, useNavigate } from "react-router-dom";
import { updateAuthFields } from '../../../store/slices/AuthSlice';
import { TextField, Button, Box } from '@mui/material';
import { useScreenResolution } from '../../../hooks/ResponsiveHook';
import { useLocation } from 'react-router-dom';
import CTAButton from './CTAButton';
const OTP = ({ namemobile, islogin, devicedetails, modeldetails, issuedetails, Category, Branddetails }) => {
  const { isMobile } = useScreenResolution();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = window.location.href;
  const fburl = 'fbclid';
  const gurl = 'gclid';
  const HOST = process.env.REACT_APP_API_URL;
  const [Otp, setOtp] = useState();
  const [source, setsource] = React.useState('');
  const [subsource, setsubsource] = React.useState('');
  const [OTPCheck, setOTPCheck] = useState(true);

  // UTM 
  const location = useLocation();
  const [utmSource, setUtmSource] = useState();
  const [utmMedium, setUtmMedium] = useState();
  const [utmCampaign, setUtmCampaign] = useState();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utm_Source = queryParams.get('utm_source');
    const utm_Medium = queryParams.get('utm_medium');
    const utm_Campaign = queryParams.get('utm_campaign');
    setUtmSource(utm_Source);
    setUtmMedium(utm_Medium);
    setUtmCampaign(utm_Campaign);

  }, [location.search])

  const fetch_source = () => {
    if (url.includes(fburl)) {
      setsource(3);
      setsubsource(27);
    } else if (url.includes(gurl)) {
      setsource(2);
      setsubsource(26);
    } else {
      setsource(1);
      setsubsource(1);
    }

  }

  const CheckOtp = () => {
    if (Otp == "") {
      console.log("OTP error");
    }
    else {
      fetch(HOST + "/api/v1/users/verify_otp_landing", {
        method: 'POST',
        body: JSON.stringify({
          fullname: namemobile.cust_name,
          mobile: namemobile.cust_mobile,
          mobile_otp: Otp,
          purpose: islogin.otpby
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      }).then((resp) => resp.json())
        .then((respJson) => {
          if (respJson.success === false) {
            console.log("CHeckOTP error");
            setOTPCheck(false);
          }
          else {
            localStorage.setItem("modelName", modeldetails.model_name);
            localStorage.setItem("issueName", issuedetails.issue_name);
            localStorage.setItem("fullName", namemobile.cust_name);
            localStorage.setItem("cus_mobile", namemobile.cust_mobile);
            setOTPCheck(true)
            let token = respJson.authorisation.token;
            let bearer = "Bearer " + token;
            localStorage.setItem("token", token);
            dispatch(
              updateAuthFields({
                token: token
              })
            );
            fetch(HOST + "/api/v1/users/my_cart", {
              method: 'PUT',
              headers: {
                Authorization: bearer,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((resp) => resp.json())
              .then((respJson) => {

              })
              .catch((error) => {
                console.log(error);
              });

            fetch(HOST + "/api/v1/users/enquiries", {
              method: "POST",
              body: JSON.stringify({
                flowGroup: 1,
                city: 1,
                sourceType: source,
                subSourceType: subsource,
                category: devicedetails.device_id,
                brand: devicedetails.brand_id,
                model_segment: modeldetails.model_id,
                issues: issuedetails.issue_id,
                coupon: 0,
                utmSource: utmSource,
                utmMedium:utmMedium,
                utmCampaign:utmCampaign
              }),
              headers: {
                Authorization: bearer,
                Accept: "application/json",
                "Content-Type": "application/json"
              }
            })
              .then((resp) => resp.json())
              .then((respJson) => {
                if (respJson.success === false) {
                  console.log("EOOR");
                }
                else {
                  localStorage.setItem(
                    "enquiry_id_encrypted",
                    respJson.enquiry_data.enquiry_id_encrypted
                  );
                  localStorage.setItem(
                    "enquiry_id",
                    respJson.enquiry_data.enquiry_id
                  );
                  localStorage.setItem(
                    "convenience_fee_inc_tax",
                    respJson.enquiry_data.convenience_fee_inc_tax
                  );
                  dispatch(
                    updateAuthFields({
                      enquiry_id_encrypted:
                        respJson.enquiry_data.enquiry_id_encrypted,
                      enquiry_id: respJson.enquiry_data.enquiry_id,
                    })
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });
            fetch(HOST + "/api/v1/users/my_cart", {
              method: "POST",
              body: JSON.stringify({
                issueId: issuedetails.issue_id
              }),
              headers: {
                Authorization: bearer,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((response) => response.json())
              .then((responseJson) => {
                navigate("/location");
                // navigate("/temp_conform");
              })
              .catch((error) => {
                console.log(error);
              })
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  const [showCounter, setShowCounter] = useState(true);
  const [resndmsg, setresnemsg] = useState('');
  const [counter, setCounter] = useState(30);
  const ResendOTP = () => {
    setresnemsg('Resend otp sucessfully');
    setTimeout(() => {
      setresnemsg('');
    }, 5000);

    setCounter(30);
    fetch(HOST + "/api/v1/users/resend_otp_landing", {
      method: "POST",
      body: JSON.stringify({
        mobile: namemobile.cust_mobile,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === false) {
          console.log("Resend OTP error")
        }
        else {
          console.log("OPT sent ");
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  useEffect(() => {
    if (isMobile) window.scrollTo(0, 500);
    fetch_source();
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  return (
    <>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "0.2rem"
      }} >
        <TextField focused error={!OTPCheck} id="outlined-basic" label={OTPCheck ? "OTP Sent" : "Incorrect OTP"} variant="outlined" onChange={(e) => setOtp(e.target.value)} />
        {counter === 0 && <Button variant='contained' sx={{ width: isMobile ? "100px" : "150px", textTransform: "none", fontSize: isMobile ? "12px" : "16px", background: "#0E62CB" }} onClick={() => ResendOTP()} >Resend OTP</Button>}
        {counter !== 0 && <Button variant='contained' sx={{ width: isMobile ? "100px" : "150px", textTransform: "none", fontSize: isMobile ? "12px" : "16px", background: "#0E62CB" }} >Resend {counter}</Button>}
      </Box>
      {resndmsg}
      <div onClick={() => CheckOtp()}><CTAButton /></div>
      {/* <Button variant='contained' onClick={()=>CheckOtp()} fullWidth sx={{height:"55px", fontSize:"18px", textTransform:"none", background:"#0E62CB"}} >Check price for free</Button> */}
    </>
  )
}

export default OTP