import { Step, styled } from "@mui/material";

export const StyledStepComponent = styled(Step)((props) => ({
  "& .MuiStepLabel-root .Mui-completed": {
    color: props.backgroundColor, // circle color (COMPLETED)
  },
  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
    color: props.txtColor, // Just text label (COMPLETED)
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: props.backgroundColor, // circle color (ACTIVE)
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: props.txtColor, // Just text label (ACTIVE)
  },
  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
    fill: props.txtColor || "#fff", // circle's number (ACTIVE)
  },
}));
