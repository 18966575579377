import React from 'react'
import { Box,Typography } from '@mui/material'
import quick from '../assets/Quick.svg'
import warran from '../assets/warrn.svg'
import expert from '../assets/expert.svg'
import certified from '../assets/tool.svg'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'
const Accordian = () => {
  const {isMobile} = useScreenResolution();
  return (
    <Box sx={{
      padding:isMobile?"2rem 1rem":"2rem",
      backgroundColor:isMobile?"#FFF0E2":"none",
      marginTop:"2rem",
    }}>
      <Typography sx={{fontSize:isMobile?"22px":"32px", fontWeight:"bold"}}>Service Highlight</Typography>
      <br/>
      <Box sx={{display:"flex",flexDirection:"column",gap:"20px"}}>
        <Box sx={{
          display:"flex",
          alignItems:"center",
          gap:"0.5rem"
        }}>
          <div style={{width:"35px",height:"35px", minWidth:"35px"}} >
          <img src={quick} alt='quick and convenient' width={"100%"}/>
          </div>
          <Typography sx={{fontSize:isMobile?"18px":"22px"}}>Most services are done in 30 minutes or less</Typography>
        </Box>
        <Box sx={{
          display:"flex",
          alignItems:"center",
          gap:"0.5rem"
        }}>
          <div style={{width:"35px",height:"35px", minWidth:"35px"}} >
          <img src={certified} alt='OEM' width={"100%"}/>
          </div>
          <Typography sx={{fontSize:isMobile?"18px":"22px"}}>Manufacturer/OEM parts and processes used in almost all services</Typography>
        </Box>
        <Box sx={{
          display:"flex",
          alignItems:"center",
          gap:"0.5rem"
        }}>
          <div style={{width:"35px",height:"35px", minWidth:"35px"}} >
          <img src={expert} alt='expert'/>
          </div>
          <Typography sx={{fontSize:isMobile?"18px":"22px"}}>Well Trained professional with 3+ years experience will come at your doorstep</Typography>
        </Box>
        <Box sx={{
          display:"flex",
          alignItems:"center",
          gap:"0.5rem"
        }}>
          <div style={{width:"35px",height:"35px", minWidth:"35px"}} >
          <img src={warran} alt='Warranty' />
          </div>
          <Typography sx={{fontSize:isMobile?"18px":"22px"}}>Up to 1-year warranty on services </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Accordian