import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updatePaymentFields } from "../../store/slices/Payment";

const WalletComponent = ({
  logo = "",
  label = "",
  value = "",
  selectedValue = "",
}) => {
  const dispatch = useDispatch();  

  const handleClick = () => {
    dispatch(
      updatePaymentFields({
        selectedWalletType: value,
      })
    );
  };

  const getIcon = () => {
    return selectedValue === value ? (
      <CheckCircleIcon color="primary" />
    ) : (
      <RadioButtonUncheckedIcon />
    );
  };
  return (
    <Box
      sx={{
        border: "1px solid #676767",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: 1,
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Box sx={{ alignItems: "center", display: "flex" }}>
        {/* <img
          src={logo}
          alt={logo}
          style={{
            width: "49.5px",
            height: "40px",
          }}
        /> */}
        <Typography>{label}</Typography>
      </Box>

      {getIcon()}
    </Box>
  );
};

export default WalletComponent;
