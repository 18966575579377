import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    token:''
  },
  reducers: {
    updateAuthFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateAuthFields } = AuthSlice.actions;

export default AuthSlice.reducer;
