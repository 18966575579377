import { Box, Button, Typography } from "@mui/material";
import { noop } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import CartCouponComponent from "../../atoms/cart/CouponComponent";
import CartComponent from "../../molecules/checkout/CartComponent";
import { updateConfirmationDialogFields } from "../../store/slices/ConfirmationDialog";

import PaymentButtons from "../../molecules/checkout/PaymentButtons";
const PaymentComponent = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      updateConfirmationDialogFields({
        dialogTitle: "Payment",

        showStepper: true,
        activeStep: 2,
      })
    );
  }, []);

  return (
    <Box
      sx={{
        minHeight: "50vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div>
        <CartCouponComponent />
      </div>
      <div>
        <CartComponent />
      </div>
      <br /> <PaymentButtons />
    </Box>
  );
};

export default PaymentComponent;
