import { Box, Stack, Typography } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { verifyCoupon } from "../../services/cart";
import { updateCartFields } from "../../store/slices/CartSlice";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AllCouponComponent = ({ coupon = {} }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const dispatch = useDispatch();
  const [Dispmessage,setDispmessage] = React.useState("");
  const applyCoupon = async () => {
    const { currentAmount, couponVerified, message } = await verifyCoupon(
      coupon
    );

    if (couponVerified) {
      dispatch(
        updateCartFields({
          appliedCoupon: coupon,
          totalAmount: currentAmount,
          discountedAmount: parseFloat(get(coupon, "coupon_amount", 0)),
          openCouponsDialog: false,
        })
      );
    } else {
      handleClickOpen();
      setDispmessage(message);
      console.log(message);
    }
  };

  let couponBoxStyles = {
    borderBottom: "1px solid #D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1px",
    height: "10vh",
  };



  return (
    <Box>
      <Box sx={couponBoxStyles}>
        <Stack>
          <Typography sx={{ color: "#048A81",fontWeight:"bold" }}>
            {get(coupon, "coupon_code").toUpperCase()}
          </Typography>
          <Typography sx={{ color: "#676767" }} variant="subtitle2">
            {get(coupon, "coupon_discription")[0].toUpperCase()}{get(coupon, "coupon_discription").slice(1)}
          </Typography>
        </Stack>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography color="red" fontWeight={'bold'} >
            {Dispmessage}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>


        <Typography
          sx={{ cursor: "pointer" }}
          color="primary"
          onClick={applyCoupon}
        >
          Apply
        </Typography>
      </Box>
    </Box>
  );
};
export default AllCouponComponent;
