import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { PAYMENT_TYPE } from "../../constants";
import { makePayment } from "../../services/payment";

const UpiPayment = ({ razorpay = {} }) => {
  const [vpa, setVpa] = React.useState("");

  // const handleVerifyUPI = async () => {
  //   const verificationResponse = await verifyUpiID(vpa);
  // };

  const handleChange = (e) => {
    setVpa(e.target.value);
  };

  const makeUpiPayment = () => {
    makePayment({
      razorpay: razorpay,
      type: PAYMENT_TYPE.UPI,
      metaData: { vpa: vpa },
    });
  };

  return (
    <Box
      sx={{
        height: "80%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Grid container direction="row" spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography align="left" variant="h6">
            UPI ID
          </Typography>
          <Typography align="left" variant="body2">
            Google Pay, PhonePe, BHIM & more
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            label="Enter UPI ID here"
            fullWidth
            variant="filled"
            value={vpa}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  // onClick={handleVerifyUPI}
                  sx={{ cursor: "pointer" }}
                  position="end"
                >
                  <Typography color="primary" fontWeight={700}>
                    Verify
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Button fullWidth variant="contained" onClick={makeUpiPayment}>
            Pay now
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpiPayment;
