import React,{useState,useEffect} from 'react'
import TopBar from './components/TopBar'
import ConformationHero from './components/ConformationHero'
import Testimonials from './components/Testimonials'
import Footer from './components/Footer'
import Accordian from './components/Accordian'
const Conformation = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return (
    <div className="mainContainer">
        <div className="wrapper">
          <TopBar/>
          <ConformationHero/>
          <Accordian/>
          <Testimonials/>
        </div>
          <Footer/>
    </div>
  )
}

export default Conformation