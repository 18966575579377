import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography } from "@mui/material";
import React from "react";
import { PAYMENT_TYPE } from "../../constants";
import { makePayment } from "../../services/payment";

const PaylaterComponent = ({
  razorpay = {},
  logo = "",
  label = "",
  code = "",
}) => {
  const makePaylaterPayment = () => {
    if (!code) alert("Invalid Payment option");
    makePayment({
      razorpay: razorpay,
      type: PAYMENT_TYPE.PAYLATER,
      metaData: { provider: code },
    });
  };

  return (
    <Box
      sx={{
        padding: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        width: {xs:'100%'},
        border: "1px solid #0E62CB",
        borderRadius: "5px",
        cursor: "pointer",
        height: "9vh",
      }}
      onClick={makePaylaterPayment}
    >
      <Box sx={{ alignItems: "center", display: "flex" }}>
        {logo && (
          <img
            src={logo}
            alt={logo}
            style={{
              width: "49.5px",
              height: "40px",
            }}
          />
        )}
        <Typography>{label}</Typography>
      </Box>
      <ChevronRightIcon />
    </Box>
  );
};

export default PaylaterComponent;
