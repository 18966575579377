import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Button } from "@mui/material";
import React from "react";
import { resendOtp } from "../../services/user-profile";

const OtpTimer = () => {
  const [counter, setCounter] = React.useState(30);

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleResendOtp = async () => {
    const resendResponse = await resendOtp();
    console.log(resendResponse);
    setCounter(30);
  };

  return counter > 0 ? (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <AccessTimeIcon fontSize="small" color="textsecondary" /> &nbsp; 00:
      {counter}
    </Box>
  ) : (
    <Button onClick={handleResendOtp}>Resend</Button>
  );
};

export default OtpTimer;
