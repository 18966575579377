import axios from "axios";

const UNAUTHORIZED_APIS = [
  "verify_otp_login",
  "resend_otp_login",
  "send_otp_login",
];

const hostEndsWith = (host, ends) => {
  let value = false;
  value = ends.some((element) => {
    return host.endsWith(element);
  });
  console.log(value);
  
  return value;
};

axios.interceptors.request.use(
  (request) => {
    if (
      hostEndsWith(request.url, UNAUTHORIZED_APIS) ||
      (request.url.includes("/cms/") && !request.url.includes("/verify_coupon"))
    ) {
      return request;
    }
    const token = localStorage.getItem("token");
    request.headers["Authorization"] = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axios;
