import React from 'react'
import TopBar from './components/TopBar'
import Testimonials from './components/Testimonials'
import Footer from './components/Footer'
import ConformationHero2 from './components/ConformationHero2'
import Accordian from './components/Accordian'
const Conformation2 = () => {
    return (
        <div className="mainContainer">
            <div className="wrapper">
                <TopBar />
                <ConformationHero2/>
                <Accordian/>
                <Testimonials />
            </div>
                <Footer />
        </div>
    )
}

export default Conformation2