import { createSlice } from "@reduxjs/toolkit";

export const PaymentSlice = createSlice({
  name: "payment",
  initialState: {
    open: false,
    fromProfile: false,
  },
  reducers: {
    updatePaymentFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updatePaymentFields } = PaymentSlice.actions;

export default PaymentSlice.reducer;
