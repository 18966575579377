  import React from "react";
  import { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const QuestionnaireCheckbox = (props) => {
  const {Answer,setAnswer,activeStep,idx} = props;
  const dispatch = useDispatch();
  const handleChange = (event) => {
    props.setSelectedAnswers(props.value);
    const newAnswer = [...Answer];
    newAnswer[activeStep] = idx
    setAnswer(newAnswer);
  };
  return (
    <Box
      sx={{
        background: "#E7F1FD",
        border: "1px solid #0E62CB",
        borderRadius: "5px",
        paddingLeft: "8px",
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox {...props} checked={Answer[activeStep] == idx} onClick={handleChange} />
          }
          label={props.label}
        />
        
      </FormGroup>
    </Box>
  );
};

export default QuestionnaireCheckbox;
