//export const TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX21vYmlsZSI6Ijk2MjA3MDc4MjgiLCJ0b2tlbl9jcmVhdGVkX2F0IjoxNjY4NjA1NTg5LCJ0b2tlbl9leHBpcmVzX2F0IjoxNjcwMzMzNTg5fQ.zYsY8ZPZPOhlg0JJquuG3TVwzGDS2Nlm3i8hGIONXPg`;
// const HOST = `http://43.204.87.153`;
// const HOST = `https://bee.nutshell.repair`;
const HOST =process.env.REACT_APP_API_URL;
const API = `/api/v1`;
const CONTEXT_PATH = `${HOST}${API}`;
// alert(process.env.REACT_APP_API_URL);
// console.log("process.env");
// console.log(process.env);
// Schedule Appointment
export const GET_TIMESLOTS = `${CONTEXT_PATH}/timeslots`;
export const GET_ADDRESS_TYPES = `${CONTEXT_PATH}/address_types`;
export const UPDATE_ADDRESS = `${CONTEXT_PATH}/users/my_addresses`;

// Orders
export const GET_MY_ORDERS = `${CONTEXT_PATH}/users/orders`;
export const UPDATE_QUOTATION = `${CONTEXT_PATH}/users/orders_quotation`;
export const UPDATE_ORDER_QUOTATION = `${CONTEXT_PATH}/users/orders_quotation`;
export const CREATE_ENQUIRY = `${CONTEXT_PATH}/users/enquiries`;
export const RESCHEDULE_ORDER = `${CONTEXT_PATH}/users/orders_reschedule`;

//User Profile
export const GET_USER_PROFILE = `${CONTEXT_PATH}/users/my_profile`;
export const LOGOUT_USER = `${CONTEXT_PATH}/users/logout`;
export const SEND_OTP = `${CONTEXT_PATH}/users/send_otp_login`;
export const VERIFY_OTP = `${CONTEXT_PATH}/users/verify_otp_login`;
export const RESEND_OTP = `${CONTEXT_PATH}/users/resend_otp_login`;
export const USER_LOGOUT = `${CONTEXT_PATH}/users/logout`;
export const GET_CANCEL_REASONS_USER = `${CONTEXT_PATH}/order_cancel_reasons_user`;
export const SUBMIT_ORDER_CANCEL_REASON = `${CONTEXT_PATH}/users/orders_cancel`;

//Payment
export const PAYMENT_CREATE_ORDER = `http://3.7.189.173:5000/createOrder`;
export const VALIDATE_UPI_ID = `http://3.7.189.173:5000/validateUpi`;
//Review & Ratings
export const GET_ORDER_REVIEW_QNA = `${CONTEXT_PATH}/users/order_review_qna`;
export const SUBMIT_ORDER_REVIEW_QNA = `${CONTEXT_PATH}/users/orders_review`;
export const GET_FEEDBACK_QNA = `${CONTEXT_PATH}/users/order_feedback_qna`;
export const SUBMIT_FEEDBACK = `${CONTEXT_PATH}/users/orders_feedback`;
//Homepage
export const GET_APPLE_PRODUCTS_MENU = `${CONTEXT_PATH}/cms/top_apple_products`;
export const GET_BRANDS_MENU = `${CONTEXT_PATH}/cms/top_brands`;
export const GET_MODELS_MENU = `${CONTEXT_PATH}/cms/top_models`;
export const GET_ISSUES_MENU = `${CONTEXT_PATH}/cms/top_issues`;
export const GET_MOBILE_BRANDS = `${CONTEXT_PATH}/brands_by_category?category=1`;
// export const GET_CAROUSEL_MENUS = `${CONTEXT_PATH}/cms/hero_section_home`;
export const GET_CAROUSEL_MENUS = `${CONTEXT_PATH}/cms/hero_image_home`;
export const GET_CATEGORY_ITEMS = `${CONTEXT_PATH}/cms/hero_section_by_category`;
export const GET_OFFER_STICKERS = `${CONTEXT_PATH}/cms/offers_section_home`;
export const GET_CATEGORY_BY_CITIES = `${CONTEXT_PATH}/categories_by_cities`;
export const GET_TESTIMONIALS = `${CONTEXT_PATH}/cms/testimonials`;
export const GET_BLOGS = `${CONTEXT_PATH}/cms/latest_blogs`;
export const GET_EXCLUSIVE_APPLE_SERVICES = `${CONTEXT_PATH}/cms/exclusive_apple_services`;
// export const GET_FAQ_BY_CATEGORY = `${CONTEXT_PATH}/cms/faqs_section_by_category`;
export const GET_FAQ_BY_CATEGORY = `${CONTEXT_PATH}/cms/faqs_section_category`;

export const GET_FAQ_BY_BRAND = `${CONTEXT_PATH}/cms/faqs_section_brand`;

export const GET_FAQ_BY_MODEL = `${CONTEXT_PATH}/cms/faqs_section_model`;
// Home Appliances
export const GET_SEGMENTS_BY_CATEGORY = `${CONTEXT_PATH}/segments_by_category`;
// Personal Gadgets
export const GET_BRANDS_BY_CATEGORY = `${CONTEXT_PATH}/brands_by_category`;
export const GET_MODELS_BY_CATEGORY = `${CONTEXT_PATH}/models_by_category_and_brand`;
//Category
export const GET_ISSUES_GADGETS = `${CONTEXT_PATH}/issues_by_models`;
export const GET_ISSUES_SEGMENTS = `${CONTEXT_PATH}/issues_by_segments`;
export const GET_ISSUES_GADGETS_DETAIL = `${CONTEXT_PATH}/issues_by_models_detail`;
export const GET_ISSUES_SEGMENTS_DETAIL = `${CONTEXT_PATH}/issues_by_segments_detail`;
// export const GET_INFO_BY_CATEGORY = `${CONTEXT_PATH}/cms/info_section_by_category`;
export const GET_INFO_BY_CATEGORY = `${CONTEXT_PATH}/cms/info_section_category`;
// export const GET_INFO_BY_BRAND = `${CONTEXT_PATH}/cms/info_section_by_brand`;
export const GET_INFO_BY_BRAND = `${CONTEXT_PATH}/cms/info_section_brand`;
// export const GET_INFO_BY_MODEL = `${CONTEXT_PATH}/cms/info_section_by_model`;
export const GET_INFO_BY_MODEL = `${CONTEXT_PATH}/cms/info_section_model`;
//Cart
export const UPDATE_CART = `${CONTEXT_PATH}/users/my_cart`;
export const GET_COUPONS = `${CONTEXT_PATH}/cms/coupons_by_cc`;
export const GET_VERIFY_COUPONS = `${CONTEXT_PATH}/cms/verify_coupon`;
// Nav Search
export const GET_NAV_SEARCH = `${CONTEXT_PATH}/nav_search`;
// Offers
export const GET_ALL_OFFERS = `${CONTEXT_PATH}/cms/offers`;
// Request Callback
export const GET_REQUEST_CALLBACK = `${CONTEXT_PATH}/cms/contact_us`;

// checkpincode
export const CHECKPINCODE = `${CONTEXT_PATH}/serviceable_pincode`;

export const ORDERS_RATE_PARTNAR = `${CONTEXT_PATH}/users/orders_rate_partner`;

export const GET_MY_ORDERS_RECEPT = `${CONTEXT_PATH}/users/order_receipt`;

// export const GET_HERO_SECTION_BY_CATEGORY = `${CONTEXT_PATH}/cms/hero_section_by_category`;
export const GET_HERO_SECTION_BY_CATEGORY = `${CONTEXT_PATH}/cms/hero_image_category`;

export const GET_HERO_SECTION_CONTENT_BY_CATEGORY = `${CONTEXT_PATH}/cms/hero_content_category`;
export const GET_HERO_SECTION_CONTENT_BY_BRAND = `${CONTEXT_PATH}/cms/hero_content_brand`;

export const GET_HERO_SECTION_CONTENT_BY_MODEL = `${CONTEXT_PATH}/cms/hero_content_model`;

export const ORDER_ONLINE_PAYMNET = `${CONTEXT_PATH}/users/orders_online_payment`;

export const ENQUIRY_GT = `${CONTEXT_PATH}/users/enquiries_gt`;


// By Pass Login API
export const BYPASS_LOGIN_SSR = `${CONTEXT_PATH}/users/l2m`