import React from 'react'
import '../css/CTAButton.css'
const CTAButton = ({disabled}) => {
  return (
    <div>
        <button disabled={disabled}  class="btn-alt" style={{textTransform:"capitalize"}} >Check price for free</button>
    </div>
  )
}

export default CTAButton