import React, { useEffect, useState } from 'react'
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
import '../css/Coupon.css'
import { useScreenResolution } from '../../../hooks/ResponsiveHook';
const CouponDilog = ({handlernew, total,coupon_set,showModal_coupon}) => {

   
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const {isMobile} = useScreenResolution();

    const [couponlist, setcouponlist] = useState([]);
    console.log(couponlist);
    let token = localStorage.getItem("token");
    const HOST =process.env.REACT_APP_API_URL;

    const fetch_all_coupon = async () =>{

        fetch(HOST+'/api/v1/cms/coupons_by_cc?city=1&category=1', {

            method: 'GET',
            headers: {
               
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
    
        }).then((response) => response.json())
        .then((responseJson) => {;
            // console.log(responseJson)
            if(responseJson.success===false){
               
                setcouponlist([])
    
            }else{
                
                setcouponlist(responseJson.data);
            }
           
            // setLoading(false);
          //  console.log(responseJson.data)
           
    
        }).catch((error) => {
            //Error 
            console.error(error);
        });



    }

    useEffect(() => {
        fetch_all_coupon();
        
       
      },[]);

      const check_coupon = async (id,title,amount,description)=>{
        //   alert(id);
        // alert(total);
        // alert(token);
       
        let bearer = "Bearer " + token;

        fetch(HOST+'/api/v1/cms/verify_coupon?city=1&category=1&coupon='+id+'&amount='+total, {

            method: 'GET',
            headers: {
                Authorization: bearer,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
    
        }).then((response) => response.json())
        .then((responseJson) => {;
            // console.log(responseJson)
            // alert(responseJson.success);
            if(responseJson.success===false){
                alert(responseJson.message);
    
            }else{


                const nextFormState = {
                    
                    code: title,
                    c_amount: amount,
                    c_description:description
                    
        
                    // mobile_error:'',
                  };
                  coupon_set(nextFormState);
                  handlernew(false);
                
                  localStorage.setItem( "couponcode",title );
                  localStorage.setItem( "amount", amount );
                  localStorage.setItem( "couponid",id );
                  localStorage.setItem( "coupondesc",description );

                
            }
           
            // setLoading(false);
          //  console.log(responseJson.data)
           
    
        }).catch((error) => {
            //Error 
            console.error(error);
        });
      }
  return (
    <Dialog
    fullScreen={fullScreen}
    maxWidth="md"
    open={showModal_coupon}
    sx={{
      marginTop: isMobile?"50%":"0",
      Padding: "0"
    }}
    PaperProps={{
      sx:{
        borderRadius:{lg:"8px",md:"8px"},
        borderTopLeftRadius: isMobile?"1rem":"",
        borderTopRightRadius: isMobile?"1rem":"",
      }
    }}
  >
    <DialogTitle>
    <button
              type="button"
              className="btn-close mysubtitlenew"
              data-bs-dismiss="modal"
              aria-label="Close"
             onClick={() => handlernew(false)}
            />
    </DialogTitle>
    <DialogContent>
        <Grid width={isMobile?"100%":"30vw"} marginTop={3} marginBottom={3}> 
            <Typography variant='h6' fontWeight={'bold'} align="center">Available Coupons</Typography>
            <Box display={"flex"} flexDirection="column" gap="1.2rem" marginTop={3} marginBottom={3}>
                {couponlist.map((item,index)=>(
                    <Box>
                        <Box sx={{display:"flex", justifyContent:"space-between",width:"100%"}}>
                            <Typography sx={{ color: "#048A81",fontWeight:"bold" }}>
                            {item.coupon_title.toUpperCase()}
                            </Typography>
                        <Typography
                            sx={{ cursor: "pointer" }}
                            color="primary"
                            onClick={()=>check_coupon(item.coupon_id,item.coupon_title,item.coupon_amount,item.coupon_discription)}
                        >
                            Apply
                        </Typography>
                        </Box>
                            <Typography sx={{ color: "#676767" }} variant="subtitle2">
                                {item.coupon_discription}
                            </Typography>
                            <hr/>
                    </Box>
                ))}
            </Box>
        </Grid>
    </DialogContent>

  </Dialog>
  )
}


export default function Coupon({handlernew, total,coupon_set, showModal_coupon}) {

    const [couponlist, setcouponlist] = useState([]);

    let token = localStorage.getItem("token");
    const HOST =process.env.REACT_APP_API_URL;

    const fetch_all_coupon = async () =>{

        fetch(HOST+'/api/v1/cms/coupons_by_cc?city=1&category=1', {

            method: 'GET',
            headers: {
               
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
    
        }).then((response) => response.json())
        .then((responseJson) => {;
            // console.log(responseJson)
            if(responseJson.success===false){
               
                setcouponlist([])
    
            }else{
                
                setcouponlist(responseJson.data);
            }
           
            // setLoading(false);
          //  console.log(responseJson.data)
           
    
        }).catch((error) => {
            //Error 
            console.error(error);
        });



    }

    useEffect(() => {
        fetch_all_coupon();
        
       
      },[]);

      const check_coupon = async (id,title,amount)=>{
        //   alert(id);
        // alert(total);
        // alert(token);
       
        let bearer = "Bearer " + token;

        fetch(HOST+'/api/v1/cms/verify_coupon?city=1&category=1&coupon='+id+'&amount='+total, {

            method: 'GET',
            headers: {
                Authorization: bearer,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
    
        }).then((response) => response.json())
        .then((responseJson) => {;
            // console.log(responseJson)
            // alert(responseJson.success);
            if(responseJson.success===false){
                alert(responseJson.message);
           
            }else{


                const nextFormState = {
                    
                    code: title,
                    c_amount: amount,
                    
        
                    // mobile_error:'',
                  };
                  coupon_set(nextFormState);
                  handlernew(false);
                
                  localStorage.setItem( "couponcode",title );
                  localStorage.setItem( "amount", amount );
                  localStorage.setItem( "couponid",id );

                
            }
           
            // setLoading(false);
          //  console.log(responseJson.data)
           
    
        }).catch((error) => {
            //Error 
            console.error(error);
        });
      }
      return <CouponDilog handlernew={handlernew} total={total} coupon_set = {coupon_set} showModal_coupon={showModal_coupon}/>
    return (

        <div className="container mycontt">
        <div className="modal-dialog">

        <div className="modal-content">
          <div className="modal-header border-0">

          <button
              type="button"
              className="btn-close mysubtitlenew"
              data-bs-dismiss="modal"
              aria-label="Close"
             onClick={() => handlernew(false)}
            />


              </div>


              <div className="modal-body mymodelbody">
              <div className="mb-3 p-2 mypadding_new">
                <p className="ng-binding mysubtitle mysubtitlenew">
                Available coupons
                </p>

                {couponlist.map((item) => (
<div>
<div className='row'>
<div className='col-md-10 col-10'>
    <p className='mucoupnp'>{item.coupon_title}</p>
    <p className='mynewcpn'>Save {item.coupon_amount} on your first order. Maximum discount ₹100.  Offer valid till 30th August</p>
</div>
<div className='col-md-2 myapply mucoupnp col-2' onClick={()=>check_coupon(item.coupon_id,item.coupon_title,item.coupon_amount)}>APPLY</div>


</div>
<hr className='myhr' />
</div>

))}
               
              
              

              

              

              

              </div>


                  </div>
              </div>


            </div>
            </div>


        );
}