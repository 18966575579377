import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Box } from '@mui/material';
import { useScreenResolution } from '../../../hooks/ResponsiveHook';
const accordArray = [
    {
        lable: "Do you offer onsite service?",
        content: "Yes. We do. Our onsite technicians will come to your doorstep and service your device within 15 minutes.."
    },
    {
        lable: "How does your pickup and delivery service work?",
        content: "Our device pickup and delivery service is centred on issues that need more than 15 minutes of service. complex service issues require pickup and drop. Additionally, the timely pickup and drop service is free of cost."
    },
    {
        lable: "Do you provide a warranty for services?",
        content: "It depends on the product, issue, model, and the type of services you would like to avail of. ."
    },
    {
        lable: "What is the mode of payment you accept for services?",
        content: "We accept various modes like cash, Debit & Credit cards, wallet or UPI"
    }
]
const FAQ = () => {
    const {isMobile} = useScreenResolution();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            <Box width={"92%"} sx={{margin:"0 auto",marginTop:"4rem"}}>
                <Typography variant='h3' align='center' style={{fontSize:isMobile?"30px":"40px",fontWeight:"bold"}}>FAQ's</Typography>
                <br/>
                {accordArray.map((item, index) => (
                    <Accordion key={index} expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography style={{ fontSize: "20px", fontWeight: '400' }}>
                                {item.lable}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ fontSize: "18px" }}>
                                {item.content}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>))}
            </Box>
        </div>
    )
}

export default FAQ