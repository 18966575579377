import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCancelReasons, submitCancelReason } from "../../services/orders";
import { updateOrderFields } from "../../store/slices/OrderSlice";

const OrderCancelDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const [cancelReasons, setCancelReasons] = React.useState([]);
  const [selectedReasons, setSelectedReasons] = React.useState({});
  const [description, updateDescription] = React.useState("");

  const handleChange = (e, cancelReason) => {
    e.target.checked
      ? setSelectedReasons(cancelReason)
      : setSelectedReasons({});
  };

  const openCancelDialog = useSelector(
    ({ orders }) => orders.openOrderCancelDialog
  );

  const handleClose = () => {
    dispatch(
      updateOrderFields({
        openOrderCancelDialog: false,
      })
    );
  };

  React.useEffect(() => {
    const getCancelReasons = async () => {
      const reasons = await fetchCancelReasons();
      if (reasons && reasons.length) {
        setCancelReasons(reasons);
      }
      console.log(reasons);
    };

    if (openCancelDialog) {
      getCancelReasons();
    }

    return () => setSelectedReasons({});
  }, [openCancelDialog]);

  const submitOrderCancelReason = async () => {
    const response = await submitCancelReason({
      cancelOrderReason: selectedReasons["order_cancel_reason_id"],
      cancelOrderDescription: description,
    });

    if (!isEmpty(reasons)) {
      // alert("Cancelled");
      handleClose();
      window.location.reload();
    }
  };
  const generateCancelReasons = () => {
    if (cancelReasons && cancelReasons.length) {
      return cancelReasons.map((reason, index) => (
        <FormGroup key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  selectedReasons["order_cancel_reason_title"] ===
                  reason["order_cancel_reason_title"]
                }
                onChange={(e) => handleChange(e, reason)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={reason["order_cancel_reason_title"]}
          />
        </FormGroup>
      ));
    }
    return <></>;
  };

  const reasons = generateCancelReasons();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={openCancelDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="md"
      fullWidth
      sx={{
        marginTop: { xs: "50%", md: "50%", lg: "0%" },
        height: { lg: "100vh" },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "left" }}>
          <Box sx={{ width: "5%" }}>
            <KeyboardBackspaceIcon sx={{ cursor:'pointer'}} onClick={handleClose} />
          </Box>
          <Box sx={{ width: "90%" }}>
            <Typography fontWeight={700} variant="h6" align="center">
              CANCEL
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ width: "100%", height: "100%",paddingLeft:"2.2rem" }}>
        <Stack sx={{ width: "100%", height: "100%" }}>
          <Typography align="center">
            Please give a reason for cancelling
          </Typography>
          <React.Fragment>{reasons}</React.Fragment>
          <TextField
            onChange={(e) => updateDescription(e.target.value)}
            value={description}
            placeholder="Enter reason here"
            multiline
            rows={3}
            style={{
              width: "100%"
            }}
          />
          <br />
          <Button
            disabled={isEmpty(selectedReasons)}
            fullWidth
            variant="contained"
            onClick={submitOrderCancelReason}
            style={{
              width: "100%"
            }}
          >
            Submit
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default OrderCancelDialog;
