import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartCouponComponent from "../../atoms/cart/CouponComponent";
import DisplayComponent from "../../atoms/cart/DisplayComponent";
import Discount from "../../molecules/cart/Discount";
import { createEnquiry } from "../../services/orders";
import {clearCart} from "../../services/cart";
import { updateCartFields,updateCartItems_new } from "../../store/slices/CartSlice";
import { updateConfirmationDialogFields } from "../../store/slices/ConfirmationDialog";
import { updateOrderFields } from "../../store/slices/OrderSlice";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import emptycart from "../../assets/emptycart.png"
import { useLocation } from "react-router-dom";
const Cart = () => {

  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const openCartsDialog = useSelector(({ cart }) => cart.openCartsDialog);
  const enquiryId = useSelector(({ orders }) => orders.enquiryId);

  const categoryItems = useSelector(({ category }) => category);

  let cata_name = get(categoryItems, "selectedCategory.category_title", '');
  let brand_name = get(categoryItems, "selectedGadgetBrand.brand_title", '');
  let model_name = get(categoryItems, "selectedModel.model_title", '');
  // console.log(cata_name);
  const [catogryname, setcatogryname] = React.useState(cata_name);
  const [brandname, setbrandname] = React.useState(brand_name);
  const [modelname, setmodelname] = React.useState(model_name);

  const [utmSource,setUtmSource] = useState();
  const [utmMedium ,setUtmMedium ] = useState();
  const [utmCampaign ,setUtmCampaign ] = useState();
  // console.log(categoryItems);
  // const convenience_fee_inc_tax = useSelector(({ orders }) => orders.convenience_fee_inc_tax);
  // const items = useSelector(({ cart }) => cart.items);
  const items = useSelector(({ cart }) => cart.dbitems);
  const cart_cata = useSelector(({ cart }) => cart.cart_category_title);
  const cart_brand = useSelector(({ cart }) => cart.cart_brand_title);
  const cart_model = useSelector(({ cart }) => cart.cart_model_segment_title);
  
  // console.log('items_1212');
  // console.log(items_nqw);
  const {isTabletOrMobile,isMobile} = useScreenResolution();
  const handleClose = () => {
    dispatch(
      updateCartFields({
        openCartsDialog: false,
      })
    );
  };

  const checkout = async () => {
    // alert(convenience_fee_inc_tax);
    if (!enquiryId) {
      const enquiry = await createEnquiry(utmSource,utmMedium,utmCampaign);
      if (!enquiry) {
        // alert("Issue in creating enquiry");
        return;
      }
      dispatch(
        updateOrderFields({
          enquiryId: get(enquiry, "enquiry_id", ""),
          enquiry_id_encrypted: get(enquiry, "enquiry_id_encrypted", ""),
          convenience_fee_inc_tax:get(enquiry, "convenience_fee_inc_tax", ""),
        })
      );
    }
    dispatch(
      updateCartFields({
        openCartsDialog: false,
      })
    );
    dispatch(
      updateConfirmationDialogFields({
        open: true,
      })
    );
  };

  const checkCartEmpty = () => {
    return get(items, "length", 0) === 0;
  };
  const clear_cart = async() => {
    
     const response = await clearCart();
    if (response) {
      dispatch(
        updateCartFields({
          dbitems:[],
          cart_model:'',
          cart_category_title:{},
          cart_brand_title:{},
          cart_model_segment_title:{},
        })
      );
      dispatch(
        updateCartItems_new({
          issue:[],
          add:true
          
        })
      );

    }
    // if (!issue) return;
    // const response = await updateItemsToCart(get(issue, "issue_id"));
    // if (response) {
    //   dispatch(
    //     updateCartItems({
    //       issue,
    //       add,
    //     })
    //   );
    // }
    window.location.reload();
  }
  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search);
    const utm_Source = queryParams.get('utm_source');
    const utm_Medium = queryParams.get('utm_medium');
    const utm_Campaign = queryParams.get('utm_campaign');
    setUtmSource(utm_Source);
    setUtmMedium(utm_Medium);
    setUtmCampaign(utm_Campaign);

  },[location.search])

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openCartsDialog}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{marginTop:`${isTabletOrMobile?'50%':'0'}`}}
      PaperProps={{
        style: { borderTopLeftRadius: isMobile?"1.5rem":"",borderTopRightRadius: isMobile?"1.5rem":"",paddingTop:"0" },
        sx:{
          borderRadius: {lg:"8px"}
        }
         }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {items.length>0?(<Typography variant="h6" fontWeight={700} lineHeight={2}>
            Your Cart  
             <br/> {cart_cata} - {cart_brand} - {cart_model} 
             <Typography variant="p" fontWeight={700} lineHeight={2} color={"red"} sx={{cursor:"pointer"}} fontSize={"12px"} onClick={clear_cart}>{" (Clear all items)"}</Typography>
          </Typography>
          ):(<></>)}
          <HighlightOffRoundedIcon
            onClick={handleClose}
            sx={{ cursor: "pointer" }}
            />
        </Box>
      </DialogTitle>
      <DialogContent>
        { items.length > 0?(

          <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
          >
          <Grid container spacing={3} direction="column">
            <Grid item lg={9} >
              <Stack spacing={1} sx={{ overflowY: "auto" }}>
                {items &&
                  items.map((issue, index) => (
                    <DisplayComponent
                      key={index}
                      issue={get(issue, "issue_title", "---")}
                      // amount={get(issue, "discounted_price", 0)}
                      // amount={get(issue, "issue_price", 0)}
                      amount={get(issue, "issue_discounted_price", 0)}
                      data={issue}
                      />
                      ))}
              </Stack>
            </Grid>
            <Grid item lg={1}>
              <CartCouponComponent />
            </Grid>
          </Grid>
          <Grid container spacing={3} direction="column">
            <Grid item lg={1}>
              <Discount />
            </Grid>
            <Grid item lg={1} marginBottom={1.5}>
              <Button
                disabled={checkCartEmpty()}
                fullWidth
                variant="contained"
                onClick={checkout}
                >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Box>):
        (
          <Box height="25vh" display={"flex"} flexDirection="column" justifyContent="center" alignItems={"center"} gap={3} marginBottom={2}>
            <img src={emptycart}>
            </img>
            <Typography variant="h5" fontWeight={"bold"}>No items in cart</Typography>
            <Typography>You have not added any services to your cart yet</Typography>
          </Box>
        )
        }
      </DialogContent>
    </Dialog>
  );
};
export default Cart;
