import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

const QRCodePayment = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
      }}
    >
      <Grid spacing={2} container>
        <Grid item lg={3} sx={{ height: "100%" }}>
          <QRCode
            size={512}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={"Hello"}
            viewBox={`0 0 512 512`}
          />
        </Grid>
        <Grid item lg={8}>
          <Typography>
            Please point your phone to the screen to capture this QR code and
            make payment
          </Typography>
        </Grid>
      </Grid>
      <Button fullWidth variant="contained">
        Place Request
      </Button>
    </Box>
  );
};

export default QRCodePayment;
