import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLoaderFields } from "../../store/slices/LoaderSlice";

const Loader = () => {
  const open = useSelector(({ loader }) => loader.load);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      updateLoaderFields({
        load: false,
      })
    );
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
