import React from 'react'

import { Box, Typography, Button } from '@material-ui/core'

import { useScreenResolution } from '../../../hooks/ResponsiveHook'

import tool from '../assets/tool.svg'
import Quick from '../assets/Quick.svg'
import warrn from '../assets/warrn.svg'
import Afford from '../assets/Afford.svg'
import '../css/InfoHero.css'
import WhatsApp from '../../../assets/whatsapp.png'
import '../css/Compare.css'
const InfoHeroMobile = () => {
  const { isMobile } = useScreenResolution();
  const go_up = () => {
    window.scrollTo(0, 0)
}
  return (
    <div>
      <Box className='center_Items' sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1.2rem", padding: "1rem", margin: "2rem auto", placeItems: "center" }}>
        <div className='infoItems infoMobile' >
          <img src={Quick} alt="tool" />
          <p >Quick & <br /> Convenient</p>
        </div>
        <div className='infoItems infoMobile' >
          <img src={Afford} alt="tool" />
          <p >Affordable <br /> Prices</p>
        </div>
        <div className='infoItems infoMobile' >
          <img src={tool} alt="tool" />
          <p >Certified <br /> Parts</p>
        </div>
        <div className='infoItems infoMobile' >
          <img src={warrn} alt="tool" />
          <p >12 months <br /> warranty</p>
        </div>
      </Box>
      <div className="d-grid gap-2 col-8 my-4 col-md-4" style={{ width: isMobile ? "100%" : "", margin:"0 auto", padding:"1rem" }}>
        {/* <span id="shine-disaa" class="shine" style={shine}></span> */}
        <Button variant='contained' style={{ padding: "1rem 3rem", textTransform: "none", fontSize: "18px", background: "#0E62CB", color:"white" }} fullWidth={isMobile} onClick={() => { go_up() }}>
          Get quotes
        </Button>
      </div>
    </div>
  )
}
const InfoHero = () => {
  const go_up = () => {
    window.scrollTo(0, 0)
}
  const { isMobile } = useScreenResolution();
  return isMobile ? <InfoHeroMobile /> : (
    <div>
      <Box className='bg-box' sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: "auto", padding: "2rem", background: "#EBF3FF", maxWidth: "1350px", margin: "2rem auto" }} >
        <div className='infoItems' >
          <img src={Quick} alt="tool" />
          <p >Quick & Convenient</p>
        </div>
        <div className='infoItems' >
          <img src={Afford} alt="tool" />
          <p >Affordable Prices</p>
        </div>
        <div className='infoItems' >
          <img src={tool} alt="tool" />
          <p >Certified Parts</p>
        </div>
        <div className='infoItems' >
          <img src={warrn} alt="tool" />
          <p >12 months warranty</p>
        </div>
      </Box>
      <div className="d-grid gap-2 col-8 my-4 col-md-4" style={{ width: isMobile ? "100%" : "", margin:"0 auto" }}>
        {/* <span id="shine-disaa" class="shine" style={shine}></span> */}
        <Button variant='contained' style={{ padding: "1rem 3rem", textTransform: "none", fontSize: "18px", background: "#0E62CB", color:"white" }} fullWidth={isMobile} onClick={() => { go_up() }}>
          Get quotes
        </Button>
      </div>
      {/* <Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <button style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px", padding: "0.8rem 1.5rem", textTransform: "none", fontSize: "18px", backgroundColor: "#25D366", color: "white", borderRadius: "10px" }} fullWidth={isMobile}>
            <img src={WhatsApp} style={{ margin: "2px 5px" }}></img>
            <div style={{ display: "flex", flexDirection: "column",gap:"2px" }}>
              <p style={{margin:"0",color:"white"}}>
                WhatsApp Us
              </p>
              <small>
                9AM - 7PM
              </small>
            </div>
          </button>
        </Box>
      </Box> */}
    </div>
  )
}

export default InfoHero