import creditCardIcon from "../assets/payment/credit-card-icon.svg";
import netBankingIcon from "../assets/payment/net-banking-icon.svg";
import payLaterIcon from "../assets/payment/pay-later-icon.svg";
import qrCodeIcon from "../assets/payment/qr-code-icon.svg";
import upiPaymentIcon from "../assets/payment/upi-payment-icon.svg";
import walletIcon from "../assets/payment/wallet-icon.svg";

// export const YOUR_GOOGLE_MAPS_API_KEY = `AIzaSyAaY7X4vv4rC6EMtSXIqj4pojKUmoNffHw`;
// export const YOUR_GOOGLE_MAPS_API_KEY = `AIzaSyCj94VJbr_qklFe3VMX_FcUjFgCXzYW44Y`;
// API key for ERIP v2 - erip.dev.002@gmail.com
// export const YOUR_GOOGLE_MAPS_API_KEY = `AIzaSyA_Zabmp0xsXwwF64fLoRrd8dL_FTFuZ6M`;
// API key for ERIP v2 -  eripticket@gmail.com
export const YOUR_GOOGLE_MAPS_API_KEY = `AIzaSyBIMIFPwu-KSDQ6W0EQLrPOvfYHlvJz2M8`;
export const DEFAULT_MAP_POSITION = { lng: 77.5945627, lat: 12.9715987 };
export const OFFICE_MAP_POSITION = { lng: 77.61312, lat: 12.95835 };

export const DEFAULT_CUSTOMER_SUPPORT_NUMBER = "000-000-1111";
export const SUCCESS_STEP_COLOR = "ccfff0";
export const MENU_MAP = {
  APPLE_PRODUCTS: "APPLE_PRODUCTS",
  TOP_BRANDS: "TOP_BRANDS",
  TOP_REPAIRS: "TOP_REPAIRS",
  MOBILE_REPAIRS: "MOBILE_REPAIRS",
  MORE: "MORE",
};

export const HOW_IT_WORKS = {
  DOOR_STEP_SERVICE: [
    {
      label: "Book an appointment",
      description:
        "you can easily book an appointment at your selected time and place of convenience",
    },
    {
      label: "Technician visit",
      description:
        "Our expert professional visits your residence/office. Do the service in right front of you.",
    },
    {
      label: "Get back your device as good as new",
      description:
        "Your device is as good as new. We never compromise on quality.",
    },
  ],
  PICK_UP_SERVICE: [
    {
      label: "Schedule a pickup",
      description:
        "Inform us about the device & the issue. Our executive visits you on scheduled time.",
    },

    {
      label: "Get diagnosis & quotation",
      description:
        "Once we receive the device, we diagnose it thoroughly. Then share the diagnosis and a service cost estimate with you via call or whatsapp.",
    },

    {
      label: "Get back your device as good as new",
      description:
        "On quotation approval, we proceed with service. Then the device is delivered back to you.",
    },
  ],
};

export const PRODUCT_DISPLAY_ROUTE = "PRODUCT_DISPLAY_ROUTE";
export const COMPANY_INFO_ROUTE = "COMPANY_INFO_ROUTE";
export const EXTERNAL_DISPLAY_ROUTE = "EXTERNAL_DISPLAY_ROUTE";

export const FOOTER_CATEGORIES = {
  PERSONAL_GADGETS: {
    label: "Personal Gadgets",
    items: [
      { label: "Mobile", category_slug_01: "mobile-repair" },
      { label: "Tablet", category_slug_01: "tablet-repair" },
      { label: "Laptop", category_slug_01: "laptop-repair" },
      { label: "Smart Watch", category_slug_01: "smartwatch-repair" },
    ],
  },
  HOME_APPLIANCE: {
    label: "Home Appliances",
    items: [
      {
        label: "Washing Machine",
        category_slug_01: "washing-machine-repair-service",
      },
      { label: "Air Conditioner", category_slug_01: "ac-repair-service" },
      { label: "Television", category_slug_01: "tv-repair-service" },
      {
        label: "Water Purifier",
        category_slug_01: "water-purifier-repair-service",
      },
      {
        label: "Refrigerator",
        category_slug_01: "refrigerator-repair-service",
      },
    ],
  },
  COMPANY: {
    label: "Company",
    items: [
      { label: "About Us", type: COMPANY_INFO_ROUTE, url: "/aboutUs" },
      { label: "Contact Us", type: COMPANY_INFO_ROUTE, url: "/contactUs" },
      { label: "Terms of Use", type: COMPANY_INFO_ROUTE, url: "/termsOfUse" },
      {
        label: "Terms and Conditions",
        type: COMPANY_INFO_ROUTE,
        url: "/termsandconditions",
      },
      {
        label: "Cancellation Policy",
        type: COMPANY_INFO_ROUTE,
        url: "/cancellation",
      },
      {
        label: "Privacy Policy",
        type: COMPANY_INFO_ROUTE,
        url: "/privcypolicy",
      },
      {
        label: "Warranty Policy",
        type: COMPANY_INFO_ROUTE,
        url: "/warranty-policy",
      },
      {
        label: "Blogs",
        type: EXTERNAL_DISPLAY_ROUTE,
        url: "https://blog.erip.in/",
      },
    ],
  },
};
// Payments

export const REACT_APP_RAZ_KEY = "rzp_test_wjliPsohrwiu36";
export const REACT_APP_RAZ_SECRET = "keWT52NKn1D5KT7OUO3EXcKT";
export const PAYMENT_TYPE = {
  CARD: "card",
  UPI: "upi",
  NETBANKING: "netbanking",
  WALLET: "wallet",
  QRCODE: "qrcode",
  PAYLATER: "paylater",
};

export const TABS = [
  { label: "QR Code", value: PAYMENT_TYPE.QRCODE, icon: qrCodeIcon },
  {
    label: "Credit/Debit Cards",
    value: PAYMENT_TYPE.CARD,
    icon: creditCardIcon,
  },
  { label: "UPI Payments", value: PAYMENT_TYPE.UPI, icon: upiPaymentIcon },
  {
    label: "Net Banking",
    value: PAYMENT_TYPE.NETBANKING,
    icon: netBankingIcon,
  },
  { label: "Wallets", value: PAYMENT_TYPE.WALLET, icon: walletIcon },
  { label: "Pay Later", value: PAYMENT_TYPE.PAYLATER, icon: payLaterIcon },
];

export const CONTACT_US_FAQS = [
  {
    faqsection_question: "Do you offer Onsite repair?",
    faqsection_answer:
      "Yes. We do. Our onsite technicians will come to your doorstep and repair your mobile phone within 30 minutes. This service is offered for issues with screen, battery, mic/speaker charging, camera, and the like",
  },
  {
    faqsection_question: "How does your pick and delivery service works?",
    faqsection_answer:
      "Our device pickup and delivery service is centred on issues that need more than 30 minutes of reparation. Issues like glass replacement, damage due to water or complex software issues require pickup and delivery services. Additionally, the timely pickup and delivery service is free of cost.",
  },
  {
    faqsection_question: "What is the average repair service cost?",
    faqsection_answer:
      "The price of our repair services varies as per different brands, the models, nature of the issue and spare parts used. We will share the approx price with you once you confirmed the issue.",
  },
  {
    faqsection_question: "What is the mode of payment used in this platform?",
    faqsection_answer: "We accept payments via card, cash, or UPI transfers.",
  },
  {
    faqsection_question:
      "Is there a warranty period for mobile repair services in Bengaluru?",
    faqsection_answer:
      "We offer up to 18 months warranty for all the mobile services that you choose.",
  },
  {
    faqsection_question: "What kind of spare parts does ERIP use?",
    faqsection_answer:
      "ERIP has adopted a strict policy by which we use 100% genuine ERIP-certified spare parts for our mobile repair services. Most of our spare parts are imported to increase the effectiveness of our services and the functioning of the devices.",
  },
];

export const ABOUT_US = {
  aboutErip: `ERIP is an online service-providing app that connects industry-grade technicians to consumers who have specific service requirements. Our experienced professionals are trained with years of experience in performing repair services on broken gadgets and home appliances. ERIP provides instant on-demand repair services at your doorstep with free pick-up and delivery of your device. Through ERIP, we are constantly working towards reducing e-waste by using the formula of reuse, repair, and recycle.`,
  aboutFounder: `Arbaz Husain is an avid reader who loves to travel, play basketball, and is a passionate conflict resolver in society. His vision of creating a professional platform where the best of technicians in India could perform customized services for consumers has been manifested through ERIP. As Mr. Husain spreads his business wings, ERIP is growing to be India’s largest repair service platform in the coming years.
  `,
};

export const TERMS_OF_USE = [
  {
    label: `Terms and Conditions for ERIP services`,
    value: `You agree to these Terms & Conditions by using it (defined below). Please take the time to thoroughly read them. The website www.erip.in is owned and operated by Azban Fixation Pvt Ltd., formerly known as "ERIP."
    The mobile touch versions, App on Play Store and Apple App Store, and/or any site(s) we have now or in the future that reference these Terms & Conditions (collectively, "ERIP") provide a marketplace and platform for consumers and organisations to sell or repair their used, old, and other articles, as well as conduct various transactions and activities, with third-party companies and other entities and persons (collectively, "Third Parties").The ERIP marketplace, platform, and related features are generally referred to as the services (the "Services"), whether supplied through the Site or through other methods.
    You must not use the Site or Services if you disagree with any aspect of these Terms & Conditions. Your continuing use of the Site or Services constitutes your acceptance of these Terms & Conditions, as they may be changed by us at any time and without notice to you. Please revisit this page to see if the ERIP Terms & Conditions have been updated.`,
  },
  {
    label: "ERIP is a marketplace venue",
    value: `ERIP is a platform that allows users to sell/repair specific things while adhering to ERIP's standards. ERIP may not be directly involved in transactions between users and third-party professionals, implying that ERIP has no control over any aspect of your transactions with Third Parties for any reason, and that the Third Parties are solely responsible to you for all aspects of your transactions with them.`,
  },
  {
    label: "Permitted Use and Legal Obligations",
    value: `ERIP grants you permission to access, view, and use the Site's content and software (collectively, the "Azban fixation Pvt Ltd Property") exclusively for the purposes of using the Services. Any copyright, trademark, or other property markings that have been put on the ERIP Property may not be removed. You are not permitted to retrieve data or other content from the ERIP Property in a systematic manner. Any modification, reproduction, redistribution, republication, uploading, posting, transmitting, distributing, or otherwise exploiting in any way the ERIP Property, or any portion of the ERIP Property, is strictly prohibited without the prior written permission of Azban fixation Pvt Ltd, except as expressly permitted by these Terms & Conditions. You agree to abide by all applicable laws, regulations, and ordinances relating to the Site and Services, the ERIP Property, or your use of them, and that you will not engage in any conduct that restricts or inhibits any other person from using or enjoying the Services while using the Site and Services.`,
  },
  {
    label: "Copyright and trademarks",
    value: `Unless otherwise stated, all materials in the Site, including, but not limited to, text, software, photos, video, graphics, music, sound, the ERIP Logo, trademarks and service marks, as well as the entire contents of the Site, are the property of Azban fixation Pvt Ltd and/or its affiliates or licensors, including the Third Parties, and are protected by Any breach of these restrictions may be considered a copyright, trademark, or other intellectual property right infringement, and you may face civil and/or criminal consequences as a result.
    Other trademarks on the site may be under licence from their respective trademark owners, in which case such licence is for the sole benefit and use of ERIP unless otherwise noted, or they may be the property of their respective owners. Without Azban fixation Pvt Ltd's written consent, you may not use any of the trademark name, logos, trademarks or brands, or trademarks or brands of others on the Site.`,
  },
  {
    label: "ERIP Services and Third Party Services and Sites",
    value: `
    The information and resources available on the Site and through the Services are for general information only and may not cover all of the terms, conditions, and exceptions that apply to ERIP's services. Through the ERIP Site and Services, ERIP displays information from Third Parties, such as pricing for your products, item descriptions, some Third Party terms of service, and other information ("Third Party Information"). ERIP has no control over, and accepts no responsibility for, any Third Party Information.
    You perform your actual sales and other transactions directly with the Third Parties and not with ERIP, unless otherwise expressly and explicitly stated. ERIP has no control over any part of your sales or transactions with Third Parties, and you are entirely liable for all aspects of your sales and transactions with them.
    The ERIP Site may contain links to Third Party and other entities' websites (the "Additional Sites"), or such Additional Sites may have links to the ERIP Site. These links are provided as a courtesy and for informative reasons only, and are not intended to be referrals or endorsements of the Additional Sites by ERIP. The Additional Sites are maintained by their respective organisations, who are entirely responsible for the content of their own websites. ERIP neither verifies nor endorses the content, accuracy, stated opinions, warranties, products or services, intellectual property compliance, or connections to such Additional Sites. All Additional Sites' privacy policies and Terms & Conditions agreements should be reviewed.
    `,
  },
  {
    label: "Privacy and Passwords",
    value: `
    ERIP respects and safeguards the privacy of your personal information. Please read the ERIP privacy statement carefully since it contains critical information about your use of the Site and Services. Some areas of the Site are password-protected and require a user identification code ("User ID") and password. It is forbidden to gain unauthorised access to or use of such areas of the Site. You agree to contact ERIP right away if you suspect a third party has gotten your User ID or password, or if you suspect unlawful access or usage is about to happen or has happened. If ERIP determines that unlawful access is occurring or has happened, ERIP may terminate access without prior notification to you for your protection. You also agree that ERIP has the authority to act on whatever orders it receives, as long as you have given your permission.`,
  },
  {
    label: "Membership",
    value: `Membership

    Members are visitors to the site and/or individuals who use the Services who choose to register with ERIP ("Members"). a.Registration and Basic Membership Terms: Each Member is given an account ("Account") once they have enrolled. If you register as a Member, you agree to represent and warrant to us that:
    
    You are of legal age to enter into a legally binding agreement.
    you will supply us with correct, up-to-date, and full registration and contact information, and you will keep this information updated.
    You are not barred by law from registering and using the Services. If you violate any of the Terms & Conditions or other ERIP rules, we retain the right to suspend or terminate your membership, as well as your access to the Site and Services.
    b. Password and Account Security: You will create a password and a user id in association with your Account. You are solely responsible for keeping your password safe and secure, as well as for all actions made with it.
    c. Age: You must be at least eighteen (18) years old, or the age of majority in your state or province of residence, whichever is greater, to establish an Account or otherwise use this Website.`,
  },
  {
    label: "Warranty Exclusions and Limitations of Liability",
    value: `
  We guarantee that the Services and ERIP Property will be largely in accordance with the descriptions on the Site. If this guarantee is breached, the Customer's sole and exclusive remedy and ERIP's entire and exclusive obligation shall be (at ERIP's choice) to correct the failure or refund the monetary amount paid for the services by the Customer, if any. We make no representations or guarantees about the Services or the ERIP Property, save as stated in the above sentence. We expressly disclaim all warranties, whether express or implied, including all warranties of merchantability, fitness for a particular purpose, title, non-infringement, and any and all warranties arising from course of dealing and usage of trade; that the services and ERIP property will meet your requirements, will always be available, accessible, uninterrupted, timely, secure, or operate without error; and as to the results that may be obtained from the operations. There is no counsel or information, whether oral or written, that can be trusted, gained in a way that isn't explicitly indicated here.
  You will not be entitled to any incidental, consequential, indirect, punitive, or special damages from us, whether based on contract, tort (including negligence), or otherwise, arising from or relating to the services or ERIP property, even if ERIP has been informed or should have known of the possibility of such damages. In any case, ERIP's maximum aggregate responsibility for any and all damages originating from the services or the ERIP property is limited to a return of the amount you paid us, if any.
  Since certain locations do not allow the restriction or exclusion of warranties or liabilities, some of the limitations or exclusions listed above may not apply to you.`,
  },
  {
    label: "Indemnity",
    value: `
Customer agrees to hold ERIP, our affiliates, suppliers, licensors, and partners, as well as their officers, directors, employees, agents, and representatives, harmless from any claim or demand made by any third party due to or arising out of I your access to or use of Services, (ii) your violation of these Terms & Conditions, or (iii) the infringement by you, or any third party using your account, of a third party's intellectual property rights. ERIP retains the right to take the exclusive defence and control of any matter for which you must indemnify us, at your expense, and you agree to cooperate with our defence of these claims. You undertake not to resolve any dispute without ERIP's prior written authorization. When we become aware of any such claim, action, or proceeding, we shall make reasonable attempts to tell you.`,
  },
  {
    label: "Applicable Law, Jurisdiction, Compliance",
    value: `You and ERIP agree that the substantive laws of the Republic of India, without respect to its conflict of laws provisions, shall govern all matters arising from or connected to the use and operation of the Site and/or the Services. You agree to have any disputes arising from or pertaining to the operation, use, or other exploitation of the Site and/or the Services heard and resolved in the courts of India. You agree that such courts have personal jurisdiction over you, that proceeding in such courts is fair and convenient, and that you will not raise any objections to proceeding in such courts.
`,
  },
  {
    label: "Miscellaneous Provisions",
    value: `
A waiver by us of any of the covenants, conditions, or agreements to be performed by you will not be construed as a waiver of any subsequent breach thereof or of any other covenant, condition, or agreement hereof contained, and no delay or omission by us in exercising any of our rights resulting from any noncompliance or default by you with respect to any of these Terms & Conditions will impair any such right or be construed as a waiver thereof. "Including" means "including but not limited to" in these Terms & Conditions. If any provision of these Terms & Conditions is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions will remain in full force and effect and will be reformed to be valid and enforceable while reflecting the parties' intent to the greatest extent permitted by law. Unless otherwise stated, these Terms & Conditions constitute the whole agreement between you and ERIP with respect to its subject matter, and replace all earlier promises, agreements, or representations, whether written or oral, with respect to such subject matter. You agree that the electronic version of these Terms & Conditions is a written statement, and that your acceptance of the terms and conditions hereof is a "signature" for all purposes.`,
  },

  {
    label: `Information Collection, Use, and Sharing`,

    value: `By filling out any ERIP contact form, you agree to be contacted by either a ERIP representative or a representative from the partner you selected. ERIP's third-party marketing partners may contact you over phone, email, text, or prerecorded message about ERIP services, offers, events, announcements, and offers. If you react to one of these offers, your information will be subject to the terms and conditions of the third party. By submitting us your phone number, you allow ERIP permission to contact you and/or send ERIP-related information, offers, or announcements to you by text messages, prerecorded voice, and/or automatic telephone calling systems.`,
  },
];
