import React from "react";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Footer from "./molecules/home/Footer";
import Navbar from "./molecules/navbar";
import AppToolbar from "./organisms/app-toolbar";
import Overlays from "./organisms/overlays";
import Navigation from "./organisms/app-navigation/Navigation";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Loader from "./atoms/components/LoaderComponent";
import { fetchCategoryItems } from "./services/home";
import { updateCategoryFields } from "./store/slices/CategorySlice";
import { isEmpty } from "lodash";
import { fetchUserProfile,getCart } from "./services/user-profile";
import { updateProfileFields } from "./store/slices/ProfileSlice";
import {updateCartFields,updateCartItems_new} from "./store/slices/CartSlice";

const App = () => {
  const theme = createTheme  ({
    typography:{
      fontFamily:[
        'Delicious Handrawn',
        'cursive',
        'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
      ].join(","),
    }
  });
  const dispatch = useDispatch();
  const categories = useSelector(({ category }) => category.categories);
  const profile = useSelector(({ profile }) => profile.user);

  React.useEffect(() => {
    const getCategoryItems = async () => {
      const data = await fetchCategoryItems();
      dispatch(
        updateCategoryFields({
          categories: data,
        })
      );
    };
    if (isEmpty(categories)) getCategoryItems();
  }, []);

  React.useEffect(() => {
    const getUserProfile = async () => {
      const response = await fetchUserProfile();
      dispatch(
        updateProfileFields({
          user: response,
        })
      );
    };
    if (isEmpty(profile)) {
      getUserProfile();
    }
  }, []);

  React.useEffect(() => {
    const getUsercart = async () => {
      const response = await getCart();

      // console.log('response');
      // console.log(response);
      if(response==''){
      }else{

      
      dispatch(
        updateCartFields({
          dbitems:response,
          cart_model:response[0].model_segment_id,
          cart_category_title:response[0].category_title,
          cart_brand_title:response[0].brand_title,
          cart_model_segment_title:response[0].model_segment_title,
          displayBookNow:true
        })
      );
      dispatch(
        updateCartItems_new({
          issue:response,
          add:true
          
        })
      );
    }
      
      // dispatch(
      //   updateProfileFields({
      //     user: response,
      //   })
      // );
    };
    if (isEmpty(profile)) {
      getUsercart();
    }
  }, []);

  
  const checkValid = () =>{
    return window.location.pathname!=='/ap-enquiry-order'
    && window.location.pathname!=='/thanks_ln_enq'
    && window.location.pathname!=='/thanks_ln_enq_2' 
    &&  window.location.pathname!=='/thanks_order' 
    && window.location.pathname !== '/landingv2test'
    && window.location.pathname !== '/temp_conform'
    && window.location.pathname !== '/conform2'
    && window.location.pathname !== '/thanks'
    && window.location.pathname !== '/service-mobile-blr'
    && window.location.pathname !== '/devicelanding'
    && window.location.pathname !== '/location'
  }

  return (
    <ThemeProvider theme={theme}>

    <React.Fragment>
    
      <Loader />
      {/* <AppToolbar /> */}
      {/* {
        ( checkValid() ) ? <AppToolbar />:null
      } */}
      {/* <AppToolbar /> */}
      {/* {
        ( checkValid() ) ? <Navigation />:null
      } */}
      <Navbar />
      {/* <Footer /> */}
      {/* {
        (checkValid() ) ? <Footer />:null
      } */}
      <Overlays />
    </React.Fragment>
      </ThemeProvider>
  );
};

export default App;
