import React, { useEffect, useRef, useState } from 'react'
import { useScreenResolution } from '../../../hooks/ResponsiveHook';
import {
  Box,
  Grid, Step,
  StepContent,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import appoint from '../assets/appoint.svg';
import visit from '../assets/visit.svg';
import getback from '../assets/getback.svg';
import '../css/HowitWorks.css'
import AccessibilityIcon from '@mui/icons-material/Accessibility';
// import vid from '../assets/vid.mp4';
// import vid2 from '../assets/vid2.mp4';


const HowItWorks = () => {


  const [autoplay, setAutoplay] = useState(false);

  // Function to check the scroll position and handle video autoplay
  const checkScrollPosition = () => {
    const scrollPosition = window.scrollY;
    const triggerPosition = 300;
    const maxtriggerPosition = 500;
    if (scrollPosition >= 1000 && scrollPosition <= 3000) {
      console.log(scrollPosition);
      setAutoplay(true);
    }
    else {
      setAutoplay(false);
    }
  };

  useEffect(() => {
    // Add an event listener to the scroll event when the component mounts
    window.addEventListener('scroll', checkScrollPosition);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);


  const CustomStepIcon = () => {
    return <div style={{
      height: isMobile ? "100px" : "120px",
      width: isMobile ? "100px" : "120px",
      borderRadius: "50%",
      background: "#F5F9FF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }} ><img src={appoint} alt="" srcset="" /></div>
  };
  const CustomStepIcon2 = () => {
    return <div style={{
      height: isMobile ? "100px" : "120px",
      width: isMobile ? "100px" : "120px",
      borderRadius: "50%",
      background: "#F5F9FF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }} ><img src={visit} alt="" srcset="" /></div>
  };
  const CustomStepIcon3 = () => {
    return <div style={{
      height: isMobile ? "100px" : "120px",
      width: isMobile ? "100px" : "120px",
      borderRadius: "50%",
      background: "#F5F9FF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }} ><img src={getback} alt="" srcset="" /></div>
  };
  const { isMobile, isTabletOrMobile } = useScreenResolution();
  // const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
  const steps = [
    {
      title: "Book an appointment",
      desc: "You can easily book an appointment at your selected time and place of convenience."
    },
    {
      title: "Technician visits you",
      desc: "Our expert professional visits your residence/office and fixes your device right in front of you."
    },
    {
      title: "Get your phone good as new",
      desc: "Receive your phone in top condition, as if it was never broken. We don’t compromise on quality"
    },
  ]
  return (
    <div >
      <Box padding={isTabletOrMobile ? "1rem" : "2rem"} sx={{ maxWidth: isMobile ? "100%" : "1400px", margin: "40px auto" }}>
        <Typography variant='h3' align='center' style={{ fontSize: isMobile ? "30px" : "40px", fontWeight: "bold" }} >How It Works</Typography>
        <Box display={"flex"} flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" marginTop={"2rem"} alignItems={"center"} sx={{gap:"1rem"}} >
          {isMobile && <Box sx={{ borderRadius: "20px", overflow: "hidden" }} style={{ width: isTabletOrMobile ? "90%" : "500px" }} >
            <div style={{ height: "545px", background: "grey" }}>
              <iframe width="100%" height="545" src={`https://www.youtube.com/embed/-FA81CzkEFs?autoplay=${autoplay}&amp;controls=${1}&amp;&mute=1;&showinfo=${0}&amp;rel=0&amp;loop=0&amp;modestbranding=0&amp;wmode=transparent`} title="YouTube video player" frameborder="0" allow="autoplay;" modestbranding></iframe>
            </div>

          </Box>}
          <Box padding={1} sx={{width:isMobile?"100%":"70%"}}>
            <Stepper orientation="vertical" connector>
              <Step active style={{ margin: isMobile ? "10px 0" : "1rem 0" }} >
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <Typography style={{ fontSize: isMobile ? "18px" : "24px", margin: isMobile ? "0 10px" : "0 28px" }}>{steps[0].title}</Typography>
                  <Typography style={{ color: "#676767", fontSize: isMobile ? "16px" : "18px", margin: isMobile ? "0 10px" : "0 28px" }} >
                    {steps[0].desc}
                  </Typography>
                </StepLabel>
              </Step>
              <Step active style={{ margin: isMobile ? "10px 0" : "1rem 0" }} >
                <StepLabel StepIconComponent={CustomStepIcon2}>
                  <Typography style={{ fontSize: isMobile ? "18px" : "24px", margin: isMobile ? "0 10px" : "0 28px" }}>{steps[1].title}</Typography>
                  <Typography style={{ color: "#676767", fontSize: isMobile ? "16px" : "18px", margin: isMobile ? "0 10px" : "0 28px" }} >
                    {steps[1].desc}
                  </Typography>
                </StepLabel>
              </Step>
              <Step active style={{ margin: isMobile ? "10px 0" : "1rem 0" }} >
                <StepLabel StepIconComponent={CustomStepIcon3}>
                  <Typography style={{ fontSize: isMobile ? "18px" : "24px", margin: isMobile ? "0 10px" : "0 28px" }}>{steps[2].title}</Typography>
                  <Typography style={{ color: "#676767", fontSize: isMobile ? "16px" : "18px", margin: isMobile ? "0 10px" : "0 28px" }} >
                    {steps[2].desc}
                  </Typography>
                </StepLabel>
              </Step>


            </Stepper>
          </Box>
          {/* <Box  display={"flex"} flexDirection={"column"} justifyContent={"space-between"} sx={{gap:"1.2rem", padding:"1.2rem", width:""}}>
          <Box className='content_holder'>
            <div className="imgHolderCircle">
              <img src={appoint} alt="" />
            </div>
            <div className="content_info">
              <Typography variant= {isMobile?'h6':'h5'} >Book an appointment</Typography>
              <Typography>You can easily book an appointment at your selected time and place of convenience.</Typography>
            </div>
          </Box>
          <Box className='content_holder'>
            <div className="imgHolderCircle">
              <img src={visit} alt="" />
            </div>
            <div className="content_info">
              <Typography variant={isMobile?'h6':'h5'} >Technician visit</Typography>
              <Typography>Our expert professional visits your residence/office. Do the service in right front of you.</Typography>
            </div>
          </Box>
          <Box className='content_holder'>
            <div className="imgHolderCircle">
              <img src={getback} alt="" />
            </div>
            <div className="content_info">
              <Typography  variant={isMobile?'h6':'h5'} >Get back your device as good as new</Typography>
              <Typography>Your device is as good as new. We never compromise on quality.</Typography>
            </div>
          </Box>
        </Box> */}
          {!isMobile && <Box sx={{ borderRadius: "20px", overflow: "hidden" }} style={{ width: isTabletOrMobile ? "100%" : "27%" }} >
            <div style={{ height: "545px", background: "grey" }}>
              <iframe id='youtubevid' width="100%" height="545" src={`https://www.youtube.com/embed/-FA81CzkEFs?autoplay=${autoplay}&amp;controls=${1}&amp;&mute=1;&autopause=0;&showinfo=${0}&amp=0;rel=0&amp;loop=0&amp;modestbranding=0&amp;wmode=transparent`} title="YouTube video player" frameborder="0" allow='autoplay' ></iframe>
            </div>
          </Box>}
        </Box>
      </Box>
    </div>
  )
}

export default HowItWorks