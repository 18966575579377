import { Stack } from "@mui/material";
import React from "react";
import youtube from "../../assets/social-media/youtube.svg";
import instagram from "../../assets/social-media/instagram.svg";
import facebook from "../../assets/social-media/facebook.svg";
import twitter from "../../assets/social-media/twitter.svg";

// const SOCIAL_MEDIA_IMAGES = [facebook, instagram, twitter, linkedin];
const Social_Media_icons = [
  {icon: facebook, link:"https://www.facebook.com/eripcompany/"},
  {icon: instagram, link:"https://www.instagram.com/eripcompany/"},
  {icon: twitter, link:"https://twitter.com/eripcompany"},
  {icon: youtube, link:"https://www.youtube.com/@eripcompany"},
]
const SocialMedia = () => {
  return (
    <Stack direction="row" justifyContent='center' spacing={3}>
      {Social_Media_icons.map((item, index) => (
        <a href={item.link} target="blank" ><img src={item.icon} alt={item.icon} key={index} style={{ height: "6vh", textDecoration:"none" }} /></a>
      ))}
    </Stack>
  );
};
export default SocialMedia;
