import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCartFields } from "../store/slices/CartSlice";
import { updateConfirmationDialogFields } from "../store/slices/ConfirmationDialog";
import { fetchUserAddressList } from "../services/confirmation";
const UserProfileOptions = ({ label = "", variant = "body2",link="",type="" }) => {
  const dispatch = useDispatch();

  const openAddress = async() => {
    console.log("yes");
    dispatch(
      updateCartFields({
        openDisplayAddress: true,
      })
    );
    const addresses = await fetchUserAddressList();
    dispatch(
      updateConfirmationDialogFields({
        allUserAddress: addresses,
        selectedAddress: null,
      })
    );
  };
  return type === 'address'?(
    <a style={{textDecoration:"none"}} onClick={()=>openAddress()}>

    <Typography
      variant={variant}
      align="left"
      sx={{
        borderBottom: "1px solid #b9b2b273",
        paddingLeft: 1,
        color: "#333946",
        cursor: 'pointer'
      }}
      
      >
      {label}
    </Typography>
    </a>
  ):(
    <a href={link} style={{textDecoration:"none"}}>
    <Typography
      variant={variant}
      align="left"
      sx={{
        borderBottom: "1px solid #b9b2b273",
        paddingLeft: 1,
        color: "#333946",
        cursor: 'pointer'
      }}
      
      >
      {label}
    </Typography>
      </a>
  );
};

export default UserProfileOptions;
