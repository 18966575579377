import { get } from "lodash";
import { getRequest, getRequestWithParams, postRequest } from "..";
import store from "../../store";
import {
  GET_BLOGS,
  GET_CAROUSEL_MENUS,
  GET_CATEGORY_BY_CITIES,
  GET_EXCLUSIVE_APPLE_SERVICES,
  GET_FAQ_BY_CATEGORY,
  GET_NAV_SEARCH,
  GET_OFFER_STICKERS,
  GET_REQUEST_CALLBACK,
  GET_TESTIMONIALS,
  GET_MOBILE_BRANDS
} from "../ApiConstants";

export const fetchCarouselMenus = async () => {
  try {
    const { data } = await getRequest(GET_CAROUSEL_MENUS);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};
export const fetchMobileBrands = async () => {
  try {
    const { data } = await getRequest(GET_MOBILE_BRANDS);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchCategoryItems = async (city = 1) => {
  try {
    const params = new URLSearchParams();
    params.append("city", city);
    const { data } = await getRequestWithParams(GET_CATEGORY_BY_CITIES, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchOfferStickers = async () => {
  try {
    const { data } = await getRequest(GET_OFFER_STICKERS);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchTestimonials = async (category = "") => {
  try {
    const params = new URLSearchParams();
    if (category) params.append("category", category);
    const { data } = await getRequestWithParams(GET_TESTIMONIALS, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchBlogs = async (category) => {
  try {
    const params = new URLSearchParams();
    if (category) params.append("category", category);

    const { data } = await getRequestWithParams(GET_BLOGS, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchExclusiveAppleServices = async () => {
  try {
    const { data } = await getRequest(GET_EXCLUSIVE_APPLE_SERVICES);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchFaqsByCategory = async () => {
  try {
    const state = store.getState();
    const category = get(state, "category.selectedCategory.category_id", "");
    if (!category) return null;
    const params = new URLSearchParams();
    params.append("category", category);
    params.append("city",1);
    const { data } = await getRequestWithParams(GET_FAQ_BY_CATEGORY, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchFaqContentByCategory = async ({
  valueKey = "",
  value = "",
  api = "",
  cata_id="",
  brand_id=""
}) => {

  try {
    // const state = store.getState();
    // const category = get(state, "category.selectedCategory.category_id", "");
    // if (!category) return null;
    const params = new URLSearchParams();
    params.append("category", cata_id);
    params.append("city",1);
    params.append("brand",brand_id);
    params.append("model",value);
    const { data } = await getRequestWithParams(api, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return null;
  }

};

export const fetchNavSearch = async (keyword = "") => {
  try {
    const state = store.getState();
    const city = get(state, "location.city", 1);
    const params = new URLSearchParams();
    params.append("city", city);
    params.append("keyword", keyword);
    const { data } = await getRequestWithParams(GET_NAV_SEARCH, params);
    return get(data, "data", []);
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getRequestCallback = async (request) => {
  try {
    const { name, mobile, email, description } = request;
    const payload = new FormData();
    payload.append("name", name);
    payload.append("mobile", mobile);
    payload.append("email", email);
    payload.append("description", description);
    const { data } = await postRequest(GET_REQUEST_CALLBACK, payload);
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
