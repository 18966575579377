import React from 'react'
import { useScreenResolution } from '../../../hooks/ResponsiveHook';

import { Avatar, Box, Rating, Stack, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import test1 from '../assets/test1.png';
import test2 from '../assets/test2.svg';
import test3 from '../assets/test3.svg';
const testimonialItem = [
  {
    profile: test1,
    name: "Monxtarr Yt",
    product: "iPhone 12",
    description: "It was really a fantastic experience I have ever had, they fixed my iPhone 12 screen right in front of me the engineer was well skilled he handled my phone very professionally, truly satisfied & I will recommend all my close ones for sure. Thank you ERIP",
  },
  {
    profile: test2,
    name: "Sahil Joshi",
    product: "iPhone X",
    description: "Fantastic service by Adil and the ERIP team. Came on time , got the job done correctly. Good price as well. What else could one ask for?",
  },
  {
    profile: test3,
    name: "Shanker Yanglem",
    product: "iPhone 13",
    description: "Due to busy schedule book an appointment at home for my battery replacement. Mr. Adil was so kind and warm, he fix my phone in just 30 min. Thank you team ERIP 🙏 and ADIL 😊",
  },
  {
    profile: "",
    name: "Ahmed Ismail",
    product: "iPhone 11",
    description: "Great service, by Mohamed Thaha and the Erip team , they repaired my iPhone in less than an hour and communication with the team was flawless. I thought the price was very reasonable, and they were fair and honest about what the fix was. Would definitely recommend and will use them again in the future.",
  },
]

const CarouselItem = ({ item }) => {
  console.log(item.profile);
  return (
    <Stack direction="row">
      <Stack
        spacing={1}
        sx={{
          padding: 1,
          textAlign: "center",
          width: { xs: "100%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "2px 3px 12px rgba(0, 0, 0, 0.08)",
          borderRadius: "8px",
          marginLeft: "auto",
          marginRight: "auto",
          gap: "2rem"
        }}
      >
        <Box sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          marginTop: "1rem"
        }}
        >
          <Avatar src={item.profile} sx={{ width: "50px", height: "50px" }} />
          <Box>
            <Typography>{item.name}</Typography>
            <Typography>{item.product}</Typography>
          </Box>
        </Box>
        <Typography maxWidth={"830px"} style={{ fontSize: "18px", lineHeight: "28px" }}>{item.description}</Typography>
        <Rating
          value={5}
        />
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          alt="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          loading="lazy"
          style={{
            height: "40px",
            width: "40px",
            marginTop: "1rem"
          }}
        />
        <br />
      </Stack>
    </Stack>
  );
};

const Testimonials = () => {
  const { isMobile, isTabletOrMobile } = useScreenResolution();
  return (
    <div>
      <Box sx={{
        padding: isMobile ? "1rem" : "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "2rem 0"
      }} >
        {!isMobile && <Typography variant='h3' align='center' style={{ fontSize: isMobile ? "30px" : "40px", fontWeight: "bold" }} >What Our Happy Customers Say</Typography>}
        <br />
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          xs={12}
          sx={{ padding: isMobile ? 1 : 2, width: isTabletOrMobile ? "100%" : "1000px" }}
        >
          {
            testimonialItem.map((item, index) => (
              <CarouselItem key={index} item={item} />
            ))
          }
        </Carousel>
      </Box>
    </div>
  )
}

export default Testimonials