import deleteIcon from "../../assets/payment/delete-icon.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UserProfileOptions from "../../atoms/UserProfileOptions";
import PaymentOptionTabs from "../../molecules/payments/PaymentOptionTabs";
import { updatePaymentFields } from "../../store/slices/Payment";
import { get } from "lodash";

const Payment = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const openPaymentModule = useSelector(({ payment }) => payment.open);

  const title = useSelector(({ payment }) => payment.title);
  const totalBalance = useSelector(({ cart }) => get(cart, "totalAmount", 0));
  const orderIssues = useSelector(({ cart }) => get(cart, "items", []));

  const fromProfile = useSelector(({ payment }) =>
    get(payment, "fromProfile", false)
  );
  const cartItemsFromProfile = useSelector(({ payment }) =>
    get(payment, "cartItemsFromProfile", null)
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      updatePaymentFields({
        open: false,
        title: "Payment Options",
      })
    );
  };

  const generateOrderIssues = () => {
    let issues = orderIssues;
    if (fromProfile) {
      issues = get(cartItemsFromProfile, "issues", []) || [];
    }
    const getIssueTitle = () => {
      if (fromProfile) {
        return "issue_name";
      } else {
        return "issue_title";
      }
    };

    const getDiscountedPrice = () => {
      if (fromProfile) {
        return "issue_discounted_price";
      } else {
        return "discounted_price";
      }
    };
    return get(issues, "length", 0) > 0 ? (
      issues.map((issue, index) => (
        <Box
          sx={{
            padding: 1,
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
          key={index}
        >
          <Typography>{issue[getIssueTitle()]}</Typography>

          <Typography fontWeight={700}>
            &#8377; {issue[getDiscountedPrice()]} &nbsp;
            {!fromProfile ? <img src={deleteIcon} alt={deleteIcon} /> : <></>}
          </Typography>
        </Box>
      ))
    ) : (
      <></>
    );
  };

  const getTotalAmount = () => {
    return fromProfile
      ? get(cartItemsFromProfile, "total", "--")
      : totalBalance;
  };

  const orderIssuesComponent = generateOrderIssues();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openPaymentModule}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={1} md={1} lg={1}>
            <KeyboardBackspaceIcon
              onClick={handleClose}
              sx={{ cursor: "pointer" }}
            />
          </Grid>
          <Grid item xs={11} md={11} lg={11}>
            <Typography fontWeight={700} variant="h6" align="center">
              Payment
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} justifyContent="space-between" height="100%">
          <Box>
            <UserProfileOptions
              fontWeight={700}
              label="Your Cart"
              variant="h6"
            />
            <Grid spacing={{ xs: 0, md: 0, lg: 3 }} container>
              <Grid item xs={12} md={12} lg={6}>
                <Box
                  sx={{
                    height: { lg: "15vh" },
                    overflowY: "auto",
                  }}
                >
                  {orderIssuesComponent}
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Box
                  sx={{
                    padding: 1,

                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontWeight={700}>Total</Typography>
                  <Typography fontWeight={700}>
                    &#8377; &nbsp;{getTotalAmount()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <UserProfileOptions fontWeight={700} label={title} variant="h6" />
            <PaymentOptionTabs />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Payment;
