// import { ConnectedTvOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import React,{useRef,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { YOUR_GOOGLE_MAPS_API_KEY } from "../../constants";
import { UPDATE_ADDRESS } from "../../services/ApiConstants";
import { updateGoogleMapsFields } from "../../store/slices/GoogleMaps";
import { getCurrentLocation } from "../../utils/GoogleMapsUtils";

const MapsContainer = (props) => {
  // console.log(props)
  let position = useSelector(({ googleMaps }) => googleMaps.position_new);
  console.log('position')
  console.log(position)
  const refMap = useRef(null);
  const [markerPosition, setMarkerPosition] = useState(position);
  // if (!props.position) {
  //   position = props.position;
  // let newaddress = useSelector(({ googleMaps }) => googleMaps.pincode);
  // // }
  // console.log(newaddress);

  const dispatch = useDispatch();

  const onMarkerDragEnd = async (map, coord) => {
    // alert("Sdsd");
    // console.log(coord);
    // const mapCenter = refMap.current.getCenter();
    // console.log(mapCenter);
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const { address, postalCode } = (await getCurrentLocation(lat, lng)) || "";
    console.log("adre,", address);
    dispatch(
      updateGoogleMapsFields({
        position: {
          lat,
          lng,
        },
        address,
        postalCode,
        enableButton: true,
        textFieldValue: address,
        pincode:postalCode,
      })
    );
  };
  
  const check_position = (mapProps, map) =>{
    // alert('eeee');
    // const mapCenter = mapRef.current.getCenter();
    // console.log(map.getCenter().lat());
    const lat = map.getCenter().lat();
    const lng = map.getCenter().lng();

     setMarkerPosition({ lat: lat, lng: lng });

     newupdateaddress(lat,lng)
   
    // const { address, postalCode } = (await getCurrentLocation(lat, lng)) || "";
    // // alert(postalCode);
    // dispatch(
    //   updateGoogleMapsFields({
    //     // position: {
    //     //   lat,
    //     //   lng,
    //     // },
    //     address,
    //     postalCode,
    //     enableButton: true,
    //     textFieldValue: address,
    //     pincode:postalCode,
    //   })
    // );
   
    
  }

  const  newupdateaddress = async (lat,lng) =>{

    const { address, postalCode } = (await getCurrentLocation(lat, lng)) || "";
    // alert(postalCode);
    dispatch(
      updateGoogleMapsFields({
        position: {
          lat,
          lng,
        },
        address,
        postalCode,
        enableButton: true,
        textFieldValue: address,
        pincode:postalCode,
      })
    );

  }
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 1,
        width: "100%",
        height: { xs: "40vh", lg: "51vh" },
      }}
    >
      <Map
        {...props}
         center={position}
        
        initialCenter={position}
        centerAroundCurrentLocation={false}
        mapTypeControl={false}
        streetViewControl={false}
        draggable={true}
        //  onDragend={check_position}
        onBoundsChanged={check_position}
        gestureHandling={'greedy'}
        zoom={19}
        // onClick={handleMapClick()}
        containerStyle={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        {position && (
         
          <Marker
             position={markerPosition}
            // position={{lat: 37.762391, lng: -122.439192}}
            // icon={{
            //   url: "https://erip.in/assets/images/fevnew.png",
            //   anchor: (32,32),
            //    scaledSize: Size(50, 50)
            // }} 
            //  draggable={!props.disableMap}
           
            // onDragend={(t, map, coord) => onMarkerDragEnd(map, coord)}
           
          />
          )}
      </Map>
    </Box>
  );
};

const MapWrapper = (props) => (
  <Map
    google={props.google}
    visible={false}
    containerStyle={{ position: "relative" }}
    
  >
    <MapsContainer {...props}  />
  </Map>
);
export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_MAPS_API_KEY,
})(MapWrapper);
