import { BottomNavigation } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ToastMessage from "../../atoms/components/ToastMessage";
import ThankYouComponent from "../../atoms/thankyou/ThankYouComponent";
import AllCouponsDialog from "../../molecules/cart/AllCouponsDialog";
import OrderCancelDialog from "../../molecules/orders/OrderCancelDialog";
import RescheduleOrder from "../../molecules/orders/RescheduleOrder";
import AddEmail from "../../molecules/profile/AddEmail";
import AddMobileNumber from "../../molecules/profile/AddMobileNumber";
import ThankyouFeedback from "../../molecules/thank-you";
import BookNow from "../book-now";
import Cart from "../cart";
import ScheduleAppointment from "../confirmation";
import Login from "../login";
import Payment from "../payment";
import ReviewAndRating from "../review-rating";
import Feedback from "../review-rating/Feedback";
import BottomNavigationMenu from "../../molecules/navbar/BottomNavigation";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import DevicesDialog from "../../atoms/DevicesModal/DevicesDialog";
const Overlays = () => {
  const displayBookNow = useSelector(({ cart }) => cart.displayBookNow);
  const {isMobile} = useScreenResolution();
  const location = useLocation();
  const locate = location.pathname;
  const isValid = () =>{
    return locate != '/ap-enquiry-order'
     && locate != '/thanks_ln_enq' 
     && locate != '/thanks_ln_enq_2' 
     && locate != '/thanks_order' 
     && locate != '/landingv2test'
     && locate != '/temp_conform'
     && locate != '/conform2'
     && locate != '/thanks'
     && locate != '/location'
     && locate != '/devicelanding'
     && locate != '/service-mobile-blr'
  }
  return (
    <React.Fragment>
      <ScheduleAppointment />
      <Payment />
      <Login />
      {isMobile && isValid() && <BottomNavigationMenu/>}
      <ReviewAndRating />
      <Feedback />
      <AllCouponsDialog />
      <Cart />
      {/* {displayBookNow && <BookNow />} */}
      <AddMobileNumber />
      <AddEmail />
      <OrderCancelDialog />
      <ThankYouComponent />
      <ThankyouFeedback />
      <ToastMessage />
      <RescheduleOrder />
      <DevicesDialog/>
    </React.Fragment>
  );
};
export default Overlays;
